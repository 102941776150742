// COMPONENTS
import {
  SelectButton,
  DefaultButton,
  RestoInput,
  Icons,
  InputCode,
  ErrorCard,
} from "components/atoms";
import { Container, YellowTemplate } from "components";

import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { registerUser } from "../../api/authentication";
import { VerifPassword } from "../../helpers/verif";
import { PasswordCheck } from "components";
import country from "../../utils/country.json";
import joinClasses from "helpers/joinClasses";
import axios from "axios";
import Cookies from "universal-cookie";

export default function RegisterPage() {
  const history = useHistory();
  const location = useLocation();
  const cookies = new Cookies();
  const [userInfo, setUserInfo] = useState({});
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const [borderColorCGV, setBorderColorCGV] = useState("black");
  const [step, setStep] = useState(1);

  const _handleRegisterClick = (type) => {
    window.open(`${process.env.REACT_APP_API_AUTH}/${type}`, "_self");
  };

  useEffect(() => {
    location.state && location.state.step
      ? setStep(location.state.step)
      : setStep(1);
  }, []);

  const sendRegistration = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_AUTH}/register`, userInfo);
      setErrorMessage(null);
      setStep(2);
    } catch (error) {
      if (error.response.data.error === "NOT_VERIFIED") {
        setStep(2);
      } else {
        setErrorMessage("Ce compte existe déjà");
      }
    }
  };

  const sendVerifyCode = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_AUTH}/verify-email`,
        { code: Number(code), email: userInfo.email }
      );
      console.log("sendVerifyCode ->", data);
      setErrorMessage(null);
      setStep(3);
    } catch (error) {
      console.log(error);
      setErrorMessage("Code invalide");
    }
  };

  const sendPassword = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_AUTH}/finalize-register`,
        userInfo
      );
      console.log("sendPassword ->", data.access_token);
      cookies.set("::token", data.access_token, { path: "/" });
      setErrorMessage(null);
      setStep(4);
    } catch (error) {}
  };

  const sendUserInformations = async (e) => {
    e.preventDefault();
    try {
      const { data } = registerUser({ ...userInfo, country: "FR" });
      console.log("sendUserInformations ->", data);
      setErrorMessage(null);
      // history.push("/");
    } catch (error) {
      console.log(error);
      setErrorMessage("Une erreur est survenue");
    }
  };

  return (
    <>
      <YellowTemplate>
        <div className={joinClasses(step === 1 ? "" : "hidden")}>
          <Container
            title={"Commencez votre essai Bonappli gratuit"}
            className={joinClasses("pb-[25px]  !max-w-[600px]")}
          >
            {/* <div className="">
              <Icons name="chevron" height={25} className={`ml-5 `} />
            </div> */}
            <div className="flex flex-col items-center mt-7">
              <form
                className="flex flex-col items-center w-full"
                onSubmit={sendRegistration}
              >
                <RestoInput
                  name="email"
                  placeholder="Adresse e-mail"
                  type="email"
                  onChange={(e) => setUserInfo({ email: e.target.value })}
                />
                {errorMessage && (
                  <p className="px-4 py-1 text-red text-sm">{errorMessage}</p>
                )}
                <DefaultButton type="submit">
                  Essayer gratuitement
                </DefaultButton>
              </form>
              <div className="max-w-[398px] mt-[50px] w-full flex flex-col items-center">
                <div className="flex w-full  items-center ">
                  <div className="border-grey-disabled border-b-[1px] w-full"></div>
                  <p className="min-w-max text-sm mx-[13.5px]">
                    Ou identifiez-vous avec
                  </p>
                  <div className="border-grey-disabled border-b-[1px] w-full"></div>
                </div>
                <div className="w-full max-w-[230px] ">
                  <button
                    className="flex  w-full items-center  mt-[20px] border border-black"
                    onClick={() => _handleRegisterClick("google")}
                  >
                    <div className="flex justify-center w-1/5 ">
                      <Icons height={20} name="google1" />
                      <Icons height={20} name="google2" />
                      <Icons height={20} name="google3" />
                      <Icons height={20} name="google4" />
                    </div>
                    <p className="uppercase text-smallest py-[14px]">
                      Continuez avec Google
                    </p>
                  </button>

                  <button
                    className="flex  w-full items-center  mt-[20px] border border-black"
                    onClick={() => _handleRegisterClick("facebook")}
                  >
                    <div className="flex justify-center w-1/5 ">
                      <Icons height={20} name="facebookk1" />
                      <Icons height={20} name="facebookk2" />
                    </div>
                    <p className="uppercase text-smallest py-[14px]">
                      Continuez avec Facebook
                    </p>
                  </button>
                  {/* <button
                    className="flex  w-full items-center  mt-[20px] border border-black"
                    onClick={() => _handleRegisterClick("apple")}
                  >
                    <div className="flex justify-center w-1/5 ">
                      <Icons height={20} name="apple" />
                    </div>
                    <p className="uppercase text-smallest py-[14px]">
                      Continuez avec Apple
                    </p>
                  </button> */}
                </div>
              </div>
              <div className=" text-center sm:mt-10 ">
                <p className="sm:mt-0 mt-10 text-sm">
                  Vous avez déjà un compte ?
                </p>
                <SelectButton
                  onClick={() => {
                    history.push("/");
                  }}
                  className={`!font-medium !mt-2 !mb-0`}
                >
                  Se connecter
                </SelectButton>
              </div>
            </div>
          </Container>
        </div>

        <div className={joinClasses(step === 2 ? "" : "hidden")}>
          <Container
            title={"Vous avez reçu un code par E-mail"}
            className={joinClasses("pb-[25px]  !max-w-[600px]")}
          >
            <div className="flex flex-col gap-10 items-center mt-7">
              <div className="flex flex-col items-end	">
                <InputCode setCode={setCode} />
                <button
                  onClick={sendRegistration}
                  className="text-xs underline mt-3 text-yellow-dark"
                >
                  Renvoyer le code
                </button>
              </div>
              <DefaultButton onClick={sendVerifyCode}>Confirmer</DefaultButton>
            </div>
          </Container>
        </div>

        <div className={joinClasses(step === 3 ? "" : "hidden")}>
          <Container
            title={"Commencez votre essai Bonappli gratuit"}
            className={joinClasses("pb-[25px]  !max-w-[600px]")}
          >
            <form
              className="flex flex-col items-center m-auto  max-w-[398px]"
              onSubmit={sendPassword}
            >
              <RestoInput
                name="password"
                type="password"
                placeholder="Créeer un mot de passe "
                onChange={(e) =>
                  setUserInfo({ ...userInfo, password: e.target.value })
                }
                required
              />
              <RestoInput
                name="confirmPassword"
                placeholder="Confirmez votre mot de passe"
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    confirmedPassword: e.target.value,
                  })
                }
                type="password"
                required
              />
              <div className="flex justify-between gap-8  mt-6 ">
                <input
                  className="form-checkbox text-xl mt-[2px] text-black"
                  type="checkbox"
                  id="CGV"
                  name="CGV"
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, CGV: e.target.checked })
                  }
                  required
                />

                <div className="text-justify flex ">
                  <label
                    style={{ color: `${borderColorCGV}` }}
                    className="text-smallest sm:text-xs font-light"
                    for="CGV"
                  >
                    J'accepte les Conditions et la Politique de confidentialité
                    de Weebly. En continuant, vous acceptez les conditions de
                    l'Accord de l'utilisateur et de la Politique de
                    confidentialité.
                  </label>
                </div>
              </div>
              <DefaultButton className="mt-10">Valider</DefaultButton>
            </form>
          </Container>
        </div>

        <div className={joinClasses(step === 4 ? "" : "hidden")}>
          <Container
            title={"Bienvenue"}
            className={joinClasses("pb-[25px]  !max-w-[600px]")}
          >
            <p className="text-center mb-10">
              Félicitation, votre compte est créé, choisissez à présent un mot
              de passe
            </p>
            <form
              className="flex flex-col items-center mt-7"
              onSubmit={sendUserInformations}
            >
              <RestoInput
                name="lastname"
                type="text"
                placeholder="Nom"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, lastname: e.target.value })
                }
                required
              />
              <RestoInput
                name="firstname"
                type="text"
                placeholder="Prénom"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, firstname: e.target.value })
                }
                required
              />
              <RestoInput
                name="phone"
                type="tel"
                placeholder="Numéro de téléphone"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, phone: e.target.value })
                }
                required
              />
              <DefaultButton type="submit">Valider</DefaultButton>
            </form>
          </Container>
        </div>
      </YellowTemplate>
    </>
  );
}
