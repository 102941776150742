import { Icons } from "components/atoms";
import { findFont } from "helpers/findDataCustom";
import PriceCustom from "./priceCustom";
const reactStringReplace = require("react-string-replace");

export default function DrinkSection({ dish, index, custom }) {
  const bottle = [
    { name: "x15cl", cl: "15cl", width: "3", height: 28 },
    { name: "x25cl", cl: "25cl", width: "4", height: 32 },
    { name: "x375cl", cl: "37,5cl", width: "3", height: 48 },
    { name: "x50cl", cl: "50cl", width: "9", height: 40 },
    { name: "x75cl", cl: "75cl", width: "4", height: 64 },
  ];

 


  return (
    <div className="flex flex-col w-11/12 md:w-2/3 gap-8">
      {/*  On liste les pictos et leur label si ils sont coché (valeur à true) */}
      <thead className="w-full flex justify-around md:justify-end  items-end ">
              
                  
                  {custom &&
                    custom.display[0].visible &&
                    bottle.map(
                      (b, index) =>
                      dish.DrinkVolume[0][b.name] && (
                          <th  style={{ width: 70 }} key={index} className={`${dish.DrinkVolume[0][b.name] === true ? " h-[96px] flex flex-col items-center justify-end " : "opacity-0" }`}>
                            <div className="">
                            <Icons
                          name={`drink${b.cl}`} height={b.height}                          
                          className={`w-${b.width}`}
                          
                          style={{ color: custom && custom.color[2].custom.color }}

                        />
                            <p className="mt-2  " style={{color: custom && custom.color[1].custom.color}}>{dish.DrinkVolume[0][b.name] === true ? ` ${b.cl} ` : "  " }</p>
                            </div>
                          </th>
                        )
                    )}
              </thead>


      {/* CATEGORIE DRINKS IN DESKTOP*/}
              
      {dish &&
        dish.DrinkVolume[0] &&
        dish.DrinkVolume[0].DrinkCategory.sort((a, b) =>
          a.positionId > b.positionId ? 1 : -1
        ).map((drinks) => {
          return (
            <table className={`hidden md:table table-fixed ${
              drinks.name === null || drinks.name === "" ? "md:hidden" : ""
              }`}>

              
              <tbody>
                <tr key={index}>
                  <td
                    className={`font-bold 
                          ${
                            custom &&
                            findFont(custom.police[2].custom.fontFamily)
                          }
                          ${
                            custom && custom.police[2].custom.bold
                              ? "font-bold"
                              : ""
                          }
                          ${
                            custom && custom.police[2].custom.italic
                              ? "italic"
                              : ""
                          }
                        `}
                    style={{
                      fontSize: `${
                        custom && custom.police[2].custom.fontSize
                      }px`,
                      letterSpacing: `${
                        custom && custom.police[2].custom.letterSpacing
                      }px`,
                      color: custom && custom.color[2].custom.color,
                    }}
                  >
                    {drinks.name && drinks.name}
                  </td>

                  {custom &&
                    custom.display[0].visible &&
                    bottle.map((b, index) => drinks[b.name] && <td />)}
                </tr>
                {drinks.Drink.map((drink, index) => (
                  <tr
                    key={index}
                    style={{
                      color: custom && custom.color[2].custom.color,
                    }}
                    className={`${
                      drink.name === null || drink.name === "" ? "hidden" : ""
                    }`}
                  >
                    
                    <td className="flex items-center justify-between gap-2">
                      <p
                        className={`max-w-[418px] md:max-w-[160px] lg:max-w-[545px] min-w-max
                            ${
                              custom &&
                              findFont(custom.police[3].custom.fontFamily)
                            }
                            ${
                              custom && custom.police[3].custom.bold
                                ? "font-bold"
                                : ""
                            }
                            ${
                              custom && custom.police[3].custom.italic
                                ? "italic"
                                : ""
                            }
                          `}
                        style={{
                          fontSize: `${
                            custom && custom.police[3].custom.fontSize
                          }px`,
                          letterSpacing: `${
                            custom && custom.police[3].custom.letterSpacing
                          }px`,
                          color: custom && custom.color[2].custom.color,
                        }}
                      >
                        {drink.name}
                      </p>
                      
                      {custom && custom.display[0].visible || custom && !custom.display[0].visible
                       && dish.DrinkVolume[0][bottle[0].name] === "false" 
                       && dish.DrinkVolume[1][bottle[1].name] === "false" 
                       && dish.DrinkVolume[2][bottle[2].name] === "false" 
                       && dish.DrinkVolume[3][bottle[3].name] === "false" 
                       && dish.DrinkVolume[4][bottle[4].name] === "false" ? <div
                       className="border-t border-black border-dotted w-full h-0"
                       style={{
                         borderColor: custom.color[3].custom.color,
                       }}
                     /> :( ""
                        
                      ) }
                    </td>
                    
                    {custom && custom.display[0].visible && (
                      <>
                      
                        {dish.DrinkVolume[0][bottle[0].name] === true && (
                          <PriceCustom
                            custom={custom}
                            
                            table
                          >
                            {drink[bottle[0].name] === null || drink[bottle[0].name] === "" ? (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }}>-</div>
                            ) : (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }} >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[0].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PriceCustom>
                        ) }
                        {dish.DrinkVolume[0][bottle[1].name] && (
                          <PriceCustom
                            custom={custom}
                            className="text-center "
                            table
                          >
                            {!drink[bottle[1].name] ? (
                              <div />
                            ) : drink[bottle[1].name] === null || drink[bottle[1].name] === "" ? (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }}>-</div>
                            ) : (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }} >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[1].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PriceCustom>
                        )}
                        {dish.DrinkVolume[0][bottle[2].name] && (
                          <PriceCustom
                            custom={custom}
                            className="text-center"
                            table
                          >
                            {drink[bottle[2].name] === null || drink[bottle[2].name] === "" ? (
                             <div className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${
                               custom && custom.police[4].custom.bold
                                 ? "font-bold"
                                 : ""
                             }
                             ${
                               custom && custom.police[4].custom.italic
                                 ? "italic"
                                 : ""
                             }
                           `}
                         style={{
                           fontSize: `${
                             custom && custom.police[4].custom.fontSize
                           }px`,
                           letterSpacing: `${
                             custom && custom.police[4].custom.letterSpacing
                           }px`,
                           color: custom && custom.color[2].custom.color,
                         }}>-</div>
                           ) : (
                             <div className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${
                               custom && custom.police[4].custom.bold
                                 ? "font-bold"
                                 : ""
                             }
                             ${
                               custom && custom.police[4].custom.italic
                                 ? "italic"
                                 : ""
                             }
                           `}
                         style={{
                           fontSize: `${
                             custom && custom.police[4].custom.fontSize
                           }px`,
                           letterSpacing: `${
                             custom && custom.police[4].custom.letterSpacing
                           }px`,
                           color: custom && custom.color[2].custom.color,
                         }} >
                               {" "}
                               {reactStringReplace(
                                 `${drink[bottle[2].name]}`,
                                 "€",
                                 (match, i) => ""
                               )}
                               €
                             </div>
                            )}
                          </PriceCustom>
                        )}
                        {dish.DrinkVolume[0][bottle[3].name] && (
                         <PriceCustom
                         custom={custom}
                         className="text-center"
                         table
                       >
                         {drink[bottle[3].name] === null || drink[bottle[3].name] === "" ? (
                          <div className={`
                          ${
                            custom &&
                            findFont(custom.police[4].custom.fontFamily)
                          }
                          ${
                            custom && custom.police[4].custom.bold
                              ? "font-bold"
                              : ""
                          }
                          ${
                            custom && custom.police[4].custom.italic
                              ? "italic"
                              : ""
                          }
                        `}
                      style={{
                        fontSize: `${
                          custom && custom.police[4].custom.fontSize
                        }px`,
                        letterSpacing: `${
                          custom && custom.police[4].custom.letterSpacing
                        }px`,
                        color: custom && custom.color[2].custom.color,
                      }}>-</div>
                        ) : (
                          <div className={`
                          ${
                            custom &&
                            findFont(custom.police[4].custom.fontFamily)
                          }
                          ${
                            custom && custom.police[4].custom.bold
                              ? "font-bold"
                              : ""
                          }
                          ${
                            custom && custom.police[4].custom.italic
                              ? "italic"
                              : ""
                          }
                        `}
                      style={{
                        fontSize: `${
                          custom && custom.police[4].custom.fontSize
                        }px`,
                        letterSpacing: `${
                          custom && custom.police[4].custom.letterSpacing
                        }px`,
                        color: custom && custom.color[2].custom.color,
                      }} >
                            {" "}
                            {reactStringReplace(
                              `${drink[bottle[3].name]}`,
                              "€",
                              (match, i) => ""
                            )}
                            €
                          </div>
                         )}
                       </PriceCustom>
                        )}
                        {dish.DrinkVolume[0][bottle[4].name] && (
                          <PriceCustom
                            custom={custom}
                            className="text-center"
                            table
                          >
                            {drink[bottle[4].name] === null || drink[bottle[4].name] === "" ? (
                             <div className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${
                               custom && custom.police[4].custom.bold
                                 ? "font-bold"
                                 : ""
                             }
                             ${
                               custom && custom.police[4].custom.italic
                                 ? "italic"
                                 : ""
                             }
                           `}
                         style={{
                           fontSize: `${
                             custom && custom.police[4].custom.fontSize
                           }px`,
                           letterSpacing: `${
                             custom && custom.police[4].custom.letterSpacing
                           }px`,
                           color: custom && custom.color[2].custom.color,
                         }}>-</div>
                           ) : (
                             <div className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${
                               custom && custom.police[4].custom.bold
                                 ? "font-bold"
                                 : ""
                             }
                             ${
                               custom && custom.police[4].custom.italic
                                 ? "italic"
                                 : ""
                             }
                           `}
                         style={{
                           fontSize: `${
                             custom && custom.police[4].custom.fontSize
                           }px`,
                           letterSpacing: `${
                             custom && custom.police[4].custom.letterSpacing
                           }px`,
                           color: custom && custom.color[2].custom.color,
                         }} >
                               {" "}
                               {reactStringReplace(
                                 `${drink[bottle[4].name]}`,
                                 "€",
                                 (match, i) => ""
                               )}
                               €
                             </div>
                            )}
                          </PriceCustom>
                        )}
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          );
        })}
      {/* CATEGORIE DRINKS IN MOBILE*/}
      {dish &&
        dish.DrinkVolume[0] &&
        dish.DrinkVolume[0].DrinkCategory.sort((a, b) =>
          a.positionId > b.positionId ? 1 : -1
        ).map((drinks) => {


          return (
            
            <div className="block md:hidden ">
              <div>
                <div key={index}>
                  <h3
                    className={`font-bold mt-6 mb-3 
                          ${
                            custom &&
                            findFont(custom.police[2].custom.fontFamily)
                          }
                          ${
                            custom && custom.police[2].custom.bold
                              ? "font-bold"
                              : ""
                          }
                          ${
                            custom && custom.police[2].custom.italic
                              ? "italic"
                              : ""
                          }
                        `}
                    style={{
                      fontSize: `${
                        custom && custom.police[2].custom.fontSize
                      }px`,
                      letterSpacing: `${
                        custom && custom.police[2].custom.letterSpacing
                      }px`,
                      color: custom && custom.color[2].custom.color,
                    }}
                  >
                    {drinks.name}
                  </h3>
                </div>
                {drinks.Drink.map((drink, index) => (
                  <div>
                      <p
                        className={` pl-4 mb-2
                            ${
                              custom &&
                              findFont(custom.police[3].custom.fontFamily)
                            }
                            ${
                              custom && custom.police[3].custom.bold
                                ? "font-bold"
                                : ""
                            }
                            ${
                              custom && custom.police[3].custom.italic
                                ? "italic"
                                : ""
                            }
                          `}
                        style={{
                          fontSize: `${
                            custom && custom.police[3].custom.fontSize
                          }px`,
                          letterSpacing: `${
                            custom && custom.police[3].custom.letterSpacing
                          }px`,
                          color: custom && custom.color[2].custom.color,
                        }}
                      >
                        {drink.name}
                      </p>
                   
                    {custom && custom.display[0].visible && (
                      <div className="flex justify-around mb-6 ">
                        {dish.DrinkVolume[0][bottle[0].name] === true && (
                          <PriceCustom custom={custom} className="text-center">
                            {drink.x15cl === null || drink.x15cl === "" ? (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }}>-</div>
                            ) : (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }} >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[0].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PriceCustom>
                        )}
                        {dish.DrinkVolume[0][bottle[1].name] === true && (
                          <PriceCustom custom={custom} className="text-center">
                            {drink[bottle[1].name] === null || drink[bottle[1].name] === "" ? (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }}>-</div>
                            ) : (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }} >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[1].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PriceCustom>
                        )}
                        {dish.DrinkVolume[0][bottle[2].name] === true && (
                          <PriceCustom custom={custom} className="text-center">
                            {drink[bottle[2].name] === null || drink[bottle[2].name] === "" ? (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }}>-</div>
                            ) : (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }} >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[2].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PriceCustom>
                        )}
                        {dish.DrinkVolume[0][bottle[3].name] === true  && (
                          <PriceCustom custom={custom} className="text-center">
                            {drink[bottle[3].name] === null || drink[bottle[3].name] === "" ? (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }}>-</div>
                            ) : (
                              <div className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${
                                custom && custom.police[4].custom.bold
                                  ? "font-bold"
                                  : ""
                              }
                              ${
                                custom && custom.police[4].custom.italic
                                  ? "italic"
                                  : ""
                              }
                            `}
                          style={{
                            fontSize: `${
                              custom && custom.police[4].custom.fontSize
                            }px`,
                            letterSpacing: `${
                              custom && custom.police[4].custom.letterSpacing
                            }px`,
                            color: custom && custom.color[2].custom.color,
                          }} >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[3].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PriceCustom>
                        )}
                        {dish.DrinkVolume[0][bottle[4].name] === true && (
                          <PriceCustom custom={custom} className="text-center">
                            {drink[bottle[4].name] === null || drink[bottle[4].name] === "" ? (
                             <div className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${
                               custom && custom.police[4].custom.bold
                                 ? "font-bold"
                                 : ""
                             }
                             ${
                               custom && custom.police[4].custom.italic
                                 ? "italic"
                                 : ""
                             }
                           `}
                         style={{
                           fontSize: `${
                             custom && custom.police[4].custom.fontSize
                           }px`,
                           letterSpacing: `${
                             custom && custom.police[4].custom.letterSpacing
                           }px`,
                           color: custom && custom.color[2].custom.color,
                         }}>-</div>
                           ) : (
                             <div className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${
                               custom && custom.police[4].custom.bold
                                 ? "font-bold"
                                 : ""
                             }
                             ${
                               custom && custom.police[4].custom.italic
                                 ? "italic"
                                 : ""
                             }
                           `}
                         style={{
                           fontSize: `${
                             custom && custom.police[4].custom.fontSize
                           }px`,
                           letterSpacing: `${
                             custom && custom.police[4].custom.letterSpacing
                           }px`,
                           color: custom && custom.color[2].custom.color,
                         }} >
                               {" "}
                               {reactStringReplace(
                                 `${drink[bottle[4].name]}`,
                                 "€",
                                 (match, i) => ""
                               )}
                               €
                             </div>
                            )}
                          </PriceCustom>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
}