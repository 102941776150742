import { useEffect, useState } from "react";
import {
  Card,
  Icons,
  EditFontRestau,
  EditColorRestau,
  EditDisplayRestau,
  BorderedButton,
} from "components/atoms";
import editFont from "utils/editFont";
import { useSelector, useDispatch } from "react-redux";
import {
  customSelector,
  fetchAllCustom,
  fetchEditCustom,
} from "store/models/customInfo";
import { userSelector } from "store/models/userInfo";
import { useLocation } from "react-router-dom";
import editColor from "utils/editColor";
import editDisplay from "utils/editDisplay";

export function CustomRestaurantCard({
  isOpen = false,
  setIsOpen = () => {},
  brandId,
}) {
  const custom = useSelector(customSelector);
  const { user } = useSelector(userSelector);
  const location = useLocation();
  const dispatch = useDispatch();

  const [isFirstInit, setIsFirstInit] = useState(true);
  const [curretTab, setCurrentTab] = useState(1);

  // state for edit form
  const [editingFont, setEditingFont] = useState([]);
  const [editingColor, setEditingColor] = useState([]);
  const [editingDisplay, setEditingDisplay] = useState([]);

  // fetch all customs for user after get user info
  useEffect(() => {
    if (user.id) {
      dispatch(fetchAllCustom(user.id));
    }
  }, [user]);

  useEffect(() => {
    // if customs of store is loaded and is the first call of this function
    if (custom.header.status === "success" && isFirstInit) {
      // get index of current custom in the store
      const index = custom.customs.findIndex(
        (item) => item.brandId === Number(brandId)
      );

      // if this custom is found in the store
      if (custom.customs[index]) {
        // set the store default values for edit form
        setEditingFont(custom.customs[index].police);
        setEditingColor(custom.customs[index].color);
        setEditingDisplay(custom.customs[index].display);
      } else {
        // set default values for edit form
        setEditingFont(editFont);
        setEditingColor(editColor);
        setEditingDisplay(editDisplay);
      }
      // set first initialisation to false
      setIsFirstInit(false);
    }
  }, [custom]);

  useEffect(() => {
    // if form is not empty and is not the first initialisation
    if (
      editingFont.length &&
      editingColor.length &&
      editingDisplay.length &&
      !isFirstInit
    ) {
      dispatch(
        fetchEditCustom({
          police: editingFont,
          brandId: brandId,
          color: editingColor,
          display: editingDisplay,
          userId: user.id,
        })
      );
    }
  }, [editingFont, editingColor, editingDisplay]);

  const tabItems = [
    {
      id: 1,
      title: "Polices",
      icon: "font-family",
    },
    {
      id: 2,
      title: "Couleurs",
      icon: "color-picker",
    },
    {
      id: 3,
      title: "Affichage",
    },
  ];

  return (
    <Card
      className={`fixed right-0 top-1/2 transform -translate-y-2/4 ${
        !isOpen && "hidden"
      } shadow-special bg-white z-50 md:w-[400px] transition-all  w-full`}
    >
      {/* header title */}
      <div className="flex gap-8 justify-between">
        <h3 className="flex items-center font-poppins font-bold text-[15px] gap-2 p-5">
          <Icons name="pinceau" height={35} className="cursor-default" />
          Personnaliser votre carte
        </h3>
        <button
          className="h-[50px] w-[50px] bg-yellow"
          onClick={() => setIsOpen(false)}
        >
          <Icons
            name="add"
            height={25}
            className="transform  rotate-45 block"
          />
        </button>
      </div>
      <div className="max-h-[650px] overflow-y-auto overflow-x-hidden">
        {/* navbar of tabs */}
        <nav className="grid grid-cols-3 gap-4 px-3">
          {tabItems.map((item, index) => (
            <div
              key={index}
              className={`border ${
                curretTab === item.id
                  ? "bg-yellow border-yellow"
                  : "border-black"
              } flex items-center justify-center font-poppins py-2 text-center text-sm rounded-full gap-3 cursor-pointer`}
              onClick={() => setCurrentTab(item.id)}
            >
              <p>{item.title}</p>
              {item.icon && <Icons name={item.icon} height="15" />}
            </div>
          ))}
        </nav>

        {/* tab of editing fonts */}
        {curretTab === 1 && (
          <div className="flex flex-col gap-3 mx-3 mt-8">
            {editingFont.map((arg, index) => (
              <EditFontRestau
                key={index}
                index={index}
                editingFont={editingFont}
                setEditingFont={setEditingFont}
                fonts={custom.fonts}
              />
            ))}
          </div>
        )}

        {/* tab of editing colors */}
        {curretTab === 2 && (
          <div className="flex flex-col gap-3 mx-3 mt-8">
            {editColor.map((arg, index) => (
              <EditColorRestau
                key={index}
                index={index}
                editingColor={editingColor}
                setEditingColor={setEditingColor}
              />
            ))}
          </div>
        )}

        {/* tab of editing display */}
        {curretTab === 3 && (
          <div className="flex flex-col gap-3 mx-3 mt-8">
            {editDisplay.map((arg, index) => (
              <EditDisplayRestau
                key={index}
                index={index}
                editingDisplay={editingDisplay}
                setEditingDisplay={setEditingDisplay}
              />
            ))}
          </div>
        )}

        <div className="py-5">
          <p className="font-semibold text-center mb-2 text-xs w-2/3 m-auto">
            Vous souhaitez aller plus loin dans la personnalisation de votre
            carte ?
          </p>
          <BorderedButton
            borderType="yellow"
            className=" m-auto  "
          >
            Contactez-nous
          </BorderedButton>
        </div>
      </div>
    </Card>
  );
}
