import joinClasses from "helpers/joinClasses";

export function HorizontalLine({ className = "", ...props }) {
  return (
    <div
      className={joinClasses(
        className,
        "border border-grey rounded-full m-auto w-auto"
      )}
      {...props}
    ></div>
  );
}
