import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";

import SubscribePage from "pages/subscribe";
import RestaurantPage from "pages/dashboard";
import OneRestaurantPage from "pages/dashboard/restoInfo";
import OneRestaurantPageMenu from "pages/dashboard/restoCard";
import OneRestaurantCategoriesPage from "pages/dashboard/restoCategories";
import CodeQR from "pages/dashboard/qr-code";
import UiPage from "pages/ui";
import Account from "pages/account";
import { PreviewMenu } from "pages/PreviewMenu";
import Carte from "pages/carte";
import { EarlyVersionCard, FirstAccountInfoCard } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { customSelector, fetchAllFonts } from "store/models/customInfo";

export default function DefaultRouter() {
  const dispatch = useDispatch();
  const custom = useSelector(customSelector);
  useEffect(() => {
    dispatch(fetchAllFonts());
  }, []);

  return (
    <>
      <style>
        {custom.fonts.map((font) => {
          return `
            @font-face {
              font-family: '${font.family}';
              src: url('${font.files.regular}');
            }
            .${font.family.replace(/\s/g, "-")} {
              font-family: '${font.family}', ${font.category};
            }
            `;
        })}
      </style>

      <BrowserRouter>
        <Router>
          <Switch>
            <Route exact path="/pages/subscribe" component={SubscribePage} />
            <Route
              exact
              path="/pages/dashboard/:id"
              component={OneRestaurantPage}
            />
            <Route
              exact
              path="/pages/dashboard/menu/:id"
              component={OneRestaurantPageMenu}
            />
            <Route
              exact
              path="/pages/dashboard/categories/:id"
              component={OneRestaurantCategoriesPage}
            />
            <Route
              exact
              path="/pages/dashboard/qrcode/:id"
              component={CodeQR}
            />
            <Route exact path="/pages/account" component={Account} />
            <Route
              exact
              path="/dashboard/brand/preview/:id"
              component={PreviewMenu}
            />
            <Route exact path="/carte/:url" component={Carte} />
            <Route exact path="/ui" component={UiPage} />
            <Route exact path="/firstInfo" component={FirstAccountInfoCard} />
            <Route exact path="/early" component={EarlyVersionCard} />
            <Route path="/" component={RestaurantPage} />
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
}
