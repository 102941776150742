import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function createDrinkCategory(token, id) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/drinkcategory`,
    { drinkVolumeId: id },

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = response.data;

  return data;
}

export async function updateDrinkCategory(id, payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/drinkcategory/${id}`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data;
  } catch (error) {
  }
}

export async function deleteDrinkCategory({ id, token }) {
  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/drinkcategory/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
