// COMPONENTS
import { HorizontalLine, Icons } from "components/atoms";

//

import { useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { updateComment } from "api/comment/comment";
import { deleteComment } from "api/comment/comment";
import { positionDown, positionUp } from "api/position";
import addAlpha from "helpers/addAlpha";
import { useEffect, useState } from "react";
import { findFont } from "helpers/findDataCustom";

export function CommentCreationPatron({
  commentData,
  commentId,
  loadData = () => {},
  custom,
  index,
  setLoading,
  length,
}) {
  const [text, setText] = useState(null);

  const { user } = useSelector(userSelector);
  const changeComment = async (data) => {
    await updateComment({ id: commentId, token: user.token, payload: data });
  };

  const removeComment = async () => {
    await deleteComment(commentId);
    loadData();
  };

  const changePosition = async (section, action) => {
    setLoading(true);
    if (action === "up") {
      await positionDown(section.id, "comment");
    }

    if (action === "down") {
      await positionUp(section.id, "comment");
    }

    loadData();
    setLoading(false);
  };

  return (
    <div className="relative" id={`comment-${commentId}`} key={commentId}>
      {/* <HorizontalLine className="mb-8" /> */}
      <div className=" flex justify-between my-8 ">
        <textarea
          className={`
          h-1/3 my-1 border rounded resize-none border-solid border-grey-disabled bg-transparent pl-3.5 w-10/12
          ${custom && findFont(custom.police[3].custom.fontFamily)}
          ${custom && custom.police[3].custom.bold && "font-bold"}
          ${custom && custom.police[3].custom.italic && "italic"}
          `}
          style={{
            borderColor: custom && addAlpha(custom.color[1].custom.color, 0.2),
            fontSize: custom && custom.police[3].custom.fontSize,
            letterSpacing: custom && custom.police[3].custom.letterSpacing,
            color: custom && custom.color[2].custom.color,
          }}
          placeholder="Commentaire"
          cols="40"
          name={`comment-${commentId}`}
          value={text || commentData.content}
          onChange={(e) => setText(e.target.value)}
          onBlur={(e) => changeComment({ content: e.target.value.trim() })}
          style={{
            color: custom && custom.color[2].custom.color,
            borderColor: custom && addAlpha(custom.color[1].custom.color, 0.2),
          }}
        />

        <div className="md:hidden flex flex-col justify-around ">
          <div className="flex flex-col  items-center ">
            {length !== 1 && (
              <>
                {index != 0 && (
                  <Icons
                    name="arrow-up"
                    height="20"
                    className="hover:opacity-50 active:opacity-100"
                    onClick={() => changePosition(commentData, "up")}
                    style={{ color: custom && custom.color[1].custom.color }}
                  />
                )}
                {index + 1 != length && (
                  <Icons
                    name="arrow-down"
                    height="20"
                    className="hover:opacity-50 active:opacity-100"
                    onClick={() => changePosition(commentData, "down")}
                    style={{ color: custom && custom.color[1].custom.color }}
                  />
                )}
              </>
            )}
          </div>
          <Icons
            name="trash"
            height="20"
            style={{ color: custom && custom.color[1].custom.color }}
            onClick={(e) => {
              e.preventDefault();
              removeComment({ id: commentId, userId: user.id });
            }}
          />
        </div>
        <div className="hidden md:flex md:justify-end md:items-center">
          <div className="flex flex-col">
            {length !== 1 && (
              <>
                {index != 0 && (
                  <Icons
                    name="arrow-up"
                    height="20"
                    className="hover:opacity-50 active:opacity-100"
                    style={{ color: custom && custom.color[1].custom.color }}
                    onClick={() => changePosition(commentData, "up")}
                  />
                )}
                {index + 1 != length && (
                  <Icons
                    name="arrow-down"
                    height="20"
                    style={{ color: custom && custom.color[1].custom.color }}
                    className="hover:opacity-50 active:opacity-100"
                    onClick={() => changePosition(commentData, "down")}
                  />
                )}
              </>
            )}
          </div>
          <Icons
            name="trash"
            height="20"
            style={{ color: custom && custom.color[1].custom.color }}
            onClick={(e) => {
              e.preventDefault();
              removeComment({ id: commentId, userId: user.id });
            }}
            className="px-2.5"
          />
        </div>
      </div>
    </div>
  );
}
