import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function updateDrinkVolume(id, payload) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/drinkvolume/${id}`,
        payload,
        getFormatedToken()
      );
  
      const { data } = response.data;

      return data;
    } catch (error) {
    }
  }