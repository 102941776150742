import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";

export function TimePatron({
  customData,
  children,
  data,
  disabled,
  className = "",
  callBack = () => {},
  isPreview = false,
}) {
  const [json, setJson] = useState({
    0: false, // checkbox
    1: null, // début matin
    2: null, // fin matin
    3: null, // début après midi
    4: null, // fin apres midi
  });
  useEffect(() => {
    callBack(json);
  }, [json]);
  useEffect(() => {
    if (data) {
      setJson(data);
    }
  }, [data]);

  return (
    <>
      <div className={`flex h-[29px] items-center ${disabled ? "pointer-events-none" : ""}`}>
        {disabled ? (
          <>
            <div className="flex justify-center flex-col">
              <div className="w-[100px] flex items-center gap-1.5">
                
                <p className={`text-sm text-grey-disabled`}>{children}</p>
              </div>
            </div>
            <div className="flex flex-col ">
              {json["0"] ? (
                <div className="flex items-center gap-1  ">
                  
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[1]}
                    className={` bg-transparent w-[49px] text-center h-[29px] text-sm p-0 text-grey-disabled border-grey-disabled pointer-events-none`}
                    onChange={(e) =>
                      setJson({ ...json, ["1"]: e.target.value })
                    }
                  />

                  <p className={`text-sm text-grey-disabled`}>-</p>
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[2]}
                    className={` bg-transparent w-[49px] text-center h-[29px] text-sm p-0 text-grey-disabled border-grey-disabled pointer-events-none`}
                    onChange={(e) =>
                      setJson({ ...json, ["2"]: e.target.value })
                    }
                  />
                  <p className={`text-sm  text-grey-disabled `}>,</p>
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[3]}
                    className={` bg-transparent w-[49px] text-center h-[29px] text-sm p-0 text-grey-disabled border-grey-disabled pointer-events-none`}
                    onChange={(e) =>
                      setJson({ ...json, ["3"]: e.target.value })
                    }
                  />
                  <p className={`text-sm  text-grey-disabled`}>-</p>
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[4]}
                    className={` bg-transparent w-[49px] text-center h-[29px] text-sm p-0 text-grey-disabled border-grey-disabled pointer-events-none`}
                    onChange={(e) =>
                      setJson({ ...json, ["4"]: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="w-[238px] text-sm">
                  {" "}
                  <p className={` italic text-grey-disabled ml-1.5`}>Fermé</p>{" "}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center flex-col">
              <div className="w-[100px] flex items-center gap-1.5">
                

                <p
                  className="text-sm"
                  style={{
                    color: customData && customData.color[2].custom.color,
                  }}
                >
                  {children}
                </p>
              </div>
            </div>
            <div className={`flex flex-col "m-auto`}>
              {json["0"] ? (
                <div className="flex items-center gap-1  ">
                  
                    <>
                    {json["1"] ? (<p
                      className={`bg-transparent w-[49px] flex justify-center items-center h-[29px] text-sm p-0`}
                      style={{
                        borderColor:
                          customData && customData.color[1].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[1]}
                    </p>) : ""}
                    </>
                  

                  <p
                    className="text-sm"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    {!isPreview || json[1] && json[2] ? " - " : ""}
                  </p>
                  
                    <>
                    {json["2"] ? (<p
                      className={`bg-transparent w-[49px] flex justify-center items-center h-[29px] text-sm p-0`}
                      style={{
                        borderColor:
                          customData && customData.color[1].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[2]}
                    </p>) : ""}
                    </>
                  
                  <p
                    className="text-sm"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    {!isPreview || json[2] && json[3] ? " , " : ""}
                  </p>
                  
                    <>
                    {json["3"] ? (<p
                      className={`bg-transparent w-[49px] flex justify-center items-center h-[29px] text-sm p-0`}
                      style={{
                        borderColor:
                          customData && customData.color[1].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[3]}
                    </p>) : ""}
                    </>
                  
                  <p
                    className="text-sm"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    {!isPreview || json[3] && json[4] ? " - " : ""}
                  </p>
                  
                    <>
                    {json["4"] ? (<p
                      className={`bg-transparent w-[49px] flex justify-center items-center h-[29px] text-sm p-0`}
                      style={{
                        borderColor:
                          customData && customData.color[1].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[4]}
                    </p>) : ""}
                    </>
                  
                </div>
              ) : (
                <div className="w-[238px] text-sm">
                  {" "}
                  <p
                    className="italic ml-1.5"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    Fermé
                  </p>{" "}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
