import joinClasses from "helpers/joinClasses";

export function CategorieSeparation({ className = "", color }) {
  return (
    <div
      className={joinClasses(className, "flex items-center  w-10/12 m-auto")}
    >
      <div
        className="h-1 w-1 md:h-2 md:w-2 bg-black transform rotate-45 "
        style={{ backgroundColor: color }}
      ></div>
      <div
      className="border-2 border-solid border-black w-5/6 md:w-full h-0 mx-6 bg-black"
        style={{ borderColor: color }}
      ></div>
      <div
        className="h-1 w-1 md:h-2 md:w-2 bg-black transform rotate-45 "
        style={{ backgroundColor: color }}
      ></div>
    </div>
  );
}
