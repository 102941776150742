import { DefaultButton } from "components/atoms/";

// ## QR CODE
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

export function QrCode({ menu }) {
  const [qrcodeImg, setQrcodeImg] = useState("");

  useEffect(() => {
    if (document.getElementById("svg")) {
      var svg = document.getElementById("svg");
      var serializer = new XMLSerializer();
      var source = serializer.serializeToString(svg);
      if (
        !source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)
      ) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }

      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

      setQrcodeImg(
        "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source)
      );
    }
  }, [document.getElementById("svg")]);

  return (
    <div className="mt-8 mb-8 flex flex-col items-center gap-5" id="myqrcode">
      {menu && menu.url && (
        <>
          <QRCode
            size={112}
            value={`${process.env.REACT_APP_PUBLIC_PROD_URL}/carte/${menu.url}`}
            id="svg"
          />
          <p className="text-xl font-medium">Votre QRcode a bien été crée !</p>
          <p className="text-sm">
            Vous pouvez le&nbsp;
            {qrcodeImg && (
              <a
                download={`qrcode-${menu && menu.url}.svg`}
                href={qrcodeImg}
                className="underline"
              >
                télécharger ici.
              </a>
            )}
          </p>
        </>
      )}
    </div>
  );
}
