export function EditColorRestau({ index, editingColor, setEditingColor }) {
  const changeColor = (e) => {
    var tmp = [...editingColor];

    tmp[index] = {
      ...editingColor[index],
      custom: { color: e.target.value },
    };
    setEditingColor(tmp);
  };

  return (
    <div className="bg-yellow bg-opacity-25 flex justify-between items-center py-4 px-4 rounded-lg">
      <p className="uppercase">{editingColor[index].title}</p>
      <div className="flex gap-2">
        <div className="h-[20px] w-[20px] rounded-full overflow-hidden relative cursor-pointer">
          <input
            defaultValue={editingColor[index].custom.color}
            type="color"
            onBlur={changeColor}
            className="border-0 absolute left-1/2 top-1/2 transform -translate-y-2/4 -translate-x-2/4 h-[50px] w-[50px] cursor-pointer"
          />
        </div>
        <p className="w-[120px] h-[20px] flex pl-3 items-center text-xs bg-white rounded-full uppercase">
          {editingColor[index].custom.color}
        </p>
      </div>
    </div>
  );
}
