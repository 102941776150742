import joinClasses from "helpers/joinClasses";

export function UnderlineInput(props) {
    return (
      
        <input
        {...props}
        className={`bg-transparent border-none pb-1 md:p-1 font-poppins text-sm font-normal placeholder:text-red  w-full max-w-[360px]  ${props.className ? props.className : "" }`}
        style={{border: "none", borderBottom: `1px solid ${props.borderColor ||  "#B3B3B3"}`,}}
      />
      
    );
  }
  