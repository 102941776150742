// COMPONENTS
import { Card, SubTitle, Title, 
         DefaultButton, RecurrenceButton, 
         YellowBloc, Line } from "components/atoms";
import { DashboardNavbar } from "components";
import { FormuleCard } from "components";


import React, { useState, useEffect } from "react";
import isLoggedIn from "../../helpers/isLoggedIn";
import { authorized } from "../../api/authentication";
import products from "../../utils/products";


export default function SubscribePage() {
  const [annual, setAnnual] = useState(false)
  const [monthly, setMonthly] = useState(true)
  const [token, setToken] = useState("");
  const [selectedFormule, setSelectFormule] = useState(null)
  const sendSubscriptionRequest = async (e, productId) => {
    e.preventDefault()
    await authorized(productId)
  }
  useEffect(() => {
    setToken(localStorage.getItem("::token"))
     isLoggedIn()

  }, []);
  
  return (
    <div>        <YellowBloc/>

      <DashboardNavbar/>
       <section>
        <div className="pt-10 md:ml-36%" >
        <Title TextSize="smaller" className=" md:text-left text-center md:ml-7 ">Veuillez sélectionner la formule qui vous convient</Title>
        </div>
        <div className="flex justify-center">
        <Card className="md:mb-0 mb-5 mx-10 md:mx-auto mt-5">
          <div className="flex justify-center">
            <div className="flex justify-center border  border-grey-dark border-solid rounded-full mt-small mb-4 gap-1">
              <RecurrenceButton className=" ml-1.5" isActive={monthly} onClick={() => {setMonthly(true); setAnnual(false)}} >Mensuel</RecurrenceButton>
              <RecurrenceButton className=" mr-1.5" isActive={annual} onClick={() => {setMonthly(false); setAnnual(true)}} >Annuel</RecurrenceButton>
            </div>
          </div>
          <div className="bg-yellow-lightest md:flex  md:justify-center mx-8 md:mx-20 rounded-xl relative">
          <Line/>
            {products.map((product, index) => {
              if(monthly){
              return product.frequency==="monthly" && (

              <div className="mx-8 md:mx-16 py-small " key={index}>
                <FormuleCard setSelectedFormule={setSelectFormule} selectedFormule={selectedFormule} id={product.stripe_id} annual={annual} monthly={monthly} nbCustomableCards={product.name} quantity={product.quantity} price={product.price} reduction={product.realPrice}></FormuleCard>
              </div>)}
              
              if(annual){
                return product.frequency==="annualy" && (
  
                <div className="mx-8 md:mx-16 py-small " key={index}>
                  <FormuleCard setSelectedFormule={setSelectFormule} selectedFormule={selectedFormule} id={product.stripe_id} annual={annual} monthly={monthly} nbCustomableCards={product.name} quantity={product.quantity} price={product.price} reduction={product.realPrice}></FormuleCard>
                </div>)}
            })}


            {/* <div className="mx-16 py-small">
              <FormuleCard setSelectedFormule={setSelectFormule} selectedFormule={selectedFormule} id={35} annual={annual} monthly={monthly} nbCustomableCards={"carte personnalisable"} quantity={"1"} price={"35€"} reduction={"420€"}></FormuleCard>
            </div>  
              
            <div className="px-16 py-small">
            <FormuleCard setSelectedFormule={setSelectFormule} selectedFormule={selectedFormule} id={50} annual={annual} monthly={monthly} nbCustomableCards={"carte personnalisable"} quantity={"2-5"} price={"50€"} reduction={"600€"}></FormuleCard>
            </div> */}

          </div>
          <div className="text-center">
          <form onSubmit={(e) => sendSubscriptionRequest(e, selectedFormule)}>
          <button className="bg-yellow font-poppins font-medium py-3.5 px-10 rounded-full text-sm uppercase mt-6 mb-3.5 backdrop-filter hover:backdrop-brightness-200">Valider</button>
          </form>
          <p className="font-poppins font-medium text-xs mb-4  md:mx-0 mx-4">En validant vous serez redirigé vers notre plateforme de paiement en ligne.</p>
          </div>
          
        </Card>
        </div>


        


















        {/* <div className="flex flex-row justify-center">
          {products.map((product, index) => (
            <div className="product  w-1/5 text-center  shadow-xl h-auto " key={index} style={{ padding: "10px 30px", margin: "10px" }}>

              <h3 className="font-bold text-2xl">{product.name}</h3>

              <button className="bg-yellow-200 p-1 align-middle rounded-full m-auto w-56 h-56 cursor-text text-5xl font-semibold">{product.price} € </button>

              <p className="font-serif text-xl font-bold">30 jours gratuit</p>
              <p className="text-xs text-gray-400">puis 10.0€/mois pendant 1 an</p>

              <form onSubmit={(e) => sendSubscriptionRequest(e, product.stripe_id)}>
                <button className="bg-gradient-to-r from-yellow-300 to-yellow-200 hover:from-yellow-400 hover:to-yellow-300   p-1   w-4/6 rounded-full m-1  h-auto">
                  Souscrire
                </button>
              </form>
            </div>
          ))}
        </div>
        <Link href="/auth/login">
          <a>Login</a>
        </Link> */}
      </section>

    </div>
  );
}





