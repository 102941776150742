// COMPONENTS
import { Card, StepsButton } from "components/atoms";

import { Link } from "react-router-dom";

export function DashboardTriNavbar({ etape, selectedBrand }) {
  const navItems = [
    {
      number: "1",
      name: "Informations",
      link: "/pages/dashboard/",
      dots: "· · · · · · · · · · · · · · · · ·",
      smallDots: "· · · · ·",
    },
    {
      number: "2",
      name: "votre menu",
      link: "/pages/dashboard/menu/",
      dots: "· · · · · · · · · · · · · · · · ·",
      smallDots: "· · · · ·",
    },
    {
      number: "3",
      link: "/pages/dashboard/qrcode/",
      name: "Lancement",
      smallDots: "",
    },
  ];

  return (
    <nav className=" md:m-auto md:flex md:justify-center md:items-center md:mt-4 md:gap-5">
      <Card className="md:hidden mt-6 mx-5 py-3">
        <div className="pl-2 text-center ">
          <p className="uppercase truncate">{selectedBrand.name}</p>
          <p className="text-sm truncate">{selectedBrand.city}</p>
        </div>
      </Card>

      <div className="hidden md:flex md:flex-col pl-2 text-center w-[250px] ">
        <p className="uppercase whitespace-nowrap truncate ">{selectedBrand.name}</p>
        <p className="text-sm whitespace-nowrap truncate">{selectedBrand.city}</p>
      </div>
      <div className=" flex justify-center ml-3 mt-5 md:mt-0 md:ml-0 md:flex md:justify-center md:pr-6 pointer-events-none ">
        {navItems.map((item, index) => (
          <Link to={`${item.link}${selectedBrand.id}`}>
            <StepsButton
              ButtonType={etape == item.number ? "validated" : ""}
              data={item}
              key={index}
            />
          </Link>
        ))}
      </div>
    </nav>
  );
}
