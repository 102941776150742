import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginUser, validateToken } from "api/authentication";
import { fetchMe } from "api/users/Me";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const initialState = {
  header: { error: false, status: "nothing", connected: false },
  user: {
    id: "",
    token: cookies.get("::token") || null,
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    country: "",
    Brand: [],
    Subscription: [],
  },
};

export const fetchUser = createAsyncThunk("user/login", async (data) => {
  const token = await loginUser(data);
  const user = await fetchMe(token);
  return { ...user, token };
});

export const validateUser = createAsyncThunk("user/validate", async () => {
  const token = cookies.get("::token");
  const user = await fetchMe(token);
  if (user && user.id)
    return {
      user: { ...user, token },
      header: { error: false, status: "success", connected: true },
    };
  else
    return {
      user: { ...initialState.user },
      header: { error: true, status: "rejected", connected: false },
    };
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeUser: (state) => {
      state.header = { ...initialState.header };
      state.user = { ...initialState.user };
      cookies.remove("::token");
    },
    setUser: (state, action) => {
      state.header = { error: false, status: "done", connected: true };
      state.user = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    /* Waiting for user to be fetched .... */
    builder.addCase(fetchUser.pending, (state) => {
      state.header = { error: false, status: "pending" };
    });
    /* User successfully fetched */
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.header = { error: false, status: "success", connected: true };
      state.user = { ...action.payload };
      cookies.set("::token", action.payload.token, { path: "/" });
    });
    /* An error appeared */
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.header = { error: true, status: "error", connected: false };
    });

    /* Waiting for user to be validated .... */
    builder.addCase(validateUser.pending, (state) => {
      state.header = { error: false, status: "pending" };
    });
    /* User successfully validated */
    builder.addCase(validateUser.fulfilled, (state, action) => {
      state.header = { ...action.payload.header };
      state.user = { ...action.payload.user };
    });
    /* An error appeared */
    builder.addCase(validateUser.rejected, (state, action) => {
      state.header = { error: true, status: "error" };
    });
  },
});

export const { removeUser, setUser } = userSlice.actions;
export const userSelector = (state) => state.user;
export default userSlice.reducer;
