import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "./menu.css";

// ATOMS
import {
  LogoPicker,
  DefaultButton,
  CategorieSeparation,
  HorizontalLine,
  ThinHorizontalLine,
  Icons,
  SmoothCard,
  BorderedButton,
} from "components/atoms";

// COMPONENTS
import {
  DishCreationPatron,
  DrinkVolumesEditor,
  FormuleCreationPatron,
  CommentCreationPatron,
  DrinkCreationPatron,
} from "components";

// API
import { createDish } from "api/dish/dish";
import { fetchOneMenu } from "api/menu/menu";
import { sendMenuSexLogo, updateMenuSex } from "api/menuSex/menuSex";
import { createComment } from "api/comment/comment";
import { createDrinkCategory } from "api/drinkCategory/drinkCategory";
import { positionDown, positionUp } from "api/position";

// STORE
import { customSelector } from "store/models/customInfo";
import { userSelector } from "store/models/userInfo";

// HELPERS
import { findDataCustom, findFont } from "helpers/findDataCustom";
import { DeleteAvatar } from "api/images/images";
import { focusInput } from "helpers/focusInput";

export function Menu({ brand }) {
  // USESTATES
  const [sex, setSex] = useState(null);
  const [formules, setFormules] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [menu, setMenu] = useState(null);
  const [sexSelectedIndex, setSexSelectedIndex] = useState(null);
  const [logo, setLogo] = useState(null);
  const [idsex, setidsex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drinkVolumes, setDrinkVolumes] = useState(null);
  const [customData, setCustomData] = useState(null);

  // USESELECTOR
  // Récuperer les infos du user
  const { user } = useSelector(userSelector);
  // Récuperer les infos du custom
  const custom = useSelector(customSelector);

  // REFS
  const navBarParent = useRef(null);
  const navBarScroll = useRef(null);

  // USEEFFECTS
  // Prendre en compte les changer du tableau custom et les appliquer
  useEffect(() => {
    if (custom) {
      setCustomData(findDataCustom(custom.customs, brand.id));
    }
  }),
    [custom];

  // Prendre en compte le changement d'image de la catégorie
  // useEffect(() => {
  //   if (logo && idsex) {
  //     sendMenuSexLogo({ id: idsex, userId: user.id, logo });
  //     getOneMenu();
  //   }

  // }, [logo]);

  useEffect(() => {
    document.getElementById("navBarScroll");
  }, []);

  useEffect(() => {
    if (brand && brand.Menu && brand.Menu[0] && brand.Menu[0].MenuSection) {
      setSex(brand.Menu[0].MenuSection);
      setMenuId(brand.Menu[0].id);
    }
    if (brand && brand.Menu && brand.Menu[0] && brand.Menu[0].Formule) {
      setFormules(brand.Menu[0].Formule);
    }
  }, [brand]);

  useEffect(() => {
    if (menu && menu.MenuSection) {
      setSex(menu.MenuSection);
    }
    if (menu && menu.Formule) {
      setFormules(menu.Formule);
    }
  }, [menu]);

  useEffect(() => {
    if (sex && !idsex) {
      const { hash } = window.location;
      if (hash !== "") {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }
    }
  }, [window.location, sex]);

  useEffect(() => {
    getScrollNavBarWidth();
  });

  useEffect(() => {
    window.addEventListener("resize", getScrollNavBarWidth);
  }, []);

  // FUNCTIONS
  // RECUPERER TOUTES LES INFOS DU MENU
  const getOneMenu = async () => {
    setLoading(true);
    setMenu(await fetchOneMenu({ token: user.token, id: menuId }));
    setLoading(false);
  };

  // CREER UN DISH
  const sendDish = async (id, e) => {
    const { data } = await createDish(user.token, id);
    await getOneMenu();
    focusInput("dish", data.dish.id);
  };

  //CREATION UNE SOUS-CATEGORIE DE DRINK
  const sendDrinkCategory = async (id) => {
    const { data } = await createDrinkCategory(user.token, id);
    await getOneMenu();
    focusInput("drink-category", data.drinkCategory.id);
  };

  // CREER UN COMMENTAIRE
  const sendComment = async (id) => {
    const { data } = await createComment(user.token, id);
    await getOneMenu();
    focusInput("comment", data.comment.id);
  };

  // CHANGER LE NOM D'UNE SECTION
  const changeMenuSection = async (payload, id) => {
    await updateMenuSex(id, payload);
    getOneMenu();
  };

  // CHANGER UNE SECTION DE POSITION
  const changeSectionPosition = async (section, action) => {
    setLoading(true);
    if (action === "up") {
      await positionDown(section.id, "menusection");
    }

    if (action === "down") {
      await positionUp(section.id, "menusection");
    }
    getOneMenu();
    setLoading(false);
  };

  // SUPPRIMER UNE SECTION
  const removeMenuSection = async (id) => {
    setLoading(true);
    await updateMenuSex(id, { isSelected: false });
    getOneMenu();
  };

  // SUPPRIMER UNE IMAGE DE CATEGORIE
  async function deleteAvatar(avatar, userId, type) {
    setLoading(true);
    await DeleteAvatar(avatar, userId, type);
    getOneMenu();
    setLoading(false);
  }

  async function uploadAvatar(idsex, userId, logo) {
    setLoading(true);
    await sendMenuSexLogo({ id: idsex, userId: userId, logo });
    getOneMenu();
    setLoading(false);
  }

  // ADAPTER LE STYLE DE LA NAVBAR EN FONCTION DE LA TAILLE DE L'ECRAN
  const getScrollNavBarWidth = () => {
    if (
      navBarScroll &&
      navBarScroll.current &&
      navBarParent &&
      navBarParent.current
    ) {
      if (navBarScroll.current.clientWidth > window.screen.width) {
        navBarParent.current.style.width = "100vw";
        navBarParent.current.style.overflow = "auto";
        navBarParent.current.style.display = "flex";
        navBarParent.current.style.justifyContent = "flex-start";
      } else {
        navBarParent.current.style.display = "flex";
        navBarParent.current.style.overflow = "none";
        navBarParent.current.style.justifyContent = "flex-start";
      }
    }
  };

  // COULEUR DE LA NAVBAR
  const getColorforNavbar = () => {
    return {
      // si la section est sélectionnée
      active: {
        backgroundColor:
          (customData && customData.color[1].custom.color) || "#fff",
        color: (customData && customData.color[0].custom.color) || "#000",
      },
      // si elle ne l'est pas
      inactive: {
        backgroundColor: "transparent",
        color: (customData && customData.color[1].custom.color) || "#fff",
      },
    };
  };

  return (
    <div className="w-full m-auto flex flex-col items-center">
      {sex && sex.length > 0 && (
        <div
          ref={navBarParent}
          className="sticky top-0 z-10 w-full border-b-2 border-solid overflow-auto m-auto flex justify-start    border-black hideScrollBar"
          style={{
            borderColor: customData && customData.color[1].custom.color,
            backgroundColor: customData && customData.color[0].custom.color,
          }}
        >
          <div
            ref={navBarScroll}
            className={`   text-center flex m-auto sticky top-0 bg-white z-10 h-[40px] items-end
            ${customData && findFont(customData.police[0].custom.fontFamily)}
                ${
                  customData && customData.police[0].custom.bold
                    ? "font-bold"
                    : "font-normal"
                }
                ${
                  customData && customData.police[0].custom.italic
                    ? "italic"
                    : ""
                }
            `}
            style={{
              backgroundColor: customData && customData.color[0].custom.color,
              borderColor: customData && customData.color[1].custom.color,
            }}
          >
            {sex &&
              sex.map((row, index) => (
                <a
                  className={`h-[40px] flex min-w-max items-center px-3   ${
                    ((!sexSelectedIndex && index === 0) ||
                      sexSelectedIndex === row.id) &&
                    "bg-black text-white "
                  }  cursor-pointer `}
                  style={
                    (!sexSelectedIndex && index === 0) ||
                    sexSelectedIndex === row.id
                      ? { ...getColorforNavbar().active }
                      : { ...getColorforNavbar().inactive }
                  }
                  onClick={() => {
                    setSexSelectedIndex(row.id);
                  }}
                  href={`#sex${row.id}`}
                >
                  {row.title}
                </a>
              ))}
          </div>
        </div>
      )}
      <div className="  w-11/12 max-w-[1400px]  flex flex-col items-center">
        {sex &&
          sex.map((row, index) => (
            <SmoothCard
              padding={row.title === "Commentaires"}
              className="xl:w-4/5 w-full my-10 bg-transparent border border-white "
            >
              <div
                key={row.id}
                id={`sex${row.id}`}
                className="w-full mb-5 md:pb-7"
                onClick={() => {
                  setidsex(row.id);
                }}
              >
                <div className="w-11/12 m-auto">
                  {row.title !== "Commentaires" && (
                    <LogoPicker
                      key={index}
                      defaultLogo={row.avatar}
                      setLogo={(e) => {
                        setLogo(e), uploadAvatar(idsex, user.id, e);
                      }}
                      visual="smoothCard"
                      ratio={9 / 3}
                      wh={0}
                      lib={"Ajouter un visuel à la catégorie (Facultatif)"}
                      className="w-full "
                      libColor={customData && customData.color[1].custom.color}
                      onClick={() =>
                        deleteAvatar(sex[index].avatar, user.id, "menuSection")
                      }
                    />
                  )}

                  <div className="flex justify-between items-center ">
                    <input
                      defaultValue={row.title}
                      id={`section${row.id}`}
                      placeholder="Nom de la catégorie..."
                      className={`bg-transparent
                  
                  ${
                    row.title === ""
                      ? "border border-grey-disabled text-xl      pl-2 w-8/12 md:w-1/3"
                      : "border-none truncate text-xl      pl-2 w-8/12 md:w-3/4"
                  }
                  ${
                    customData &&
                    findFont(customData.police[0].custom.fontFamily)
                  }
                  ${
                    customData &&
                    customData.police[0].custom.bold &&
                    "font-bold"
                  }
                  ${
                    customData && customData.police[0].custom.italic && "italic"
                  }
                `}
                      style={{
                        fontSize:
                          customData && customData.police[0].custom.fontSize,
                        letterSpacing:
                          customData &&
                          customData.police[0].custom.letterSpacing,
                        color: customData && customData.color[1].custom.color,
                        width: "auto",
                        height: "1.8em",
                      }}
                      onBlur={(e) =>
                        changeMenuSection(
                          { title: e.target.value.trim() },
                          row.id
                        )
                      }
                    />

                    <div className="flex items-center">
                      <Icons
                        name="crayon  "
                        height="20"
                        style={{
                          color: customData && customData.color[1].custom.color,
                        }}
                        className="mr-3 md:mr-5 hover:opacity-50 active:opacity-100"
                        onClick={() =>
                          document.getElementById(`section${row.id}`).focus()
                        }
                      />
                      <div
                        onClick={() =>
                          document.getElementById(`section${row.id}`).focus()
                        }
                        className=" hover:opacity-50 active:opacity-100 absolute mt-[2.5px] md:mt-[5px] ml-[20px]"
                      >
                        <Icons
                          name="crayon-1  "
                          style={{
                            color:
                              customData && customData.color[1].custom.color,
                          }}
                          height="20"
                        />
                        <Icons
                          name="crayon-2  "
                          style={{
                            color:
                              customData && customData.color[1].custom.color,
                          }}
                          height="20"
                        />
                      </div>
                      <div className="flex flex-col">
                        {sex.length !== 1 && (
                          <>
                            {index != 0 && (
                              <Icons
                                name="arrow-up"
                                height="20"
                                style={{
                                  color:
                                    customData &&
                                    customData.color[1].custom.color,
                                }}
                                className="mr-3 md:mr-5 hover:opacity-50 active:opacity-100"
                                onClick={() => changeSectionPosition(row, "up")}
                              />
                            )}
                            {index + 1 != sex.length && (
                              <Icons
                                name="arrow-down"
                                height="20"
                                style={{
                                  color:
                                    customData &&
                                    customData.color[1].custom.color,
                                }}
                                className="mr-3 md:mr-5 hover:opacity-50 active:opacity-100"
                                onClick={() =>
                                  changeSectionPosition(row, "down")
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                      <Icons
                        name="trash"
                        height="20"
                        style={{
                          color: customData && customData.color[1].custom.color,
                        }}
                        onClick={() => {
                          removeMenuSection(row.id);
                        }}
                        className="px-2.5"
                      />
                    </div>
                  </div>
                  <HorizontalLine
                    className=" mt-2 mb-3 border-[1px]"
                    style={{
                      borderColor:
                        customData && customData.color[3].custom.color,
                    }}
                  />
                  <div>
                    <div>
                      {row &&
                        row.Dish &&
                        row.Dish.map((dish, index) => {
                          return (
                            <DishCreationPatron
                              dishData={dish}
                              dishId={dish.id}
                              dishList={row.Dish}
                              loadData={() => getOneMenu()}
                              custom={customData}
                              setLoading={setLoading}
                              index={index}
                              length={row.Dish.length}
                            />
                          );
                        })}
                      {row.Dish.length > 0 ? (
                        <ThinHorizontalLine
                          className="mb-5 md:mb-3  md:mt-0 mt-5"
                          style={{
                            borderColor:
                              customData && customData.color[3].custom.color,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {row && row.DrinkVolume && row.DrinkVolume[0] && (
                        <DrinkVolumesEditor
                          volume={row.DrinkVolume[0]}
                          callback={(data) => setDrinkVolumes(data)}
                          custom={customData}
                        />
                      )}
                      {row &&
                        row.DrinkVolume &&
                        row.DrinkVolume[0] &&
                        row.DrinkVolume[0].DrinkCategory &&
                        row.DrinkVolume[0].DrinkCategory.map(
                          (drinkK, index) => (
                            <DrinkCreationPatron
                              drinkCategory={drinkK}
                              drinkCategoryId={drinkK.id}
                              menuSectionName={row.title}
                              loadData={() => getOneMenu()}
                              custom={customData}
                              setLoading={setLoading}
                              index={index}
                              length={row.DrinkVolume[0].DrinkCategory.length}
                              volumes={
                                drinkVolumes || {
                                  "15cl": row.DrinkVolume[0].x15cl,
                                  "25cl": row.DrinkVolume[0].x25cl,
                                  "37,5cl": row.DrinkVolume[0].x375cl,
                                  "50cl": row.DrinkVolume[0].x50cl,
                                  "75cl": row.DrinkVolume[0].x75cl,
                                }
                              }
                            />
                          )
                        )}
                      {row &&
                      row.DrinkVolume &&
                      row.DrinkVolume[0] &&
                      row.DrinkVolume[0].DrinkCategory &&
                      row.DrinkVolume[0].DrinkCategory.length > 0 ? (
                        <ThinHorizontalLine
                          className="mb-5 md:mb-3  md:mt-0 mt-5"
                          style={{
                            borderColor:
                              customData && customData.color[3].custom.color,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {row &&
                        row.Comment &&
                        row.Comment.map((comment, index) => (
                          <CommentCreationPatron
                            loadData={() => getOneMenu()}
                            commentData={comment}
                            commentId={comment.id}
                            custom={customData}
                            index={index}
                            length={row.Comment.length}
                            setLoading={setLoading}
                          />
                        ))}
                      {row.Comment.length > 0 ? (
                        <ThinHorizontalLine
                          className="mb-5 md:mb-3  md:mt-0 mt-5"
                          style={{
                            borderColor:
                              customData && customData.color[3].custom.color,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <ThinHorizontalLine className="mb-5 md:mb-3  md:mt-0 mt-5" style={{ borderColor: customData && customData.color[3].custom.color,}}/> */}

                    <div className="flex flex-col items-center md:block pt-3">
                      {row.isGlobal && row.title === "Vins" ? (
                        <DefaultButton
                          onClick={() => {
                            sendDrinkCategory(row.DrinkVolume[0].id);
                          }}
                          className=" "
                        >
                          Ajouter une sous catégorie de {row.title}
                        </DefaultButton>
                      ) : row.title === "Commentaires" ? (
                        <BorderedButton
                          onClick={() => {
                            sendComment(row.id);
                          }}
                          className=" mb-7  md:mb-0"
                          style={{
                            borderColor:
                              customData && customData.color[1].custom.color,
                            color:
                              customData && customData.color[1].custom.color,
                          }}
                        >
                          Ajouter un commentaire
                        </BorderedButton>
                      ) : (
                        <>
                          <DefaultButton
                            onClick={(e) => {
                              sendDish(row.id, e);
                            }}
                            className="mb-5 md:mb-0"
                          >
                            Ajouter un plat
                          </DefaultButton>
                          <BorderedButton
                            onClick={() => {
                              sendComment(row.id);
                            }}
                            className=" font-medium  md:ml-[35px]   md:mb-0"
                            style={{
                              borderColor:
                                customData && customData.color[1].custom.color,
                              color:
                                customData && customData.color[1].custom.color,
                            }}
                          >
                            Ajouter un commentaire
                          </BorderedButton>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SmoothCard>
          ))}

        {formules &&
          formules.map((formule, index) => (
            <div
              key={formule.id}
              className="flex flex-col items-center w-full md:my-8 "
            >
              <FormuleCreationPatron
                formule={formule}
                formuleId={formule.id}
                loadData={() => getOneMenu()}
                custom={customData}
                formuleList={formules}
                index={index}
                length={formules.length}
                setLoading={setLoading}
              />
              <CategorieSeparation
                color={customData && customData.color[3].custom.color}
              />
            </div>
          ))}
        {loading && (
          <div className="fixed top-0 left-0 h-screen w-screen bg-black flex justify-center items-center z-50 bg-opacity-50">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
