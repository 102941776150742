import { Icons } from "components/atoms"
import { Link } from "react-router-dom"

export function BasicFooter({ backgroundColor, className }) {
    if (backgroundColor === "yellow") {
        return (
            <nav className="bg-yellow-lightest flex py-6 border-t border-solid border-black  bottom-0 md:w-full ">
                <div className="flex md:items-center md:ml-52">
                    <img src="/assets/brand/logo.svg" alt="" className=" " />
                    <a className="font-poppins font-regular text-sm underline mx-14">Mentions légales</a>
                    <a className="font-poppins font-regular text-sm underline" href="mailto:nathan@agencerjs.com">Nous contacter</a>
                </div>
            </nav>
        )
    } else {
        return (
            <nav className="bg-white py-4 md:py-6 border-t border-solid border-black  bottom-0  w-full  ">
                <div className="flex md:justify-between md:w-2/3 m-auto">
                    <div className="md:w-auto w-2/3 flex flex-col md:flex md:flex-row md:items-center ml-11 md:ml-0 ">
                        <div className="flex mb-5 md:mb-0 ">
                            <img src="/assets/brand/logo.svg" alt="" className="h-3 md:h-5" />
                        </div>
                        <div className="flex md:mx-14 ">
                            <a className="font-poppins font-medium text-smallest md:text-sm underline md:mr-14 mr-2.5 ">Mentions légales</a>
                            <a className="font-poppins font-medium text-smallest md:text-sm underline" href="mailto:nathan@agencerjs.com">Nous contacter</a>
                        </div>

                    </div>
                    <div className="flex items-end md:items-center ">
                        <a href="#"><Icons name="facebook" className=" mr-3 md:mr-10" height="md:20"/></a>
                        
                        <a href="#"><Icons name="instagram" height="md:20"/></a>
                    </div>

                </div>
            </nav>
        )
    }
}
