import { Card, DefaultButton, LogoPicker } from "components/atoms";
import { Menu } from "components";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { PopupFormule } from "../Popup/PopupFormule";
import { StepFooter } from "components";
import { findFont } from "helpers/findDataCustom";

export function AddCategorieFormule({
  className,
  selectedBrand,
  selectedBrandId,
  loadData = () => {},
  custom,
}) {
  const [sexlength, setSexlength] = useState(0);
  const [formulelength, setFormulelength] = useState(0);
  const [upFormule, setUpFormule] = useState(false);

  useEffect(() => {
    if (
      selectedBrand &&
      selectedBrand.Menu &&
      selectedBrand.Menu[0] &&
      selectedBrand.Menu[0].MenuSection
    ) {
      setSexlength(selectedBrand.Menu[0].MenuSection.length);
    }
    if (
      selectedBrand &&
      selectedBrand.Menu &&
      selectedBrand.Menu[0] &&
      selectedBrand.Menu[0].Formule
    ) {
      setFormulelength(selectedBrand.Menu[0].Formule.length);
    }
  }, []);

  return (
    <div className="flex items-center flex-col mt-4 mb-16 w-[full] m-auto">
      {upFormule && (
        <PopupFormule
          menuId={selectedBrand.Menu[0].id}
          close={() => setUpFormule(false)}
          loadData={() => loadData()}
        />
      )}
      <Card
        className={`w-full md:w-${
          sexlength === 0 && formulelength === 0 ? "900" : "full"
        }`}
        style={{
          backgroundColor: custom && custom.color[0].custom.color,
        }}
      >
        <div
          className=" flex flex-col items-center md:mx-0"
          
        >
          <div className="mt-10 m-auto">
            { selectedBrand.avatar ?<img src={selectedBrand.avatar} className="h-28" /> : ""}
          </div>
          <p 
                className={`font-poppins text-2xl  mb-2 ${selectedBrand.city ? "border-b border-solid border-grey-dark" :  ""} ${selectedBrand.avatar ? "mt-3" :  ""} w-full md:w-1/3 text-center pb-2
                ${
                  custom &&
                  findFont( selectedBrand.avatar ? custom && custom.police[2].custom.fontFamily : custom && custom.police[0].custom.fontFamily )
                }
                ${
                  selectedBrand.avatar ?  custom && custom.police[2].custom.bold
                  ? "font-bold"
                  : "" : custom && custom.police[0].custom.bold ? "font-bold"
                  : ""
                }
                ${
                  selectedBrand.avatar ? custom && custom.police[2].custom.italic
                  ? "italic"
                  : "" : custom && custom.police[0].custom.italic 
                }
              `}
        style={{
          fontSize: `${
           selectedBrand.avatar ? custom && custom.police[2].custom.fontSize : custom && custom.police[0].custom.fontSize
          }px`,

          letterSpacing: `${
            selectedBrand.avatar ? custom && custom.police[2].custom.letterSpacing : custom && custom.police[0].custom.letterSpacing
          }px`,
          color: selectedBrand.avatar ?custom && custom.color[2].custom.color : custom && custom.color[1].custom.color,
          borderColor: custom && custom.color[3].custom.color,
        }}>
                  {selectedBrand.name}
                </p>
                <p  className={`font-poppins text-sm mb-5
                ${
                  custom &&
                  findFont(custom && custom.police[3].custom.fontFamily)
                }
                ${
                  custom && custom.police[3].custom.bold
                    ? "font-bold"
                    : ""
                }
                ${
                  custom && custom.police[3].custom.italic
                    ? "italic"
                    : ""
                }
              `}
        style={{
          fontSize: `${
            custom && custom.police[3].custom.fontSize
          }px`,
          letterSpacing: `${
            custom && custom.police[3].custom.letterSpacing
          }px`,
          color: custom && custom.color[2].custom.color,
        }}>{selectedBrand.city}</p>

          <Menu brand={selectedBrand} />
        </div>
        {/* BUTTONS */}
        <div className="flex flex-col items-center gap-5 mt-5 md:flex md:flex-col md:items-center md:w-full md:justify-around md:mt-10 md:mb-3">
          <Link to={`/pages/dashboard/categories/${selectedBrandId}`}>
            <div className="flex flex-col items-center mb-3  ">
              <DefaultButton className="!mb-2">
                Ajouter une catégorie
              </DefaultButton>
              <p style={{color: custom && custom.color[2].custom.color}} className="font-poppins text-xs">
              (Ex : Entrées ou plats ou desserts)
              </p>
            </div>
          </Link>
          <div className="flex flex-col items-center mb-3 ">
            <DefaultButton
              
              onClick={() => setUpFormule(true)}
              className=" !mb-2"
            >
              Ajouter une formule
            </DefaultButton>
            <p style={{color: custom && custom.color[2].custom.color}} className="font-poppins text-xs">
              (Ex : Menu enfants, Menu midi)
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}

{
  /*{upFormule && (
<PopupFormule
  menuId={selectedBrand.Menu[0].id}
  close={() => setUpFormule(false)}
  loadData={() => loadData()}
/>
)} */
}
