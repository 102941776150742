import { DefaultButton, Paragraph, Card } from "components/atoms";
import { updateMenu } from "api/menu/menu";
import { updateBrand } from "api/brand/brand";
import { useEffect, useState } from "react";
import { PopUp } from "./popUp";

export function ConfigureUrl({
  menuId,
  brandId,
  user,
  existingUrl,
  getOneMenu,
  setUpdated,
}) {
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const [check, setCheck] = useState(false);
  const [url, setUrl] = useState(null);
  const [security, setSecurity] = useState(false);

  /* REGEX to check if url validate */
  const checkUrl = (str) => {
    const regex = /^[a-zA-Z0-9.-_-]+$/;
    return regex.test(str);
  };

  const handleUrlChanges = (str) => {
    if (!str || str.length < 3 || str.length > 24) {
      setError({
        status: true,
        message: "L'URL doit contenir entre 3 et 24 caractères maximum",
      });
    } else if (checkUrl(str)) {
      setUrl(str);
      setError({ status: false, message: "" });
    } else
      setError({
        status: true,
        message: "L'URL ne peut pas contenir de caractères spéciaux",
      });
  };

  const changeMenu = async (data) => {
    if (check && !error.status) {
      const responseMenu = await updateMenu({
        id: menuId,
        token: user.token,
        payload: data,
      });
      if (!responseMenu) {
        setError({
          status: true,
          message: "Cette URL est déjà utilisée, veuillez en choisir une autre",
        });
        setSecurity(false);
        return;
      }
      const responseBrand = await updateBrand({
        id: brandId,
        payload: { status: "PUBLISHED" },
      });
      if (!responseBrand) {
        setError({
          status: true,
          message:
            "Une erreur est survenue, votre restaurant n'est pas publié mais l'url à bien été enregistrée",
        });
        setSecurity(false);
        return;
      }
      getOneMenu();
      setUpdated(true);
      setError({ status: false, message: "" });
    } else {
      setError({
        status: true,
        message: "Veuillez valider toutes les conditions",
      });
      setSecurity(false);
    }
  };

  useEffect(() => {
    setUrl(existingUrl);
  }, [existingUrl]);

  return (
    <div>
      <div className="flex flex-col items-center mt-8">
        <div className="flex items-center">
          {/* <Paragraph className="text-gray-400">
              https://www.bonappli.fr/carte/
            </Paragraph> */}
          <Paragraph className="text-gray-400">
            {`${process.env.REACT_APP_PUBLIC_PROD_URL}/carte/`}
          </Paragraph>
          <input
            type="text"
            className="ml-1 mb-0.5 rounded border border-solid border-grey-disabled pl-1 outline-none"
            defaultValue={url}
            onChange={(e) => {
              //replace all space and / by -
              handleUrlChanges(e.target.value.replace(/\s/g, "-").replace(/\//g, "-"));
            }}
          ></input>
        </div>
        <div className="flex justify-between my-6">
          <input
            className="form-checkbox text-xl text-black mt-1 mr-3"
            type="checkbox"
            id="CGV"
            name="CGV"
            onClick={(e) => setCheck(e.target.checked)}
          />
          <label className="md:text-base font-thin" for="CGV">
            <p className="text-xs">
              En cliquant sur valider, vous vous apprêtez à valider l'adresse
              choisie ci-dessus. <br /> Vos clients pourront avoir accès à votre
              carte à cette adresse. Votre facturation démarrera dès la
              validation.
            </p>
          </label>
        </div>
        {error.status && (
          <p className="text-red text-sm mb-2">{error.message}</p>
        )}

        <DefaultButton
          className="mt-3 font-medium"
          ButtonType="classic"
          onClick={() => {
            if (check && url && 3 < url.length < 24) {
              setSecurity(true);
            } else
              setError({
                status: true,
                message: "Veuillez valider les conditions",
              });
          }}
        >
          Valider
        </DefaultButton>
        <p className="mt-12 text-xs text-center">
        Vous souhaitez un nom de domaine personnalisé ? <br/>
        Vous pouvez à tout moment <a href="mailto:nathan@agencerjs.com" className="underline font-medium" >nous contacter</a>.
        </p>
        
      </div>

      {/* SECURITY POP UP */}
      {security && (
        <PopUp>
          <h2 className="font-medium mb-2">Attention !</h2>
          <p>
            Changer d'URL entraînera la&nbsp;
            <label className="text-red">suppression</label> de tout les QRCodes
            édités pour ce restaurant.
          </p>
          <div className="flex items-end">
            <DefaultButton
              className="mt-6 font-medium mr-5"
              ButtonType="classic"
              onClick={() => {
                changeMenu({ url });
              }}
            >
              Valider
            </DefaultButton>
            <DefaultButton
              className="mt-6 font-medium bg-white border border-yellow"
              ButtonType="classic"
              onClick={() => {
                setSecurity(false);
              }}
            >
              Retour
            </DefaultButton>
          </div>
        </PopUp>
      )}
    </div>
  );
}
