import { BorderedButton, Card, DefaultButton } from "components/atoms";

export function DeletePopUp({
  children,
  onValidate = () => {},
  onCancel = () => {},
}) {
  return (
    <div className="fixed h-full z-20  w-full bg-black-opacity top-0 left-0 bottom-0 right-0 flex justify-center items-center">
      <Card className="md:w-5/12 lg:w-4/12 xl:w-3/12 px-4 w-9/12">
        <div className="py-4">
          <div>{children}</div>
          <div className="flex sm:flex-row flex-col items-center md:justify-center lg:justify-start">
            <DefaultButton
              ButtonType="smaller"
              className="mt-6  sm:mr-5 "
              onClick={() => onValidate()}
            >
              Supprimer
            </DefaultButton>
            <BorderedButton
              borderType="yellow"
              className="mt-6 !py-[7px] !px-7 "
              onClick={() => onCancel()}
            >
              Annuler
            </BorderedButton>
          </div>
        </div>
      </Card>
    </div>
  );
}
