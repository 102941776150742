import {
  deleteFormuleElement,
  updateFormuleElement,
} from "api/formule/formuleElement";
import {
  DefaultButton,
  HorizontalLine,
  Icons,
  ThinHorizontalLine,
  Title,
} from "components/atoms";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userSelector } from "store/models/userInfo";
import { positionDown, positionUp } from "api/position";
import { findFont } from "helpers/findDataCustom";

export function FormuleElementCreationPatron({
  formuleElement,
  formuleElementId,
  custom,
  formuleElementList,
  loadData = () => {},
  setLoading,
  index,
  length,
}) {
  const { user } = useSelector(userSelector);

  const [subElements, setSubElements] = useState(formuleElement.subElements);

  const removeFormuleElement = async () => {
    await deleteFormuleElement({ id: formuleElementId, token: user.token });
    loadData();
  };
  const changeFormuleElement = async (data) => {
    await updateFormuleElement(formuleElementId, data);
  };

  useEffect(() => {
    if (subElements) {
      changeFormuleElement({ subElements: subElements });
    }
  }, [subElements]);

  const changePosition = async (section, action) => {
    setLoading(true);
    if (action === "up") {
      await positionDown(section.id, "formuleelement");
    }

    if (action === "down") {
      await positionUp(section.id, "formuleelement");
    }
    loadData();
    setLoading(false);
  };

  function remooveFormuleElement(key) {
    let wait = { ...subElements };
    delete wait[key];
    setSubElements(wait);
  }

  return (
    <div className="mt-8" id={`formule-element-${formuleElementId}`}>
      <div className="flex md:flex-row flex-col-reverse md:justify-between md:items-center">
        <input
          className={`border border-solid bg-transparent  border-grey-disabled rounded bg-transparent font-poppins p-3 w-full md:w-2/3
                ${custom && findFont(custom.police[2].custom.fontFamily)}
                ${custom && custom.police[2].custom.bold && "font-bold"}
                ${custom && custom.police[2].custom.italic && "italic"}
                ${custom && !custom.display[0].visible && "opacity-50"}
          `}
          style={{
            fontSize: custom && custom.police[2].custom.fontSize,
            letterSpacing: custom && custom.police[2].custom.letterSpacing,
            color: custom && custom.color[0].custom.color,
          }}
          placeholder="Ex : entrées ou plats"
          defaultValue={formuleElement.name}
          onBlur={(e) => changeFormuleElement({ name: e.target.value.trim() })}
          name={`formule-element-${formuleElementId}`}
        ></input>
        <div className="md:text-right flex gap-5 md:gap-0 justify-end items-center md:mb-0 mb-[15px]">
          {/* <Icons name="edit" height="20" /> */}
          <div className="flex flex-col">
            {length !== 1 && (
              <>
                {index != 0 && (
                  <Icons
                    name="arrow-up"
                    height="20"
                    style={{
                      color: custom && custom.color[0].custom.color,
                    }}
                    className=" md:mr-5 hover:opacity-50 active:opacity-100"
                    onClick={() => changePosition(formuleElement, "up")}
                  />
                )}
                {index + 1 != length && (
                  <Icons
                    name="arrow-down"
                    height="20"
                    style={{
                      color: custom && custom.color[0].custom.color,
                    }}
                    className=" md:mr-5 hover:opacity-50 active:opacity-100"
                    onClick={() => changePosition(formuleElement, "down")}
                  />
                )}
              </>
            )}
          </div>
          <Icons
            name="trash"
            height="20"
            style={{
              color: custom && custom.color[0].custom.color,
            }}
            onClick={() => removeFormuleElement()}
            className="px-2.5 md:px-2.5"
          />
        </div>
      </div>
      <div className="flex flex-col items-start ">
        {subElements &&
          Object.keys(subElements).map((subElemKey) => (
            <div className="flex w-full  items-center justify-between">
              <div className="w-full md:w-2/3 md:flex md:justify-end ">
                <input
                  className={`border border-solid bg-transparent border-grey-disabled font-poppins p-3 w-11/12  md:w-11/12  my-1 
                ${custom && findFont(custom.police[3].custom.fontFamily)}
                ${custom && custom.police[3].custom.bold && "font-bold"}
                ${custom && custom.police[3].custom.italic && "italic"}
                ${custom && !custom.display[0].visible && "opacity-50"}
                `}
                  style={{
                    fontSize: custom && custom.police[3].custom.fontSize,
                    letterSpacing:
                      custom && custom.police[3].custom.letterSpacing,
                    color: custom && custom.color[0].custom.color,
                  }}
                  placeholder="| sous-élément "
                  defaultValue={subElements[subElemKey]}
                  onBlur={(e) =>
                    setSubElements({
                      ...subElements,
                      [subElemKey]: e.target.value.trim(),
                    })
                  }
                ></input>
              </div>
              <Icons
                name="trash"
                height="20"
                style={{
                  color: custom && custom.color[0].custom.color,
                }}
                onClick={() => remooveFormuleElement(subElemKey)}
                className="px-2.5 md:px-2.5"
              />
            </div>
          ))}
        <DefaultButton
          className="my-2"
          onClick={() => {
            setSubElements({
              ...subElements,
              [`name${
                subElements ? Object.keys(subElements).length + 99999 : 99999
              }`]: "",
            });
          }}
        >
          Ajouter un sous-élément
        </DefaultButton>
      </div>
      <div className="relative my-8">
        <div
          className="h-0 border-t border-orange"
          style={{
            borderColor: custom && custom.color[3].custom.color,
          }}
        />
        <span
          className="absolute font-poppins font-black text-5xl h-[47px] top-1/2 md:mt-[1px] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          style={{
            color: custom && custom.color[3].custom.color,
          }}
        >
          +
        </span>
      </div>
    </div>
  );
}
