import {
  HorizontalLine,
  LogoPicker,
  Icons,
  CategorieSeparation,
} from "components/atoms";
import { useState, useEffect } from "react";
import { updateDish } from "api/dish/dish";
import { sendDishLogo, deleteDish } from "api/dish/dish";
import { userSelector } from "store/models/userInfo";
import { useSelector } from "react-redux";
import { findFont } from "helpers/findDataCustom";
import { positionDown, positionUp } from "api/position";
import addAlpha from "helpers/addAlpha";
import { DeleteAvatar } from "api/images/images";

export function DishCreationPatron({
  dishData,
  dishId,
  custom,
  loadData = () => {},
  dishList,
  setLoading,
  index,
  length,
}) {
  const { user } = useSelector(userSelector);
  const [logo, setLogo] = useState(null);

  function ingrediantsCompose(ingrediantsString) {
    const array = ingrediantsString.split(";");
    return array.filter((n) => n);
  }

  function composeIngrediants(ingrediantsArray) {
    let str = "";
    ingrediantsArray.forEach((row) => {
      str += `${row}`;
    });
    return str;
  }

  async function deleteAvatar(avatar, userId, type) {
    await DeleteAvatar(avatar, userId, type);
    loadData();
  }

  const changeDish = async (data) => {
    await updateDish(dishId, data);
  };

  const removeDish = async () => {
    await deleteDish({ id: dishId, userId: user.id });
    loadData();
  };

  const changePosition = async (section, action) => {
    setLoading(true);
    if (action === "up") {
      await positionDown(section.id, "dish");
    }

    if (action === "down") {
      await positionUp(section.id, "dish");
    }
    loadData();
    setLoading(false);
  };

  async function uploadAvatar(dishId, userId, logo) {
    setLoading(true);
    await sendDishLogo({ id: dishId, userId: userId, logo });
    loadData();
    setLoading(false);
  }

  // useEffect(() => {

  //   if (logo) {
  //     sendDishLogo({ id: dishId, userId: user.id, logo });
  //   }

  // }, [logo]);

  return (
    <div key={dishData.id} id={`dish-${dishId}`}>
      <div className="flex flex-col md:mb-6 md:flex md:flex-row md:mt-0 mt-6">
        <div className="flex justify-between md:block h-full">
          <div className="bg-yellow-lightest  !h-[135px] !w-[135px]">
            <LogoPicker
              key={dishData.id}
              defaultLogo={dishData.avatar}
              setLogo={(e) => {
                setLogo(e), uploadAvatar(dishId, user.id, e);
              }}
              className="!max-w-[135px]  !h-[135px] !border-none"
              he={"!max-h-[135px]"}
              mt={false}
              mb={false}
              lib={""}
              onClick={() => deleteAvatar(dishData.avatar, user.id, "dish")}
            />
          </div>
          <div className="md:hidden flex flex-col justify-around ">
            <div className="flex flex-col  items-center ">
              {length !== 1 && (
                <>
                  {index != 0 && (
                    <Icons
                      name="arrow-up"
                      height="20"
                      style={{ color: custom && custom.color[1].custom.color }}
                      className="hover:opacity-50 active:opacity-100"
                      onClick={() => changePosition(dishData, "up")}
                    />
                  )}
                  {index + 1 != length && (
                    <Icons
                      name="arrow-down"
                      height="20"
                      style={{ color: custom && custom.color[1].custom.color }}
                      className="hover:opacity-50 active:opacity-100"
                      onClick={() => changePosition(dishData, "down")}
                    />
                  )}
                </>
              )}
            </div>
            <Icons
              name="trash"
              height="20"
              style={{ color: custom && custom.color[1].custom.color }}
              onClick={(e) => {
                e.preventDefault();
                removeDish({ id: dishId, userId: user.id });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between w-full  md:pl-10 mt-4 md:mt-0">
          <div className="hidden md:flex md:justify-end md:items-center">
            <div className="flex flex-col">
              {length !== 1 && (
                <>
                  {index != 0 && (
                    <Icons
                      name="arrow-up"
                      height="20"
                      style={{ color: custom && custom.color[1].custom.color }}
                      className="hover:opacity-50 active:opacity-100"
                      onClick={() => changePosition(dishData, "up")}
                    />
                  )}
                  {index + 1 != length && (
                    <Icons
                      name="arrow-down"
                      height="20"
                      style={{ color: custom && custom.color[1].custom.color }}
                      className="hover:opacity-50 active:opacity-100"
                      onClick={() => changePosition(dishData, "down")}
                    />
                  )}
                </>
              )}
            </div>
            <Icons
              name="trash"
              height="20"
              style={{ color: custom && custom.color[1].custom.color }}
              onClick={(e) => {
                e.preventDefault();
                removeDish({ id: dishId, userId: user.id });
              }}
              className="px-2.5"
            />
          </div>
          <div className="w-full md:flex-row md:flex  ">
            <div className="w-full md:flex-2">
              <input
                className={`py-2.5 pl-3 md:pl-5 w-full md:mb-1.5 mb-2 rounded border border-solid border-grey-light outline-none bg-transparent ${
                  custom && findFont(custom.police[2].custom.fontFamily)
                }
                    ${custom && custom.police[2].custom.bold && "font-bold"}
                    ${custom && custom.police[2].custom.italic && "italic"}
                    `}
                name={`dish-${dishData.id}`}
                placeholder="Nom du plat"
                defaultValue={dishData.name}
                style={{
                  borderColor:
                    custom && addAlpha(custom.color[1].custom.color, 0.2),
                  fontSize: custom && custom.police[2].custom.fontSize,
                  letterSpacing:
                    custom && custom.police[2].custom.letterSpacing,
                  color: custom && custom.color[2].custom.color,
                }}
                onBlur={(e) => changeDish({ name: e.target.value.trim() })}
              ></input>
            </div>

            <div className=" block md:flex-1 md:flex ">
              <div className="  md:px-2 px-3 flex items-center md:flex-1">
                <div
                  className=" w-full h-1  hidden md:block "
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%)",
                    backgroundPosition: "center",
                    backgroundSize: "10px 1.5px",
                    backgroundRepeat: "repeat-x",
                  }}
                ></div>
              </div>
              <div className="flex">
                <input
                  className={`
                 py-2.5 md:py-2 md:mb-0 mb-2 border w-20 md:mr-3 rounded-sm border-solid border-grey-disabled bg-transparent font-poppins text-base text-center 
                ${custom && !custom.display[0].visible && "opacity-50"}
                ${custom && findFont(custom.police[4].custom.fontFamily)}
                ${custom && custom.police[4].custom.bold && "font-bold"}
                ${custom && custom.police[4].custom.italic && "italic"}
              `}
                  style={{
                    borderColor:
                      custom && addAlpha(custom.color[1].custom.color, 0.2),
                    fontSize: custom && custom.police[4].custom.fontSize,
                    letterSpacing:
                      custom && custom.police[4].custom.letterSpacing,
                    color: custom && custom.color[2].custom.color,
                  }}
                  placeholder="Prix"
                  defaultValue={dishData.price}
                  onBlur={(e) => changeDish({ price: e.target.value.trim() })}
                ></input>
              </div>
              {custom && !custom.display[0].visible && (
                <div className="w-full h-full absolute top-0 left-0 cursor-not-allowed" />
              )}
            </div>
          </div>
          <div className="md:flex  md:items-center md:w-2/3">
            <input
              className={`
               mb-0.5 rounded border border-solid border-grey-light outline-none py-2.5 md:mb-1 pl-3 md:pl-5 w-full md:w-full bg-transparent
              ${custom && findFont(custom.police[3].custom.fontFamily)}
              ${custom && custom.police[3].custom.bold && "font-bold"}
              ${custom && custom.police[3].custom.italic && "italic"}
            `}
              style={{
                borderColor:
                  custom && addAlpha(custom.color[1].custom.color, 0.2),
                fontSize: custom && custom.police[3].custom.fontSize,
                letterSpacing: custom && custom.police[3].custom.letterSpacing,
                color: custom && custom.color[2].custom.color,
              }}
              placeholder="Ingrédients"
              defaultValue={composeIngrediants(dishData.ingredients)}
              onBlur={(e) =>
                changeDish({ ingredients: ingrediantsCompose(e.target.value) })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
