import { useEffect } from "react";
import fontsList from "utils/fontsList";
import FontPicker from "font-picker-react";
import { Icons, Selector } from "../..";

export function EditFontRestau({ index, editingFont, setEditingFont, fonts }) {
  const changeFont = (e) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: { ...editingFont[index].custom, fontFamily: e.target.value },
    };

    setEditingFont(tmp);
  };

  const changeFontSize = (e) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        fontSize: Number(e.target.value),
      },
    };

    setEditingFont(tmp);
  };

  const changeletterSpacing = (e) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        letterSpacing: Number(e.target.value),
      },
    };

    setEditingFont(tmp);
  };

  const changeBold = () => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        bold: !editingFont[index].custom.bold,
      },
    };

    setEditingFont(tmp);
  };

  const changeItalic = (e) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        italic: !editingFont[index].custom.italic,
      },
    };

    setEditingFont(tmp);
  };

  return (
    <div className="bg-yellow bg-opacity-25 flex justify-between items-center py-2 px-4 rounded-lg">
      <p className="uppercase">{editingFont[index].title}</p>
      <div className="flex flex-col gap-2">
        {/* <select
          onChange={changeFont}
          className="border-0 rounded-full py-1.5 text-xs capitalize w-11/12"
        >
          {fontsList.map((font, indexdemerde) => (
            <option
              key={indexdemerde}
              value={font.name}
              selected={editingFont[index].custom.fontFamily == font.name}
              className={`font-${font.name}`}
            >
              {font.name}
            </option>
          ))}
        </select> */}
        <Selector
          defaultValue={editingFont[index].custom.fontFamily}
          onChange={changeFont}
          list={fonts}
          limit={50}
          key={index}
        />
        <div className="flex gap-8">
          <input
            type="number"
            onChange={changeFontSize}
            value={editingFont[index].custom.fontSize}
            className="border-0 border-b bg-transparent w-auto text-xs capitalize max-w-[40px] px-1 py-0 "
            min="1"
          />
          <div className="flex gap-4">
            <div className="flex items-center">
              <Icons name="letter-spacing" height="10" />
              <input
                type="number"
                onChange={changeletterSpacing}
                value={editingFont[index].custom.letterSpacing}
                className="border-0 border-b bg-transparent w-auto text-xs capitalize max-w-[40px] px-1 py-0 h-full"
              />
            </div>
            <button
              className={`h-[25px] w-[25px] flex items-center justify-center ${
                editingFont[index].custom.bold ? "bg-white" : "bg-transparent"
              } rounded-full `}
              onClick={changeBold}
            >
              <Icons name="bold" height="10" />
            </button>
            <button
              className={`h-[25px] w-[25px] flex items-center justify-center ${
                editingFont[index].custom.italic ? "bg-white" : "bg-transparent"
              } rounded-full `}
              onClick={changeItalic}
            >
              <Icons name="italic" height="10" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
