import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchBrand } from "api/brand/brand";

const initialState = {
  headers: { error: false, status: "pending" },
  brand: [],
};

export const getBrand = createAsyncThunk("brand/get", async () => {
  const brands = await fetchBrand();
  return brands;
});

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setBrand: (state, action) => {
      state.brand = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* Waiting for brand to be fetch */
    builder.addCase(getBrand.pending, (state) => {
      state.headers.status = "pending";
    });
    /* Brand get success */
    builder.addCase(getBrand.fulfilled, (state, action) => {
      state.headers.status = "success";
      state.brand = action.payload;
    });
    /* Brand get failed */
    builder.addCase(getBrand.rejected, (state) => {
      state.headers = { error: true, status: "rejected" };
      state.brand = [];
    });
  },
});

export const { setBrand } = brandSlice.actions;
export const brandSelector = (state) => state.brand;
export default brandSlice.reducer;

