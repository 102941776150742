import joinClasses from "helpers/joinClasses";

export function Card({ children, className = "", style }) {
  return (
    <div
      style={style}
      className={joinClasses(className, "bg-white rounded-xl shadow-light")}
    >
      {children}
    </div>
  );
}

export function SmoothCard({ children, padding, className = "", style }) {
  return (
    <div
      style={style}
      className={joinClasses(className, `${padding&&"p-7"} bg-white rounded-xl shadow-smooth`)}
    >
      {children}
    </div>
  );
}
