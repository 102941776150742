import { deleteDrink, updateDrink } from "api/drink/drink";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userSelector } from "store/models/userInfo";
import { Icons } from "components/atoms";
import { findFont } from "helpers/findDataCustom";

export function Drink({ drink, drinkId, volumes, custom }) {
  const { user } = useSelector(userSelector);

  const [prices, setPrices] = useState({});
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (drink) {
      setPrices({
        x15cl: drink.x15cl,
        x25cl: drink.x25cl,
        x375cl: drink.x375cl,
        x50cl: drink.x50cl,
        x75cl: drink.x75cl,
      });
    }
  }, [drink]);

  const changeDrink = async (data) => {
    await updateDrink({
      id: drinkId,
      token: user.token,
      payload: data,
    });
  };

  const removeDrink = async () => {
    await deleteDrink({ id: drinkId });
    setShow(false);
  };

  useEffect(() => {
    if (prices) {
      changeDrink(prices);
    }
  }, [prices]);
  return show ? (
    <div
      className="md:flex md:flex-row md:items-center  flex flex-col relative md:mb-0 mb-5"
      id={`drink-${drinkId}`}
    >
      <div className="flex items-center md:justify-start justify-between md:w-2/3 ">
        <input
          className={`h-1/3 my-1 border border-solid border-grey-disabled rounded bg-transparent py-2.5 pl-2 md:pl-4 md:w-full w-4/5 text-sm lg:text-base truncate ${
            custom && findFont(custom.police[3].custom.fontFamily)
          }
        ${custom && custom.police[3].custom.bold && "font-bold"}
        ${custom && custom.police[3].custom.italic && "italic"}`}
          placeholder="Nom du Vin"
          defaultValue={drink.name}
          name={`drink-${drinkId}`}
          onBlur={(e) => changeDrink({ name: e.target.value.trim() })}
          style={{
            fontSize: custom && custom.police[3].custom.fontSize,
            letterSpacing: custom && custom.police[3].custom.letterSpacing,
            color: custom && custom.color[2].custom.color,
          }}
        ></input>
        <Icons
          name="trash"
          height="18"
          className="ml-2 md:pr-0 pr-2"
          onClick={(e) => {
            e.preventDefault();
            removeDrink();
          }}
          style={{ color: custom && custom.color[1].custom.color }}
        />
      </div>
      <div
        className={`flex t md:justify-end md:align-middle md:items-center md:w-full w-full md:relative md:overflow-hidden ${
          custom && !custom.display[0].visible && "opacity-50"
        }`}
      >
        {custom && !custom.display[0].visible && (
          <div className="w-full h-full absolute cursor-not-allowed" />
        )}
        <div
          className="border-t-2 hidden md:block md:mx-2 border-black border-dotted w-full"
          style={{ borderColor: custom && custom.color[1].custom.color }}
        ></div>
        {volumes &&
          Object.keys(volumes).map(
            (row) =>
              volumes[row] && (
                <input
                  className={`my-1 text-center border border-solid border-grey-disabled rounded bg-transparent md:text-base text-xs md:py-2.5 pt-2 pb-1.5 lg:w-16 md:w-[60px] w-1/5 lg:mx-2 md:mx-1 mr-2.5 
                    ${custom && findFont(custom.police[4].custom.fontFamily)}
                    ${custom && custom.police[4].custom.bold ? "font-bold" : ""}
                    ${
                      custom && custom.police[4].custom.italic ? "italic" : ""
                    }`}
                  placeholder="Prix"
                  style={{
                    fontSize: `${custom && custom.police[4].custom.fontSize}px`,
                    letterSpacing: `${
                      custom && custom.police[4].custom.letterSpacing
                    }px`,
                    color: custom && custom.color[2].custom.color,
                  }}
                  defaultValue={prices[`x${row.replace(",", "")}`]}
                  onBlur={(e) =>
                    setPrices({
                      ...prices,
                      [`x${row.replace(",", "")}`]: e.target.value.trim(),
                      change: true,
                    })
                  }
                ></input>
              )
          )}
      </div>
    </div>
  ) : (
    <></>
  );
}
