import Button from "../Button";
import joinClasses from "helpers/joinClasses";
import { Icons } from "components/atoms";

export function SelectButton({
  children,
  ButtonType,
  isActive,
  className = "",
  onClick,
}) {
  if (isActive) {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(
          className,
          "bg-grey font-poppins font-light shadow-double mt-30 mb-7  pl-5 md:pl-9 pr-2 pt-1.5 pb-moins1  text-sm  flex justify-center items-center w-max gap-3 md:gap-10 text-black"
        )}
      >
        {children}
        <Icons
          name="valide"
          className="border-4 flex justify-center items-center border-solid border-green-500 border-opacity-100  py-1 px-0.5 text-green"
          height="15"
        />
      </Button>
    );
  }
  return (
    <Button
      onClick={onClick}
      className={joinClasses(
        className,
        "bg-white font-poppins shadow-light font-light py-3 px-9 w-max text-sm text-black mt-5 md:mt-30 mb-7"
      )}
    >
      {children}
    </Button>
  );
}
