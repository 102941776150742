import { BorderedButton, Card, DefaultButton, SubParagraph } from "components/atoms";
import { useState } from "react";

export default function PopUp({
  oldData,
  saveData = () => {},
  setUserForm,
  userForm,
  triggerOff,
}) {
  return (
    <div className="absolute bottom-0 top-0 w-full  bg-black-opacity flex items-center justify-center ">
      <Card className="mobileL:p-10 px-4 py-10 flex flex-col items-center mobileL:block w-full  mobileL:w-auto  ">
        <div className="w-full text-center mobileL:text-left">
          <div className="border-b border-black my-3">
            Modifier votre profil
          </div>
        </div>
        <div>
          <div className="mobileL:my-6 ">
            <SubParagraph>Prénom</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base "
              placeholder={oldData.firstname}
              onChange={(e) => {
                setUserForm({ ...userForm, firstname: e.target.value });
              }}
            />
          </div>
          <div className="my-6">
            <SubParagraph>Nom</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base"
              placeholder={oldData.lastname}
              onChange={(e) => {
                setUserForm({ ...userForm, lastname: e.target.value });
              }}
            />
          </div>
          <div className="my-6">
            <SubParagraph>E-mail</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base"
              placeholder={oldData.email}
              onChange={(e) => {
                setUserForm({ ...userForm, email: e.target.value });
              }}
            />
          </div>
          <div className="my-6">
            <SubParagraph>Numéro de téléphone</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base"
              placeholder={oldData.phone}
              onChange={(e) => {
                setUserForm({ ...userForm, phone: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex gap-1 mobileL:gap-3 mt-6 justify-end w-full">
            <BorderedButton
              borderType="small"
              onClick={() => {
                triggerOff(false);
              }}
            >
              Annuler
            </BorderedButton>
            <DefaultButton
              
              className="bg-yellow"
              onClick={(e) => {
                e.preventDefault();
                saveData();
                triggerOff(false);
              }}
            >
              Enregistrer
            </DefaultButton>
          </div>
        </div>
      </Card>
    </div>
  );
}
