import { Icons } from "components/atoms";
import style from "./Default.module.css";

export function DefaultModal({
  children,
  isOpen,
  setIsOpen = () => { },
  title,
  className,
}) {
  return (
    <>
      <div
        className={style.default_modal__backgound_trigger}
        style={isOpen ? { display: "block" } : { display: "none" }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <div className={ `fixed top-1/2 m-auto transform  -translate-y-1/2 max-h-[80vh] min-h-[20vh] w-[300px] bg-white border rounded-sm overflow-x-auto z-[9999]`} style={isOpen ? { display: "block" } : { display: "none" }} >
        <div className="flex justify-between mx-3 py-1 items-center m-auto" >
          {title ? <h1 >{title}</h1> : ""}
          <button
            className="flex items-center"
            onClick={() => {
              setIsOpen(false);
            }}
            
            
          >
            <Icons name="add" className="transform rotate-45" height={25}/>
          </button>
        </div>
        <div className="">{children}</div>
      </div>
    </>
  );
}
