import { Card, Title, YellowBloc } from "components/atoms"
import { DashboardNavbar } from "components";


export function Container({ children, title, className}) {
  return (
    <div className=" mt-20 " >
      <section className="px-[29px]  ">
      <Title TextSize="smaller" className="text-center mb-[22px]">{title}</Title>

          <Card
            className={`  m-auto max-w-[500px] pt-7
              ${className}`}
          >

            {children}
          </Card>
      </section>
    </div>
  );
}

