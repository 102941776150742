import Button from "./Button";
import joinClasses from "helpers/joinClasses";

export function BorderedButton({ children, borderType, className="", onClick, ...props }) {
    if (borderType === "yellow") {
      return (
        <Button
          onClick={onClick}
          className={joinClasses(className, "bg-transparent font-poppins font-medium border border-yellow block  py-[9px] px-[30px] text-xs md:text-sm  ")}
          {...props}
        >
          {children}
        </Button>
      );
    }
  
    return (
      <Button
        onClick={onClick}
        className={joinClasses(className, "bg-transparent font-poppins  py-[14px] px-10 text-xs md:text-sm  border border-grey-dark")}
        {...props}
      >
        {children}
      </Button>
    );
  }