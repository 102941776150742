export function EditDisplayRestau({
  index,
  editingDisplay,
  setEditingDisplay,
}) {
  const changeDisplay = (e) => {
    var tmp = [...editingDisplay];
    tmp[index] = {
      ...editingDisplay[index],
      visible: e.target.checked,
    };
    setEditingDisplay(tmp);
  };

  return (
    <div className="bg-yellow bg-opacity-25 flex justify-between items-center py-4 px-8 rounded-lg">
      <label className="flex gap-4 cursor-pointer items-center">
        <input
          defaultChecked={editingDisplay[index].visible}
          type="checkbox"
          onChange={changeDisplay}
          className="text-black bg-white"
        />

        <p className="uppercase">{editingDisplay[index].title}</p>
      </label>
    </div>
  );
}
