import joinClasses from "helpers/joinClasses";

export function RestoInput({ children, name, placeholder, className = "", onClick, onChange, type, inputType, borderColor, defaultValue }, props) {
    if (inputType === "register") {
        return (
            <div className="  sm:w-1/2 w-full text-sm m-auto flex h-[60px] ">
                <input type={type} {...props} name={name} placeholder={placeholder} onChange={onChange} defaultValue={defaultValue} className={joinClasses(className, "w-5/6 sm:w-4/5 m-auto font-poppins text-sm placeholder-grey-medium pb-1 pl-1 border-none focus:outline-none active-none")} style={{ border: "none", borderBottom: `1px solid ${props.borderColor || "#B3B3B3"}`, }} />
            </div>
            )
    }
    return (
        <div className="  mb-7 text-sm w-full m-auto max-w-[398px]  justify-content">
            <input type={type} {...props} name={name} placeholder={placeholder} onChange={onChange} defaultValue={defaultValue} className={joinClasses(className, "w-full font-poppins text-sm placeholder-grey-medium pb-1 pl-1 border-none focus:outline-none active-none")} style={{ border: "none", borderBottom: `1px solid ${props.borderColor || "#B3B3B3"}`, }} />
        </div>
    )

}