import joinClasses from "helpers/joinClasses";
import { useState } from "react";

export function Drag({ className = "", changePosition = () => {}, position }) {
  return (
    <input
      className={joinClasses(className, "border w-10 h-8 mx-2 p-0")}
      type="number"
      min="1"
      onBlur={(e) => changePosition(e.target.value)}
      defaultValue={position && position}
    ></input>
  );
}
