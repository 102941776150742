import FormuleCard from "./formuleCard";

export default function FormuleSectionView({ formules, custom }) {
  return (
    <div className="flex flex-col justify-center gap-2 w-full max-w-6xl m-auto mt-18">
      {formules
            .sort((a, b) => (a.positionId > b.positionId ? 1 : -1))
            .map((formule, index) => (
        <FormuleCard formule={formule} key={index} custom={custom} />
      ))}
    </div>
  );
}
