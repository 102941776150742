import Button from "../Button";
import joinClasses from "helpers/joinClasses";

export function RecurrenceButton({ children, onClick, className="", isActive }) {
    if (isActive) {
      return (
        <Button
          onClick={onClick}
          className={joinClasses(className,"bg-yellow  font-poppins font-medium text-base py-2 px-7 my-1.5")}
        >
          {children}
        </Button>
      );
    }
    return (
      <Button
        onClick={onClick}
        className={joinClasses(className," font-poppins font-medium text-base py-2 px-7 my-1.5")}
      >
        {children}
      </Button>
    );
  }