import { fetchSelectedBrand } from "api/brand/brand";
import { useState, useEffect } from "react";
import {
  CustomRestaurantCard,
  RestaurantEditInfoPratique,
  RestaurantPreviewInfoPratique,
  YellowTemplate,
} from "components";
import { MenuView } from "components";
import { useDispatch, useSelector } from "react-redux";
import { customSelector, fetchAllCustom } from "store/models/customInfo";
import { findDataCustom, findFont } from "helpers/findDataCustom";
import { EditColorRestau } from "components/atoms";
import { userSelector } from "store/models/userInfo";

import { Icons, ShowOptions } from "components/atoms";
import "../components/molecules/Navbars/animation.css";


import { useHistory, useLocation } from "react-router";
import {
  getPositionInPercent,
  getPositionWithPercent,
} from "helpers/scrollConvert";


export function PreviewMenu(props) {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isCustomOpen, setIsCustomOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [theBrandId, setTheBrandId] = useState(null);
  const [isOpen, setIsOpen] = useState("basic");

  const history = useHistory();
  const location = useLocation();

  const custom = useSelector(customSelector);
  const [customData, setCustomData] = useState(null);
  useEffect(() => {
    if (custom) {
      setCustomData(
        findDataCustom(custom.customs, Number(props.match.params.id))
      );
    }
  }, [custom]);


 

  const getBrand = async (id) => {
    const brand = await fetchSelectedBrand({ id });
    setSelectedBrand(brand);
    setCustomData(findDataCustom(brand.Custom, Number(props.match.params.id)));
    setTheBrandId(id);
  };

  useEffect(() => {
    if (selectedBrand && location && location.state && location.state.scroll) {
      window.scrollTo(0, getPositionWithPercent(location.state.scroll));
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      const brandId = props.match.params.id;
      getBrand(brandId);
    }
  }, [props.match.params.id]);

  useEffect(() => { }, [isCustomOpen]);
  useEffect(() => { }, [isInfoOpen]);

  function displayInfoMenu() {
    setIsInfoOpen(true);
  }

  function displayCustomMenu() {
    setIsCustomOpen(true);
  }


  function toOpen() {
    if (isOpen === "basic") {
      setIsOpen(true);

    } else {
      setIsOpen(!isOpen);
    }
  }

  const navItems = [
    {
      id: 1,
      icon: "pinceau",
      onClick: () => displayCustomMenu(),
      className: " rounded-md flex items-center justify-center"
    },
    {
      id: 2,
      icon: "add",
      onClick: () => history.push(`/pages/dashboard/menu/${props.match.params.id}`),
      className: " rounded-md flex items-center justify-center",
      iconClassName: "transform -rotate-45",
    },
    {
      id: 3,
      icon: "info",
      onClick: () => displayInfoMenu(),
      className: " rounded-md flex items-center justify-center",

    },
  ];

  function changeToEditPage() {
    history.push({
      pathname: `/pages/dashboard/menu/${props.match.params.id}`,
      state: {
        scroll: getPositionInPercent(),
        savedScroll:
          location.state && location.state.savedScroll
            ? location.state.savedScroll
            : null,
      },
    });
  }

  return (
    <YellowTemplate
      style={{
        backgroundColor: customData && customData.color[0].custom.color,
      }}
    >

      <ShowOptions className="flex justify-center items-center lg:hidden  z-50" onClick={() => toOpen()}  style={{
        backgroundColor: customData && customData.color[1].custom.color,
      }}>
        <Icons name="add" height={25} className={` ${isOpen === "basic" ? "" : isOpen === true ? "duration-300 rotate-45" : "duration-300 -rotate-0"}`}  style={{
        color: customData && customData.color[0].custom.color,
      }} />
      </ShowOptions>


      <div className="my-16 w-full">

        <div className={`fixed lg:hidden justify-center items-end flex  w-[40px]  right-5 bottom-5 z-30 ${isOpen === "basic" ? "   " : isOpen === true ? "containerHeightUp " : " containerHeightDown"}`}>

          <nav className={`flex flex-col   ${isOpen === "basic" ? "   " : isOpen === true ? "transition " : " reverseTransition"}`}>
            {navItems.map((item, index) => (
              <button
                key={index}
                className={ `rounded-md flex items-center justify-center ${isOpen === "basic" ? " " : isOpen === true ? "p-2.5 squareUp" : "p-0 squareDown "} ${item.icon === "add" ? "bg-yellow" : "bg-white"}` }
                onClick={item.onClick}
              >
                <Icons iconType={"transition"} name={item.icon} className={`${isOpen === "basic" ? "text-[0px]" : isOpen === true ? "fontSizeUp" : "fontSizeDown"} ${item.icon === "add" && item.iconClassName} `  } />

              </button>
            ))}
          </nav>
        </div>

        <nav className="hidden lg:fixed lg:right-0 lg:top-1/2 lg:flex lg:flex-col lg:gap-5 lg:transform lg:-translate-y-2/4 lg:z-20">
        <button
            className="shadow-special rounded-md flex items-center justify-center p-2.5 bg-white"
            onClick={() => displayCustomMenu()}
          >
            <Icons name="pinceau" height={30} />
          </button>
          <button

            className="shadow-special rounded-md flex items-center justify-center  p-2.5 bg-yellow"
            onClick={() =>
              history.push(`/pages/dashboard/menu/${props.match.params.id}`)
            }

          >
            <Icons name="add" className="transform rotate-45" height={30} />
          </button>
          <button
            className="shadow-special rounded-md flex items-center justify-center p-2.5 bg-white"
            onClick={() => displayInfoMenu()}
          >
            <Icons name="info" height={30} />
          </button>
        </nav>
        {selectedBrand ? (
          <div className="flex justify-center items-center w-full">
            <div className="w-full flex justify-center flex-col">
              <header className="flex justify-center m-auto mb-4 max-w-[135px]">
                {selectedBrand.avatar ? (
                  <img src={selectedBrand.avatar} alt="brand-avatar" />
                ) : (
                  ""
                )}
              </header>
              <div className="flex flex-col items-center mb-5">
                <p
                  className={`font-poppins text-2xl  mb-2 ${
                    selectedBrand.city
                      ? "border-b border-solid border-grey-dark"
                      : ""
                  }  w-full md:w-1/3 text-center pb-2
                ${
                  customData &&
                  findFont(
                    selectedBrand.avatar
                      ? customData && customData.police[2].custom.fontFamily
                      : customData && customData.police[0].custom.fontFamily
                  )
                }
                ${
                  selectedBrand.avatar
                    ? customData && customData.police[2].custom.bold
                      ? "font-bold"
                      : ""
                    : customData && customData.police[0].custom.bold
                    ? "font-bold"
                    : ""
                }
                ${
                  selectedBrand.avatar
                    ? customData && customData.police[2].custom.italic
                      ? "italic"
                      : ""
                    : customData && customData.police[0].custom.italic
                }
              `}
                  style={{
                    fontSize: `${
                      selectedBrand.avatar
                        ? customData && customData.police[2].custom.fontSize
                        : customData && customData.police[0].custom.fontSize
                    }px`,

                    letterSpacing: `${
                      selectedBrand.avatar
                        ? customData &&
                          customData.police[2].custom.letterSpacing
                        : customData &&
                          customData.police[0].custom.letterSpacing
                    }px`,
                    color: selectedBrand.avatar
                      ? customData && customData.color[2].custom.color
                      : customData && customData.color[1].custom.color,
                    borderColor: customData && customData.color[3].custom.color,
                  }}
                >
                  {selectedBrand.name}
                </p>
                <p
                  className={`font-poppins text-sm
                ${
                  customData &&
                  findFont(customData && customData.police[3].custom.fontFamily)
                }
                ${
                  customData && customData.police[3].custom.bold
                    ? "font-bold"
                    : ""
                }
                ${
                  customData && customData.police[3].custom.italic
                    ? "italic"
                    : ""
                }
              `}
                  style={{
                    fontSize: `${
                      customData && customData.police[3].custom.fontSize
                    }px`,
                    letterSpacing: `${
                      customData && customData.police[3].custom.letterSpacing
                    }px`,
                    color: customData && customData.color[2].custom.color,
                  }}
                >
                  {selectedBrand.city}
                </p>
              </div>
              <div className="w-full">
                {custom && (
                  <MenuView data={selectedBrand.Menu[0]} custom={customData} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>



      <RestaurantPreviewInfoPratique
        isInfoOpen={isInfoOpen}
        setIsInfoOpen={setIsInfoOpen}
        selectedBrand={selectedBrand}
        customData={customData}
      />

      <CustomRestaurantCard
        isOpen={isCustomOpen}
        setIsOpen={setIsCustomOpen}
        brandId={Number(props.match.params.id)}
      />
    </YellowTemplate>
  );
}
