import { Card, DefaultButton } from "components/atoms";

export function PopUp({ children }) {
  return (
    <div className="absolute h-screen w-screen bg-black-opacity top-0 left-0 flex justify-center items-center">
      <Card className="w-1/3 px-4">
        <div className="py-4">{children}</div>
      </Card>
    </div>
  );
}
