// COMPONENTS
import { BorderedButton, EmptyRestoCard, Icons, LogoPicker } from "components/atoms";
import { CardInput } from "components";

//
import { useHistory } from "react-router-dom"
import { useState, useEffect } from "react";
import { sendBrand } from "api/brand/brand";
import { sendBrandLogo } from "api/brand/brand";
import { getBrand } from "store/models/restaurantInfo";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { setBrand } from "store/models/restaurantInfo";
import { createMenu } from "api/menu/menu";
import { RestaurantCard } from "./RestaurantCard";

export function CreateRestaurantCard({ restaurantList}) {
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();

  const [logo, setLogo] = useState(null);
  const [name, setName] = useState(null);
  const [city, setCity] = useState(null);

  const [reset, setReset] = useState(false);
  const [brandInfo, setBrandInfo] = useState([]);

  const postBrand = async () => {
    setBrandInfo(await sendBrand(user.id, name, city));
    dispatch(setBrand(brandInfo));
    setName("");
    setCity("");
  };

  const postBrandLogo = async (brandId) => {
    await sendBrandLogo(brandId, user.id, logo);
    history.push(`/pages/dashboard/${brandInfo.brand.id}`)
  };

  async function createMenuForBrand(id) {
    await createMenu(id);
  }

  useEffect(() => {
    dispatch(getBrand());
  }, []);

  const history = useHistory()

  useEffect(() => {
    if (brandInfo && brandInfo.brand && brandInfo.brand.id) {
      postBrandLogo(brandInfo.brand.id, user.userId, logo, user.token);
      createMenuForBrand(brandInfo.brand.id);
      dispatch(getBrand());
      setReset(!reset);
      
    }
  }, [brandInfo]);

  return (
    <div>
      <div className="md:flex md:flex-wrap md:justify-start md:gap-3.5  md:items-center">
        {/* BRAND FORM RESPONSIVE --> */}
        <EmptyRestoCard className="md:w-226 md:hidden">
          <div className="m-auto h-auto flex flex-col items-center">
            <button
              className="bg-transparent font-xs text-sm font-light rounded-full py-4 px-10 "
              onClick={(e) => {
                e.preventDefault;
                postBrand(user.token);
              }}
            >
              <Icons
                name="add"
                className=" border border-solid border-black rounded-full p-2 mr-2.5"
              />
              Ajouter un restaurant
            </button>
          </div>
        </EmptyRestoCard>
        {/* END BRAND FORM RESPONSIVE --> */}

        {/* BRAND FORM DESKTOP --> */}
        <div className="h-full">
          <EmptyRestoCard className="flex flex-col justify-between items-center md:w-226 md:h-[320px] hidden md:flex md:mt-0 mt-2">
            <div className="flex flex-col items-center w-20">
              <LogoPicker mt={false} createH="20" wh={20} setLogo={setLogo} reset={reset} lib={"Ajouter mon logo (facultatif)"} className="!mb-5 !border-none  !pt-5 !max-w-[80px] !text-xs !mt-7 !max-h-[80px]"  />
            </div>
            <div>
                <div className="w-[140px]	">
                  <div className="flex justify-center">
                    <CardInput
                      onChange={setName}
                      value={name}
                      placeholder={"Nom du restaurant"}
                      className=" text-sm "
                    />
                  </div>
                </div>
                <div className="mt-[18px] w-[140px] ">
                  <div className="flex justify-center ">
                    <CardInput
                      onChange={setCity}
                      value={city}
                      placeholder={"Ville"}
                      className=" text-sm "
                    />
                  </div>
                </div>
              </div>
            <BorderedButton
                className="mb-5 "
                onClick={(e) => {
                  e.preventDefault();
                  postBrand();
                }}
              >
                Ajouter
              </BorderedButton>
          </EmptyRestoCard>
        </div>
        {/* END BRAND FORM DESKTOP --> */}


        {/* EXISTING BRAND LIST --> */}
        {restaurantList &&
          restaurantList.length > 0 &&
          restaurantList.map((row, index) => (
            <RestaurantCard
              key={index}
              brandId={row.id}
              logo={row.avatar}
              name={row.name}
              position={row.positionId}
              city={row.city}
              status={row.status}
            />
          ))}

        
      </div>
    </div>
  );
}
