import {
  Card,
  PriceButton,
  SubTitle,
  Paragraph,
  SelectButton,
} from "components/atoms";

export function FormuleCard({
  nbCustomableCards,
  quantity,
  price,
  annual,
  reduction,
  setSelectedFormule,
  selectedFormule,
  id,
}) {
  const [selectButton, setSelectButton] = useState(false);

  if (annual) {
    return (
      <Card className=" flex flex-col items-center shadow-simple text-center">
        <div>
          <p className="font-medium font-oswald text-xl mt-1.5 line-through">
            {reduction}
          </p>
        </div>
        <PriceButton className="mt-2  ">{price}</PriceButton>
        <SubTitle TextSize="smaller" className=" mt-2 mx-10">
          {quantity}
        </SubTitle>
        <SubTitle TextSize="smaller" className="-mt-1 mx-8">
          {nbCustomableCards}
        </SubTitle>
        <Paragraph TextSize="smaller" className="mt-4">
          1er mois offert
        </Paragraph>
        <SelectButton
          isActive={selectedFormule === id}
          onClick={() => {
            setSelectedFormule(id);
          }}
        >
          Sélectionner
        </SelectButton>
      </Card>
    );
  }
  return (
    <Card className=" flex flex-col items-center shadow-simple text-center">
      <PriceButton className="mt-5 ">{price}</PriceButton>
      <SubTitle TextSize="smaller" className="mt-30 mx-10">
        {quantity}
      </SubTitle>
      <SubTitle TextSize="smaller" className="-mt-1 mx-8">
        {nbCustomableCards}
      </SubTitle>
      <Paragraph TextSize="smaller" className="mt-4">
        1er mois offert
      </Paragraph>
      <SelectButton
        isActive={selectedFormule === id}
        onClick={() => {
          setSelectedFormule(id);
        }}
      >
        Sélectionner
      </SelectButton>
    </Card>
  );
}
