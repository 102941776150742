// COMPONENTS
import {
  SelectButton,
  DefaultButton,
  RestoInput,
  Icons,
} from "components/atoms";
import { Container, YellowTemplate } from "components";

import { useEffect, useState } from "react";
import { fetchUser, userSelector } from "store/models/userInfo";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export default function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userForm, setUserForm] = useState({
    email: "",
    password: "",
  });

  const sendLogin = async () => {
    const userLogged = await dispatch(fetchUser(userForm));
    if (
      userLogged.payload.error &&
      userLogged.payload.error.message === "Not registered"
    ) {
      history.push({
        pathname: "/auth/register",
        state: {
          step: 4,
        },
      });
    }
  };

  const _handleRegisterClick = (type) => {
    window.open(`http://localhost:4000/auth/${type}`, "_self");
  };

  return (
    <>
      <YellowTemplate>
        <Container
          title={"Connexion au dashboard"}
          className={"pb-[25px]  !max-w-[600px]"}
        >
          <form
            className="flex flex-col items-center mt-7"
            onSubmit={(e) => {
              e.preventDefault();
              sendLogin();
            }}
          >
            <RestoInput
              name="email"
              placeholder="Adresse e-mail"
              type="email"
              required
              onChange={(e) =>
                setUserForm({ ...userForm, email: e.target.value })
              }
            />
            <RestoInput
              name="password"
              placeholder="Mot de passe"
              type="password"
              required
              onChange={(e) =>
                setUserForm({ ...userForm, password: e.target.value })
              }
            />
            <DefaultButton type="submit">Connexion</DefaultButton>
            <div className="max-w-[398px] mt-[50px] w-full flex flex-col items-center">
              <div className="flex w-full  items-center ">
                <div className="border-grey-disabled border-b-[1px] w-full"></div>
                <p className="min-w-max text-sm mx-[13.5px]">
                  Ou identifiez-vous avec
                </p>
                <div className="border-grey-disabled border-b-[1px] w-full"></div>
              </div>
              <div className="w-full max-w-[230px] ">
                <button
                  className="flex  w-full items-center  mt-[20px] border border-black"
                  onClick={() => _handleRegisterClick("google")}
                >
                  <div className="flex justify-center w-1/5 ">
                    <Icons height={20} name="google1" />
                    <Icons height={20} name="google2" />
                    <Icons height={20} name="google3" />
                    <Icons height={20} name="google4" />
                  </div>
                  <p className="uppercase text-smallest py-[14px]">
                    Continuez avec Google
                  </p>
                </button>

                <button
                  className="flex  w-full items-center  mt-[20px] border border-black"
                  onClick={() => _handleRegisterClick("facebook")}
                >
                  <div className="flex justify-center w-1/5 ">
                    <Icons height={20} name="facebookk1" />
                    <Icons height={20} name="facebookk2" />
                  </div>
                  <p className="uppercase text-smallest py-[14px]">
                    Continuez avec Facebook
                  </p>
                </button>
              </div>
            </div>
            <div className=" text-center sm:mt-10 ">
              <p className="sm:mt-0 mt-10 text-sm">
                Vous n'avez pas de compte ?
              </p>
              <SelectButton
                onClick={() => {
                  history.push("/auth/register");
                }}
                className={`!font-medium !mt-2 !mb-0`}
              >
                S'inscrire
              </SelectButton>
            </div>
          </form>
        </Container>
      </YellowTemplate>
    </>
  );
}
