import Button from "./Button";
import joinClasses from "helpers/joinClasses";
import { Icons } from "components/atoms";

export function AddCategorie({children,ButtonType,className="",onClick,edit,isGlobal,isSelected}) {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(className, " md:w-[180px] border border-solid border-black flex justify-between items-center font-poppins text-sm pl-5 pr-[7px] md:pr-2    md:h-12   py-[7px] gap-2  ", isSelected ? "bg-grey" : "bg-white") }
      >
        <p className="truncate md:w-full text-left">{children}</p>
        {edit ? (
          !isGlobal &&
          <Icons
            name="trash"
            height="14"
            className="text-white bg-red-600 p-2 rounded-full"
          />
        ) : isSelected ? (
          <Icons
            name="valide"
            height="14"
            className="text-yellow bg-grey py-0.5 md:py-1 md:px-0.5  border-2 md:border-4 rounded-full  border-yellow "
          />
        ) : (
          <Icons
            name="add"
            height="14"
            className="text-white bg-yellow p-1 md:p-2  rounded-full"
          />
        )}
      </Button>
    );
  }
  