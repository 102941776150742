import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function fetchCustom(userid) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/custom/all/${userid}`,
      getFormatedToken()
    );

    const { data } = response.data;

    return data.customs;
  } catch (error) {
    
  }
}

export async function sendEditCustom(payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/custom/`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data.custom;
  } catch (error) {
    
  }
}

export async function sendCreateCustom(payload) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/custom/`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data.custom;
  } catch (error) {
    
  }
}
