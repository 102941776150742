import joinClasses from "helpers/joinClasses";

export function Icons({ height = 10, name, className = "", onClick, style, iconType,iconHeight }) {
  if(iconType === "transition"){
    return (
      <i
      className={joinClasses(className, `cursor-pointer icon-${name} `)}
      onClick={onClick}
      />
      );
  }else{

    return (
      <i
      className={joinClasses(className, `cursor-pointer icon-${name} `)}
      style={{ fontSize: `${height}px`, ...style }}
      onClick={onClick}
      />
      );
  }
}
