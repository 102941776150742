import { useState } from "react";
import { Icons, Paragraph } from "components/atoms";
import { CropImage } from "components";
import { useEffect } from "react";
import joinClasses from "helpers/joinClasses";

export function LogoPicker({
  defaultLogo,
  onChange,
  mt = true,
  mb = true,
  wh = 40,
  he = "h-auto",
  visual = "",
  setLogo = () => {},
  reset,
  lib = "Ajouter un logo",
  ratio = 4 / 4,
  className = "",
  createH = "auto",
  libColor,
  onClick,
}) {
  const [avatar, setAvatar] = useState(null);
  const [avatarBlob, setAvatarBlob] = useState(null);

  useEffect(() => {
    setAvatarBlob(null);
  }, [reset]);

  useEffect(() => {
    if (avatarBlob && defaultLogo) {
      setAvatarBlob(null);
    }
  }, [defaultLogo]);
  const renderAvatarForm = () => {
    if (avatarBlob) {
      return avatarBlob;
    } else if (defaultLogo) {
      return `${defaultLogo}`;
    } else {
      return "%icon%";
    }
  };

  return (
    <div
      className={joinClasses(
        className,
        `${
          !renderAvatarForm() && "border border-solid rounded border-grey-light"
        } ${mt && `mt-10`} ${mb && `mb-6`} flex justify-center ${
          visual == "smoothCard" ? "w-full md:h-80 h-32" : "w-full h-32 m-auto"
        } relative`
      )}
    >
      <input
        type="file"
        className="absolute w-full h-full opacity-0 cursor-pointer"
        onChange={(e) => {
          setAvatar(e.target.files[0]);
        }}
        name="file"
      />
      {avatar && (
        <CropImage
          sendImage={() => {
            setLogo(avatar);
            // setAvatarBlob(null);
          }}
          setImage={setAvatar}
          setImageBlob={setAvatarBlob}
          src={avatar}
          ratio={ratio}
        />
      )}
      {renderAvatarForm() === "%icon%" ? (
        <div className="flex flex-col items-center justify-center">
          <div className="bg-yellow-lightest rounded-full h-16 w-16 md:h-20 md:w-20 mx-10 my-3  flex items-center justify-center ">
            <Icons name="add-picture" height="28" />
          </div>
          <p
            className={`font-poppins text-sm font-light text-center`}
            style={{ color: libColor }}
          >
            {lib}
          </p>
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `url('${renderAvatarForm()}')`,
            }}
            className={`flex flex-col items-center justify-center w-full bg-no-repeat bg-cover h-${createH} `}
          />
          <div
            onClick={onClick}
            className="absolute flex items-center justify-center top-0 right-0 h-5 w-5 rounded transform -translate-y-1/4 translate-x-1/4 shadow-md"
            style={{ backgroundColor: "#FE4A49" }}
          >
            <Icons
              name="add"
              className="text-white transform rotate-45"
              height="13"
            />
          </div>
        </>
      )}
    </div>
  );
}
