import getFormatedToken from "api/helpers/getFormatedToken";
import axios from "axios";

export async function DeleteAvatar(avatar, userId, type){
    try{
        await axios.post(
            `${process.env.REACT_APP_API_URL}/users/delete/avatar`, {avatar, userId, type},
            getFormatedToken()
            );       
    } catch(error){
    }
}