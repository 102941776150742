import joinClasses from "helpers/joinClasses";

export function CardInput({ onChange = () => {}, value, placeholder, className = "",  },props) {
  return (
    <input
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      {...props}
      className={joinClasses(className,`font-poppins text-xs md:text-sm placeholder:text-grey-dark text-black  bg-transparent border-b  border-grey-disabled outline-none  max-w-[360px] pl-1 pb-1`)}
      style={{border: "none", borderBottom: `1px solid ${props.borderColor ||  "#B3B3B3"}`,}}
    />
  );
}
