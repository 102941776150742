import joinClasses from "helpers/joinClasses";

export function BigCard({ children, className = "", style }) {
  return (
    <div
      style={style}
      className={joinClasses(className, "md:bg-white rounded-xl shadow-2xl")}
    >
      {children}
    </div>
  );
}
