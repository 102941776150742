import joinClasses from "helpers/joinClasses";

export function Title({ children, className = "", TextSize, ...props }) {
  if (TextSize === "smaller") {
    return (
      <h1
        className={joinClasses(
          className,
          "font-poppins  font-medium text-xl"
        )}
        {...props}
      >
        {children}
      </h1>
    );
  }
  return (
    <h1
      className={joinClasses(
        className,
        "font-poppins  font-semibold text-[40px]"
      )}
      {...props}
    >
      {children}
    </h1>
  );
}
