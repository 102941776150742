// COMPONENTS
import { Icons, BorderedButton } from "components/atoms";

//
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";

export function DashboardNavbar({ onClick }) {
  const { user } = useSelector(userSelector);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  return (
    <>
      <nav className="bg-white w-full">
        <div className=" flex justify-between items-center ml-10 py-7  sm:py-0     sm:flex sm:justify-between sm:items-center sm:ml-14 sm:mr-10">
          <Link to={"/pages/dashboard"}>
            <div className="flex h-full">
              <img
                src="/assets/brand/logo.svg"
                alt="logo-menu"
                className="z-20"
              />
            </div>
          </Link>
          <div
            onClick={() => setIsMobileOpen(!isMobileOpen)}
            className={`z-20 cursor-pointer sm:hidden absolute right-8  ${
              isMobileOpen ? "top-9" : "top-8"
            }`}
          >
            <span
              className={`block bg-black h-0.5 w-5 my-1 transition-all transform ${
                isMobileOpen && "absolute rotate-45 translate-y-0.5 bg-black "
              }`}
            ></span>
            <span
              className={`block bg-black h-0.5 w-5 my-1 transition-all ${
                isMobileOpen && "opacity-0"
              }`}
            ></span>
            <span
              className={`block h-0.5 w-5 bg-black my-1 transition-all transform ${
                isMobileOpen && "absolute -rotate-45 -translate-y-2 bg-black "
              }`}
            ></span>
          </div>

          <div className="sm:flex sm:items-center sm:gap-4 md:gap-8 sm:my-3 hidden">
          <a className="font-poppins font-medium text-smallest md:text-sm underline" href="mailto:nathan@agencerjs.com">Signaler un bug</a>

            <Link to={"/pages/dashboard"} className="flex">
              <div>
                <BorderedButton className="!py-2.5 !px-7">Mes restaurants</BorderedButton>
              </div>
            </Link>
            <Link to="/pages/account">
              <a className="flex md:flex-col md:items-center gap-2 md:gap-0">
                <Icons name="user" height="21" />
                <p className="font-poppins text-xs font-light text-center">
                  {user.firstname} {user.lastname}
                </p>
              </a>
            </Link>
          </div>
        </div>
      </nav>
      <nav
        className={`absolute sm:hidden bg-white z-10 top-0 h-1/2 w-screen transform transition-all ${
          isMobileOpen ? " translate-y-0" : "-translate-y-full"
        }`}
      >
        <ul className="flex h-full w-full flex-col justify-center items-center gap-10">
          <li>
          <a className="font-poppins font-medium text-smallest md:text-sm underline" href="mailto:nathan@agencerjs.com">Signaler un bug</a>

          </li>
          <li>

            <Link to={"/pages/dashboard"} className="flex">
              <div>
              <BorderedButton className="!py-2.5 !px-7">Mes restaurants</BorderedButton>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/pages/account">
              <a className="flex md:flex-col md:items-center gap-2 md:gap-0">
                <Icons name="user" height="21" />
                <p className="font-poppins text-xs font-light text-center mt-1">
                  {user.firstname} {user.lastname}
                </p>
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
