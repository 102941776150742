const products = [
    {
        quantity: '1',
        name: ' carte personnalisable',
        stripe_id: 'price_1JaHVnGaJHiI9Jq6CZ87IqKz',
        price: "10€",
        frequency: 'monthly'
    },
    {
        quantity: '2-5',
        name: 'cartes personnalisables',
        stripe_id: 'price_1JaHVEGaJHiI9Jq6v9UiS0hG',
        price: "100€",
        frequency: 'monthly'
    },
    {
        quantity: '1',
        name: 'carte personnalisable',
        stripe_id: 'price_1JcSmfGaJHiI9Jq6GtbNilnH',
        price: "35€",
        frequency: 'annualy',
        realPrice: "420€"
    },
    {
        quantity: '2-5',
        name: 'cartes personnalisables',
        stripe_id: 'price_1JcSmfGaJHiI9Jq6wbMzjstl',
        price: "100€",
        frequency: 'annualy',
        realPrice: "600€"
    }, 
]

export default products;