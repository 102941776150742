import { Icons } from "components/atoms";
import { findFont } from "helpers/findDataCustom";
import joinClasses from "helpers/joinClasses";
import { useRef, useState, useEffect } from "react";

export const Selector = ({
  list = [],
  defaultValue = "",
  onChange = () => {},
  limit = 0,
}) => {
  let selectorRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(
    list.find((item) => item.family === defaultValue)
  );
  const [isOpen, setIsOpen] = useState(false);

  const [generatedId, setGeneratedId] = useState(
    Math.random().toString(36).substr(2, 9)
  );

  const triggerSelector = (e) => {
    if (!isOpen) return setIsOpen(true);
    if (e.target.value === "") return setIsOpen(false);
    setSelectedValue(list.find((item) => item.family === e.target.value));
    onChange({ ...e, target: { ...e.target, value: e.target.value } });
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedValue(list.find((item) => item.family === defaultValue));
  }, [list]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.id.includes(`selector-${generatedId}`) && isOpen) {
        setIsOpen(false);
      }
    });
  }, [isOpen]);

  return (
    <div
      ref={selectorRef}
      onClick={triggerSelector}
      className="w-11/12 relative "
      id={`selector-${generatedId}`}
    >
      <button
        id={`seleted-selector-${generatedId}`}
        className="border-0 rounded-full py-1.5 px-2.5 text-xs capitalize w-full bg-white text-left flex justify-between items-center"
      >
        {selectedValue ? selectedValue.family : "Choisissez une police"}
        <Icons
          name="arrow-down"
          className={joinClasses(
            "transform ",
            isOpen ? "rotate-180" : "rotate-0"
          )}
          height="12"
        />
      </button>
      <ul
        id={`list-selector-${generatedId}`}
        className={joinClasses(
          isOpen ? "max-h-[200px]" : "max-h-[0px]",
          "absolute z-10 rounded-sm shadow-sm overflow-y-auto w-full overflow-x-auto "
        )}
        style={{ backgroundColor: "#f7f7f7" }}
      >
        {list.length &&
          list.map((item, index) => {
            return (
              <li key={index} className="w-full">
                <button
                  className={joinClasses(
                    "w-full text-left px-2.5 text-sm",
                    findFont(item.family)
                  )}
                  style={{
                    backgroundColor:
                      selectedValue && selectedValue.family === item.family
                        ? "#f3f0f0"
                        : "#f7f7f7",
                  }}
                  value={item.family}
                  id={`item-selector`}
                >
                  {item.family}
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
