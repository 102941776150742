import { updateDrinkVolume } from "api/drinkVolumes/drinkVolumes";
import { useState, useEffect } from "react";

export function DrinkVolumesEditor({volume,custom, callback = () => { }}) {

  const [volumes, setVolumes] = useState(null);

  const changeDrinkVolume = async (data) => {
    await updateDrinkVolume(volume.id, data);
  };

  useEffect(() => {
    if (volume) {
      setVolumes({
        "15cl": volume.x15cl,
        "25cl": volume.x25cl,
        "37,5cl": volume.x375cl,
        "50cl": volume.x50cl,
        "75cl": volume.x75cl,
      });
    }
  }, [volume]);

  useEffect(() => {
    if (volumes) {
      callback(volumes);
      changeDrinkVolume({
        x15cl: volumes["15cl"],
        x25cl: volumes["25cl"],
        x375cl: volumes["37,5cl"],
        x50cl: volumes["50cl"],
        x75cl: volumes["75cl"],
      });
    }
  }, [volumes]);

  return (
    <div className="flex pl-0 md:pl-2  items-center">
    <div className="flex md:align-middle md:items-center  md:justify-start sm:gap-6  ">
      {[
        { cl: "15cl", width: "3", height: "7" },
        { cl: "25cl", width: "4", height: "8" },
        { cl: "37,5cl", width: "3", height: "12" },
        { cl: "50cl", width: "10", height: "10" },
        { cl: "75cl", width: "4", height: "16" },
      ].map((cl) => (
        <div className="md:flex md:align-middle md:items-center md:flex-row flex flex-col-reverse items-center  md:mx-0 ">
          <input
            className="form-checkbox text-xl text-black"
            type="checkbox"
            id={`${cl.cl}${volume.id}`}
            checked={volumes && volumes[cl.cl]}
            onClick={(e) =>
              setVolumes({ ...volumes, [cl.cl]: e.target.checked })
            }
            style={{color: custom && custom.color[1].custom.color }}
          />
          <label
            className="text-xs  md:text-base md:flex md:align-middle md:items-center md:flex-row flex flex-col-reverse items-center"
            for={`${cl.cl}${volume.id}`}
          >
            <p className="mx-2.5" style={{color: custom && custom.color[1].custom.color}}>{cl.cl}</p>

          </label>
        </div>
      ))}
    </div>

  </div>
  );
}


