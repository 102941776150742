import axios from "axios";
import getFormatedToken from "api/helpers/getFormatedToken";

export async function updateUser(id, payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/users/${id}`,
      payload,
      getFormatedToken()
    );
    const { data } = response.data;
    return data.user;
  } catch (error) {
  }
}
