import { YellowTemplate } from "components";
import { Card, DefaultButton, Paragraph } from "components/atoms";
import { Container } from "../Containers/Container";

export function EarlyVersionCard() {
  return ( 
        <YellowTemplate>
            <Container title={"Version de lancement"} className="flex flex-col items-center gap-6">
                    <Paragraph TextSize="smaller" className=" text-center   ">
                        C’est gratuit pendant le lancement, <br/>
                        Profitez de la plateforme gratuitement <br/>
                        jusqu’au 15 avril 2022.
                    </Paragraph>
                    <Paragraph TextSize="smaller" className=" text-center">Tous les inscrits avant cette date profiteront <br/> 
d’un tarif préférentiel lors du lancement officiel.
</Paragraph>
                    
                    <DefaultButton className="mb-6">
                        Aller
                    </DefaultButton>
                </Container>
        </YellowTemplate>
  )
    
}