// COMPONENTS
import {
  Card,
  RestoInput,
  LogoPicker,
  DefaultButton,
  BorderedButton,
} from "components/atoms";

// MON VIé

import { useState } from "react";
import { useEffect } from "react";
import { fetchSelectedBrand, sendBrand, updateBrand } from "api/brand/brand";
import { sendBrandLogo } from "api/brand/brand";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { Link } from "react-router-dom";
import { DeleteAvatar } from "api/images/images";

export function DashboardCard({ selectedBrandId }) {
  const [restaurantInfo, setRestaurantInfo] = useState({
    address1: "",
    name: "",
    phone: "",
    zip: "",
    city: "",
    complement: "",
  });
  const [logo, setLogo] = useState(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(userSelector);
  const token = user.token;

  async function getOneBrandId(selectedBrandId) {
    setRestaurantInfo(await fetchSelectedBrand({ id: selectedBrandId }));
  }

  async function sendUpdatedBrand(id, payload) {
    await sendBrandLogo(id, user.id, logo);
    setRestaurantInfo(await updateBrand({ id, payload }));
  }

  useEffect(() => {
    getOneBrandId(selectedBrandId);
  }, []);

 async function deleteAvatar(logo, userId, type){
    setLoading(true);
    await DeleteAvatar(logo, userId, type)
    getOneBrandId(selectedBrandId);
    setLoading(false);

  }

  async function uploadAvatar(id, userId, logo){
    setLoading(true);
    await sendBrandLogo(id, userId, logo);
    setRestaurantInfo({ ...restaurantInfo})
    setRestaurantInfo(await fetchSelectedBrand({ id: selectedBrandId }))
    setLoading(false);
  }

  return (
    <div className="mt-4 flex justify-center px-3 pb-5 md:p-0">
      <Card className="w-full md:w-900">
        <LogoPicker
          defaultLogo={restaurantInfo.avatar}
          setLogo={(e) => {setLogo(e), uploadAvatar(selectedBrandId, user.id, e)}}
          wh={48}
          className="!max-w-[120px]"
          onClick={() => deleteAvatar(restaurantInfo.avatar, user.id, "brand")}
        />
        <div className="flex justify-content text-2xl   md:px-0 ">
          <input
            required
            onChange={(e) => {
              setRestaurantInfo({ ...restaurantInfo, name: e.target.value });
            }}
            placeholder={"Nom du restaurant"}
            defaultValue={restaurantInfo.name}
            className="text-center md:max-w-max md:px-0 m-auto relative font-poppins text-2xl pb-2  placeholder-grey-medium border-none focus:outline-none active-none"
            style={{ border: "none", borderBottom: `1px solid`, borderColor: "#B3B3B3", }} />
        </div>
        <div className="grid grid-cols-1 md:justify-center md:flex   ">
          <div className=" mt-12 mx-5 md:ml-0 md:mx-0 ">
            
              <RestoInput
                required
                onChange={(e) => {
                  setRestaurantInfo({
                    ...restaurantInfo,
                    address1: e.target.value,
                  });
                }}
                placeholder={"N° + Rue *"}
                
                defaultValue={restaurantInfo.address1}
              />
            
            
              <RestoInput
                onChange={(e) => {
                  setRestaurantInfo({
                    ...restaurantInfo,
                    address2: e.target.value,
                  });
                }}
                placeholder={"Complément"}
                defaultValue={restaurantInfo.address2}
              />
            
            <div className=" md:flex md:flex-row flex flex-col">
              <RestoInput
                className="w-1/2 md:w-3/4  md:mb-0"
                required
                onChange={(e) => {
                  setRestaurantInfo({ ...restaurantInfo, zip: e.target.value });
                }}
                placeholder={"Code postal *"}
                defaultValue={restaurantInfo.zip}
              />
              <RestoInput
                className="pr-9"
                required
                onChange={(e) => {
                  setRestaurantInfo({
                    ...restaurantInfo,
                    city: e.target.value,
                  });
                }}
                placeholder={"Ville *"}
                defaultValue={restaurantInfo.city}
              />
            </div>
            <div className="mb-12">
              <RestoInput
                onChange={(e) => {
                  setRestaurantInfo({
                    ...restaurantInfo,
                    phone: e.target.value,
                  });
                }}
                placeholder={"Téléphone"}
                defaultValue={restaurantInfo.phone}
              />
            </div>
            <div className="flex flex-col items-center mb-10 md:mb-7  md:flex md:flex-row ">
              <Link to="/pages/dashboard">
                <BorderedButton
                  onClick={() => {
                    sendUpdatedBrand(restaurantInfo.id, restaurantInfo);
                  }}
                  className="mb-4 md:mb-0 md:mr-4"
                >
                  Valider
                </BorderedButton>
              </Link>
              <Link to={`/pages/dashboard/menu/${selectedBrandId}`}>
                <DefaultButton
                  onClick={() => {
                    sendUpdatedBrand(restaurantInfo.id, restaurantInfo);
                  }}
                >
                  Commencer mon menu
                </DefaultButton>
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
