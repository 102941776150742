import { Card, DefaultButton, Icons, LogoPicker } from "components/atoms";
import { useEffect, useState } from "react";
import { TimePatron } from "../Patrons/TimePatron";
import "./switch.css";

import { fetchSelectedBrand, updateBrand } from "api/brand/brand";
import { Link } from "react-router-dom";

export function RestaurantPreviewInfoPratique({
    customData,
    isInfoOpen = false,
    setIsInfoOpen = () => { },
    selectedBrand,
    currentSection = false,
    setCurrentSection = () => { },
}) {
    // const { user } = useSelector(userSelector);
    // const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [schedule, setSchedule] = useState({
        Lundi: null,
        Mardi: null,
        Mercredi: null,
        Jeudi: null,
        Vendredi: null,
        Samedi: null,
        Dimanche: null,
    });
    const [links, setLinks] = useState([""]);

    useEffect(() => {
        if (selectedBrand) {
            setDisabled(selectedBrand.isSchedule);
            setSchedule({
                Lundi: selectedBrand.schedule && selectedBrand.schedule.Lundi,
                Mardi: selectedBrand.schedule && selectedBrand.schedule.Mardi,
                Mercredi: selectedBrand.schedule && selectedBrand.schedule.Mercredi,
                Jeudi: selectedBrand.schedule && selectedBrand.schedule.Jeudi,
                Vendredi: selectedBrand.schedule && selectedBrand.schedule.Vendredi,
                Samedi: selectedBrand.schedule && selectedBrand.schedule.Samedi,
                Dimanche: selectedBrand.schedule && selectedBrand.schedule.Dimanche,
            });
            setLinks(JSON.parse(selectedBrand.links))
        }
    }, [selectedBrand]);

    

    return (
        <Card
            className={`fixed right-0 top-1/2 transform -translate-y-2/4 font-poppins  ${!isInfoOpen && "hidden"
                } shadow-special bg-white z-50 sm:w-[400px] pb-2 transition-all  w-full`}
            style={{
                backgroundColor: customData && customData.color[0].custom.color,
            }}
        >
            {/* <LogoPicker className={` w-full !my-0 relative h-[240px]`}/> */}
            <div className="flex items-center justify-between w-full top-0 left-0 " style={{ backgroundColor: customData && customData.color[0].custom.color }}>
                <div className="flex gap-4 ml-4">
                    <Icons name="info" className=" right-0 top-0 pointer-events-none" style={{
                        color: customData && customData.color[2].custom.color,
                    }} height={25} />
                    <h3 className="flex items-center  font-bold text-sm" style={{
                        color: customData && customData.color[2].custom.color,
                    }}>
                        Infos pratiques
                    </h3>
                </div>
                <button
                    className="bg-yellow"
                    style={{ width: "52px", height: "52px",  backgroundColor: customData && customData.color[1].custom.color }}
                    onClick={() => {
                        setIsInfoOpen(false), setCurrentSection(0);
                    }}
                    
                >
                    <Icons
                        name="add"
                        height={20}
                        className="transform  rotate-45 block"
                        style={{color: customData && customData.color[0].custom.color}}
                    />
                </button>
            </div>
            <div className="overflow-y-auto overflow-x-hidden w-full "style={{backgroundColor: customData && customData.color[0].custom.color}}>
        <div className=" w-11/12 m-auto flex flex-col ">
        <div className="h-[126px] relative  w-full mt-3 flex flex-col pl-[14px]  justify-center m-auto rounded" style={{color: customData && customData.color[2].custom.color}}>
            <p className="text-2xl font-bold mb-2">
              {selectedBrand && selectedBrand.name}
            </p>
            <p>
              {selectedBrand && selectedBrand.address1}
              {selectedBrand && selectedBrand.address2 && (
                <p>{selectedBrand.address2}</p>
              )}
            </p>
            <p>
              {selectedBrand && selectedBrand.zip + " " + selectedBrand.city}
            </p>
          </div>
          <div className="w-11/12  m-auto mt-2.5 flex items-center justify-between">
                  <a href={`tel:${selectedBrand && selectedBrand.phone}`}>
                    <DefaultButton style={{backgroundColor: customData && customData.color[1].custom.color}} className={"flex items-center justify-between gap-5 !py-3 !pl-[15px] !pr-[41px] w-[256px]"}>
                            <Icons name="phone1" height={30} className="pointer-events-none" style={{color: customData && customData.color[0].custom.color}}/>
                            <p className=" text-xl font-bold " style={{color: customData && customData.color[0].custom.color}}>
                                {selectedBrand && selectedBrand.phone}
                            </p> 
                    </DefaultButton>
                  </a>
            
          </div>
          <div className="mt-[22px] pl-[14px] flex flex-col ">
            <div className="flex items-center gap-2 mb-4">
              <h4 className="font-bold text-xs " style={{color: customData && customData.color[1].custom.color}}>HORAIRES :</h4>
            </div>
            {
              selectedBrand && selectedBrand.isSchedule ?
              Object.keys(schedule).map((day, index) => (
                <TimePatron
                customData={customData}
                data={schedule[day]}
                disabled={!disabled}
                key={index}
                isPreview={true}
              >
                {day}
              </TimePatron>
            )):
            <div style={{color: customData && customData.color[2].custom.color}}>Non renseigné</div>
            }
            <div></div>
          </div>
          <div className="pl-[14px]">
            <div className="flex items-center  gap-2 mt-4 mb-2">
              <p  className="font-bold text-xs" style={{color: customData && customData.color[1].custom.color}}>COMMANDER : </p>
            </div>
            <div className="flex flex-col gap-1">
            {links && links.map((value, index) => (
                <p className="cursor-pointer text-sm">{value}</p>
            ))}
            </div>
          </div>
        </div>
      </div>
        </Card>
    );
}
