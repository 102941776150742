import { useState, useEffect } from "react";

// * Composants * //
import { YellowTemplate, DashboardTriNavbar, AddCategorieFormule, StepFooter } from "components";
import { Icons, ShowOptions } from "components/atoms";
// * REDUX * //
import { useSelector } from "react-redux";

// * REQUETES * //
import { userSelector } from "store/models/userInfo";
import { fetchSelectedBrand } from "api/brand/brand";
import { DashboardOptions } from "components/molecules/Navbars/DashboardOptions";
import { customSelector } from "store/models/customInfo";
import { findDataCustom } from "helpers/findDataCustom";
import { useLocation } from "react-router";
import { getPositionWithPercent } from "helpers/scrollConvert";

export default function OneRestaurantPageMenu(props) {
  const { user } = useSelector(userSelector);
  const location = useLocation();
  const brandId = props.match.params.id;
  const [brand, setBrand] = useState(null);
  const [isOpen, setIsOpen] = useState("basic");
  async function getOneBrand() {
    setBrand(await fetchSelectedBrand({ token: user.token, id: brandId }));
  }

  function toOpen(){
    if(isOpen === "basic"){
    setIsOpen(true);
    
    }else{
      setIsOpen(!isOpen);
    }
  }

  useEffect(() => {
    getOneBrand();
  }, []);

  useEffect(() => {}, [brand]);

  const custom = useSelector(customSelector);
  const [customData, setCustomData] = useState(null);
  useEffect(() => {
    if (custom) {
      setCustomData(findDataCustom(custom.customs, Number(brandId)));
    }
    if (location.state && location.state.savedScroll) {
      window.scrollTo(0, location.state.savedScroll);
    } else if (location.state && location.state.scroll) {
      window.scrollTo(0, getPositionWithPercent(location.state.scroll));
    }
  }, [custom]);

  return (
    <YellowTemplate>
      
      <ShowOptions className="flex justify-center items-center lg:hidden  z-50" onClick={() => toOpen()} style={{
        backgroundColor: customData && customData.color[1].custom.color,
      }}>
        <Icons name="add" height={25} className={` ${isOpen === "basic" ? "" : isOpen === true ?  "duration-300 rotate-45" : "duration-300 -rotate-0"}`} style={{
        color: customData && customData.color[0].custom.color,
      }}/>
      </ShowOptions>
      

      <DashboardOptions isAppear={isOpen} brandId={Number(brandId)} selectedBrand={brand} />
      
      
      {brandId && brand && (
        <>
          <DashboardTriNavbar etape={2} selectedBrand={brand} />
          <AddCategorieFormule
            selectedBrand={brand}
            selectedBrandId={brandId}
            loadData={() => getOneBrand()}
            custom={customData}
          />
          <StepFooter
            selectedBrandId={brandId}
            step={2}
            linkBack={brandId}
            linkNext={`qrcode/${brandId}`}
          />
        </>
      )}
    </YellowTemplate>
  );
}
