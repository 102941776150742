import { deleteMenuSex } from "api/menuSex/menuSex";
import { updateMenuSex } from "api/menuSex/menuSex";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { userSelector } from "store/models/userInfo";
import { Card, Icons, DefaultButton } from "components/atoms";
import { fetchAllmenuSex } from "api/menuSex/menuSex";
import { AddCategorie } from "components/atoms";
import { sendMenuSex } from "api/menuSex/menuSex";
import { getBrand } from "store/models/restaurantInfo";

export function CategorieCard({ selectedBrand, selectedBrandId }) {
  let history = useHistory();
  const dispatch = useDispatch();

  const [menuSex, setMenuSex] = useState(null);
  const [title, setTitle] = useState(null);
  const [edit, setEdit] = useState(false);
  const [menu, setMenu] = useState([]);

  const [loading, setLoading] = useState(false);

  const { user } = useSelector(userSelector);

  useEffect(() => {
    if (selectedBrand && selectedBrand.Menu && selectedBrand.Menu[0]) {
      setMenu(selectedBrand.Menu[0]);
    }
  }, [selectedBrand]);

  async function getMenuSex() {
    setLoading(true);
    setMenuSex(await fetchAllmenuSex(user.token));
    setLoading(false);
  }

  useEffect(() => {
    getMenuSex();
  }, []);

  const postMenuSex = async () => {
    await sendMenuSex(title, user.token, menu.id);
    setTitle("");
    getMenuSex();
  };

  const removeMenuSex = async (id) => {
    await deleteMenuSex({ id, userId: user.id });
    getMenuSex();
  };

  const changeMenuSex = async (id, data) => {
    await updateMenuSex(id, data);
    if (data.isSelected === true) {
      history.push(`/pages/dashboard/menu/${selectedBrandId}#sex${id}`);
    }
    getMenuSex();
  };

  return (
    <div className=" mb-2 mx-5  md:mx-0 flex justify-center  ">
      <Card>
        <div className="flex mt-5">
          <div className="flex justify-between m-auto items-center w-full border-b border-solid h-[37px] md:h-[53px] mx-5 md:mx-10 border-black  pb-1 md:pb-2.5">
            <p className="font-poppins font-medium text-base md:text-xl  md:ml-0  md:pb-0">
              {edit ? "Modifier les catégories" : "Ajouter une catégorie"}
            </p>

            {edit ? <DefaultButton ButtonType="smaller" className="!font-medium" onClick={() => {setEdit(!edit)}}>Retour</DefaultButton> : <button
              className=" text-sm tracking-normal relative p-0 bg-transparent  flex flex-col items-center pb-0 justify-center "
              onClick={() => {
                setEdit(!edit);
              }}
            >
              <Icons
                name="crayon  "
                height="20"
                className=" hover:opacity-50 active:opacity-100"

              />
              <div className="absolute top-0 ml-[20px]">
                <Icons
                  name="crayon-1  "
                  height="20"
                  className="  "
                />
                <Icons
                  name="crayon-2  "
                  height="20"
                  className="   "
                />
              </div>
              <p className="hidden md:block">éditer</p>
            </button>
              }

          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 mt-8 gap-3 md:gap-5 md:ml-12 md:mr-12 mx-5">
          {menuSex &&
            menuSex.length > 0 &&
            menuSex.map((row) => {
              if (row.menuId === menu.id) {
                return (
                  <AddCategorie
                    edit={edit}
                    isGlobal={row.isGlobal}
                    isSelected={row.isSelected}
                    onClick={() => {
                      edit
                        ? !row.isGlobal && removeMenuSex(row.id)
                        : changeMenuSex(row.id, {
                          isSelected: !row.isSelected,
                        });
                    }}
                  >
                    {row.title}
                  </AddCategorie>
                );
              }
            })}
        </div>
        <div className="md:flex flex-col md:flex-row text-center justify-center items-center">
          <p className="font-medium font-poppins text-xl md:text-2xl mt-4 md:mt-0 md:ml-18  md:mr-11  ">
            ou
          </p>
          <div className="bg-yellow-lightest rounded-xl  m-5  md:pt-5   py-5        md:pr-6  md:pl-8 md:mt-11 md:mb-11  md:flex  ">
            <input
              className=" border border-black border-solid py-3 text-sm  md:mx-5 md:mt-0  w-4/5  md:py-15 md:pl-9  md:w-104 md:mr-5  text-center md:text-left"
              placeholder="Catégorie personnalisée"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            ></input>
            <div className="hidden md:block">
              <DefaultButton

                onClick={(e) => {
                  e.preventDefault();
                  postMenuSex();
                  dispatch(getBrand());
                }}
              >
                Ajouter
              </DefaultButton>
            </div>
          </div>
          <div className="md:hidden">
            <DefaultButton
              className="mb-4 md:mb-0"
              onClick={(e) => {
                e.preventDefault();
                postMenuSex();
                dispatch(getBrand());
              }}
            >
              Ajouter
            </DefaultButton>
          </div>
        </div>
      </Card>
      {loading && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-black flex justify-center items-center z-50 bg-opacity-50">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
}
