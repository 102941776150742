import Button from "./Button";
import joinClasses from "helpers/joinClasses"; 

export function ShowOptions({className="", onClick, children,  ...props}) {
    return(
        <Button
        {...props} 
       
        className={joinClasses(className,"fixed bottom-5 right-5  rounded-full h-10 w-10")
        } onClick={onClick}
        >
            {children}
        </Button>
    )
}