import getFormatedToken from "api/helpers/getFormatedToken";
import axios from "axios";

export async function createFormule({ token, id, name, price }) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/formule`,
    { menuId: id, name, price },

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = response.data;
  return data;
}

export async function updateFormule(id, payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/formule/${id}`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data;
  } catch (error) {
  }
}


export async function deleteFormule({id, token}) {
  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/formule/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}