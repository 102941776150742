import { useState, useEffect } from "react";
// * Composants * //
import { StepFooter, YellowTemplate } from "components";
import { DashboardTriNavbar } from "components";
// * REDUX * //
import { useSelector } from "react-redux";
// * REQUETES * //
import { userSelector } from "store/models/userInfo";
import { DashboardCard } from "components";
import { fetchSelectedBrand } from "api/brand/brand";

export default function OneRestaurantPage(props) {
  const { user } = useSelector(userSelector);
  const brandId = props.match.params.id;
  const [brand, setBrand] = useState(null);

  async function getOneBrand() {
    setBrand(await fetchSelectedBrand({ token: user.token, id: brandId }));
  }

  useEffect(() => {
    getOneBrand();
  }, []);

  return (
    <YellowTemplate>
      {brandId && brand && (
        <>
          <DashboardTriNavbar etape={1} selectedBrand={brand} />
          <DashboardCard selectedBrandId={brandId} />
        </>
      )}
    </YellowTemplate>
  );
}
