import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function createDrink(token, id) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/drink`,
    { DrinkCategoryId: id },

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = response.data;

  return data;
}

export async function updateDrink({token, id, payload}) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/drink/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response.data;

    return data;
  } catch (error) {
  }
}

export async function deleteDrink({ id }) {
  if (!id) {
    return;
  }
  try {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/drink/${id}`,
      getFormatedToken()
    );
  } catch (error) {
  }
}
