// COMPONENTS
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Icons, StepsButton } from "components/atoms";
import "./animation.css";
import { Link } from "react-router-dom";
import { CustomRestaurantCard, PreviewMenu } from "components";

import { useHistory } from "react-router";
import { getBrand } from "store/models/restaurantInfo";
import { useDispatch } from "react-redux";
import { getPositionInPercent } from "helpers/scrollConvert";
import { RestaurantEditInfoPratique } from "../Cards/RestaurantEditInfoPratique";
import { customSelector } from "store/models/customInfo";
import { findDataCustom, findFont } from "helpers/findDataCustom";

export function DashboardOptions({ brandId, selectedBrand, isAppear = "basic" }) {
  const [currentSection, setCurrentSection] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [customData, setCustomData] = useState(null);

  const history = useHistory();
  const custom = useSelector(customSelector);

  const navItems = [
    {
      id: 1,
      icon: "pinceau",
    },
    {
      id: 2,
      icon: "view",
    },
    {
      id: 3,
      icon: "info",
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => { }, [isInfoOpen]);

  useEffect(() => {
    if (custom) {
      setCustomData(findDataCustom(custom.customs, brandId));
    }
  }),
    [custom];

  function displayInfoMenu() {
    setIsInfoOpen(true);
  }
  useEffect(() => {
    if (currentSection === 1) setIsOpen(true);
    else if (currentSection === 2) {
      history.push({
        pathname: `/dashboard/brand/preview/${brandId}`,
        state: {
          scroll: getPositionInPercent(),
          savedScroll: window.scrollY,
        },
      });
    } else if(currentSection === 3){
      setIsInfoOpen(true);
    }
    else setIsOpen(false);
  }, [currentSection]);

  useEffect(() => {
    if (currentSection === 1 && isOpen === false) {
      setCurrentSection(0);
    }
  }, [isOpen]);

  // OnChange avec un délai de 1 seconde

  return (
    <>
      <div className={`fixed lg:hidden justify-center items-end flex w-[40px]  right-5 bottom-5 z-30 ${isOpen === "basic" ? "   " : isOpen === true ? "containerHeightUp " : " containerHeightDown"}`}>

        <nav className={`flex flex-col   ${isAppear === "basic" ? "   " : isAppear === true ? "transition ": " reverseTransition"}`}>
          {navItems.map((item, index) => (
            <button
              key={index}
              className={` rounded-md flex items-center justify-center    ${!item.icon && "opacity-0"} ${isAppear === "basic" ? " " : isAppear === true ? "p-2.5 squareUp" : "p-0 squareDown " } ${
                currentSection === item.id ? "bg-yellow" : "bg-white"
              } `}
              onClick={() => setCurrentSection(item.id)}
            >
              <Icons iconType={"transition"} name={item.icon} className={isAppear === "basic" ? "text-[0px]" : isAppear === true ? "fontSizeUp" : "fontSizeDown"}  />
              
            </button>
          ))}
        </nav>
      </div>

      
      <nav className="hidden lg:fixed lg:right-0 lg:top-1/2 lg:flex lg:flex-col lg:gap-5 lg:transform lg:-translate-y-2/4 lg:z-20">
        {navItems.map((item, index) => (
          <button
            key={index}
            className={`shadow-special rounded-md flex  items-center justify-center p-2.5 w-[55px] h-[50px]  ${currentSection != "3" ? (currentSection === item.id ? "bg-yellow" : "bg-white") : "bg-white"} `}
              
            onClick={() => setCurrentSection(item.id)}
          >
            <Icons name={item.icon} height={30} />
          </button>
        ))}
      </nav>

      <CustomRestaurantCard
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        brandId={brandId}
      />
      <RestaurantEditInfoPratique
       customData={customData}
        isInfoOpen={isInfoOpen}
        setIsInfoOpen={setIsInfoOpen}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        selectedBrand={selectedBrand}
      />
    </>
  );
}
