import { CategorieCard } from "components";

export function EditCard({ selectedBrand, selectedBrandId }) {
  return (
    <div>
      <div>
        <div className="flex justify-center mt-7">
        </div>
        <CategorieCard selectedBrand={selectedBrand} selectedBrandId={selectedBrandId}/>
      </div>
    </div>
  );
}
