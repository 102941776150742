import getFormatedToken from "api/helpers/getFormatedToken";
import axios from "axios";

export async function positionUp(id, table) {
  

    await axios.put(
      `${process.env.REACT_APP_API_URL}/${table}/${id}/up`,
      {},
      getFormatedToken()
    );
  }
  
  export async function positionDown(id, table) {
    
    await axios.put(
      `${process.env.REACT_APP_API_URL}/${table}/${id}/down`,
      {},
      getFormatedToken()
    );
  }