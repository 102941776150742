import { fetchOneMenu } from "api/menu/menu";
import { fetchSelectedBrand } from "api/brand/brand";

import { Card, HorizontalLine, Title } from "components/atoms";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userSelector } from "store/models/userInfo";

//structure
import { ConfigureUrl } from "./configureUrl";
import { LaunchedCard } from "./launchedCard";
import { QrCode } from "./qrCode";

export function LaunchCard({ menuId, brandId }) {
  const { user } = useSelector(userSelector);

  /*  const [contact, setContact] = useState(false); */

  const [menu, setMenu] = useState(null);
  const [brand, setBrand] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [generateQrCode, setGenerateQrCode] = useState(false);

  const getOneMenu = async () => {
    const newMenu = await fetchOneMenu({ token: user.token, id: menuId });
    setMenu(newMenu);
    return newMenu;
  };
  const getBrand = async () => {
    setBrand(await fetchSelectedBrand({ id: brandId }));
  };

  useEffect(() => {
    getOneMenu();
    getBrand();
  }, [updated]);

  /* Get existing URL */
  useEffect(() => {
    if (menu && menu.url && brand && brand.status == "PUBLISHED") {
      setUpdated(true);
    }
  }, [menu, brand]);

  return (
    <div className="mt-4 flex justify-center px-3 pb-5 md:p-0">
      <Card className="md:w-900 p-8">
        
        {!updated ? (
          <div>
          <Title TextSize="smaller" className="p-4 !font-bold">
            Votre URL
          </Title>
          <HorizontalLine />
          <ConfigureUrl
            menuId={menuId}
            brandId={brandId}
            user={user}
            getOneMenu={getOneMenu}
            existingUrl={menu && menu.url ? menu.url : null}
            setUpdated={setUpdated}
          />
          </div>
        ) : !generateQrCode ? (
          <LaunchedCard
            getOneMenu={getOneMenu}
            setGenerateQrCode={setGenerateQrCode}
            setUpdated={setUpdated}
            brandId={brandId}
            getBrand={getBrand}
          />
        ) : (
          <QrCode menu={menu} />
        )}
      </Card>
    </div>
  );
}

/*   function saveimg() {
    var svg = document.getElementById("svg");
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
  } */
