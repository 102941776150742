import getFormatedToken from "api/helpers/getFormatedToken";
import axios from "axios";

export async function createComment(token, id) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/comment`,
    { menuSectionId: id },

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = response.data;
  return data;
}

export async function updateComment({ token, id, payload }) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/comment/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = response.data;

    return data;
  } catch (error) {
    
  }
}

export async function deleteComment( id ) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/comment/${id}`,
    getFormatedToken(),
    );

    const { data } = response.data;

    return data;
  }


