import { useState, useEffect } from "react";
import { EmptyRestoCard } from "components/atoms";
import { DashboardTriNavbar, StepFooter } from "components";
import { YellowTemplate } from "components";
// * REDUX * //
import { useSelector } from "react-redux";
// * REQUETES * //
import { userSelector } from "store/models/userInfo";
import { fetchSelectedBrand } from "api/brand/brand";
import { LaunchCard } from "components/organisms/launchCard";

export default function CodeQR(props) {
  const { user } = useSelector(userSelector);
  const brandId = props.match.params.id;
  const [brand, setBrand] = useState(null);

  async function getOneBrand() {
    setBrand(await fetchSelectedBrand({ token: user.token, id: brandId }));
  }

  useEffect(() => {
    getOneBrand();
  }, []);

  return (
    <YellowTemplate>
      {brandId && brand && (
        <>
          <DashboardTriNavbar etape={3} selectedBrand={brand} />
          <LaunchCard
            menuId={brand.Menu && brand.Menu[0] && brand.Menu[0].id}
            brandId={brandId}
          />
          <StepFooter
            selectedBrandId={brandId}
            step={3}
            linkBack={`menu/${brandId}`}
          />
        </>
      )}
    </YellowTemplate>
  );
}
