import { DefaultButton, Icons, Paragraph } from "components/atoms";
import { useEffect, useState } from "react";
import { PopUp } from "./popUp";
import { updateBrand } from "api/brand/brand";
import { Link } from "react-router-dom";
import Button from "components/atoms/Buttons/Button";

export function LaunchedCard({
  getOneMenu,
  setGenerateQrCode,
  setUpdated,
  brandId,
  menuId,
  getBrand,
}) {
  const [url, setUrl] = useState("");
  const [copy, setCopy] = useState(false);
  const [security, setSecurity] = useState(false);

  const handleQRCodeGeneration = () => {
    setGenerateQrCode(true);
  };

  const returnEdition = async () => {
    await updateBrand({
      id: brandId,
      payload: { status: "DRAFT" },
    });
    await getBrand();
    setUpdated(false);
  };

  const getUrl = async () => {
    const menu = await getOneMenu(menuId);
    setUrl(`${process.env.REACT_APP_PUBLIC_PROD_URL}/carte/` + menu.url);
  };

  useEffect(() => {
    getUrl();
  }, []);

  return (
    <div>
      <div className="flex flex-col items-center mt-4">
        <h1 className="text-xl font-bold">Félicitations !</h1>
        <p className="text-xs my-4">
          Vous carte est désormais disponible sur l'adresse suivante :
        </p>
        <div className="flex justify-around w-2/3 mb-3">
          <Paragraph>
            <p className="text-base">{url}</p>
          </Paragraph>
          <Button
            
            className="bg-yellow py-[5px] px-[15px] text-xs font-medium"
            onClick={() => {
              navigator.clipboard.writeText(url);
              setCopy(true);
              setTimeout(() => {
                setCopy(false);
              }, 2000);
            }}
          >
            {copy ? "Copié !" : "Copier"}
          </Button>
        </div>
        <button
          className="my-8 !text-sm flex  items-center gap-2 px-[35px] py-6 cursor-pointer rounded-full bg-white shadow-light text-xl active:shadow-inner"
          onClick={() => handleQRCodeGeneration()}
        >
         <Icons name="qr-code" height="14" /> Génerer mon QR code
        </button>
        <p className="mt-[20px] text-xs text-center">
        Vous souhaitez un nom de domaine personnalisé ? <br/>
        Vous pouvez à tout moment <a href="# " className="underline font-medium">nous contacter</a>.
        </p>
        {/* <DefaultButton ButtonType="classic" onClick={() => setSecurity(true)}>
          Editer l'URL
        </DefaultButton>
        {security && (
          <PopUp>
            <h2 className="font-medium mb-2">Attention !</h2>
            <p>
              En retournant sur l'édition de l'URL,&nbsp;
              <label className="text-red">
                votre restaurant ne sera plus en ligne&nbsp;
              </label>
              jusqu'à ce que vous le publiez à nouveau.
            </p>
            <div className="flex items-end">
              <DefaultButton
                className="mt-6 font-medium mr-5"
                ButtonType="classic"
                onClick={() => {
                  returnEdition();
                }}
              >
                Éditer
              </DefaultButton>
              <DefaultButton
                className="mt-6 font-medium bg-white border border-yellow"
                ButtonType="classic"
                onClick={() => {
                  setSecurity(false);
                }}
              >
                Retour
              </DefaultButton>
            </div>
          </PopUp>
        )} */}
      </div>
    </div>
  );
}
