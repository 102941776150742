import { Title } from "components/atoms";
import { findFont } from "helpers/findDataCustom";
import DrinkSection from "./DrinkSection";
import PriceCustom from "./priceCustom";
import FontPicker from "font-picker-react";
import { DefaultModal } from "components";
import { useState } from "react";
const reactStringReplace = require("react-string-replace");


// import reactStringReplace from "react-string-replace"
const drinkBorderCSS = "border-l-2 border-dotted";

const bottle = [
  { cl: "15cl", width: "3", height: 28 },
  { cl: "25cl", width: "4", height: 32 },
  { cl: "37,5cl", width: "3", height: 48 },
  { cl: "50cl", width: "9", height: 40 },
  { cl: "75cl", width: "4", height: 64 },
];



export function MenuSectionView({ data, custom }) {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDish, setselectedDish] = useState({title : null, image: null});

  return (
    <div className="flex flex-col justify-center gap-1 w-full max-w-6xl m-auto">
      {data
        .sort((a, b) => (a.positionId > b.positionId ? 1 : -1))
        .map((dish, index) => (
          /* men10u section */
          <div
            key={index}
            className="flex flex-col items-center  w-full  mt-10 mb-10"
            id={`section-${dish.id}`}
          >
            {dish.avatar && (
              <img src={dish.avatar} className="w-11/12 md:w-2/3" />
            )}
            <div
              className="w-11/12 md:w-2/3 border-b-2 mb-3"
              style={{ borderColor: custom && custom.color[3].custom.color }}
            >
              <Title
                className={` break-words
                ${custom && findFont(custom.police[0].custom.fontFamily)}
                ${custom && custom.police[0].custom.bold ? "font-bold" : "!font-normal"}
                ${custom && custom.police[0].custom.italic ? "italic" : ""}
              `}
                style={{
                  marginTop: "0.4em",
                  fontSize: `${custom && custom.police[0].custom.fontSize}px`,
                  letterSpacing: `${custom && custom.police[0].custom.letterSpacing
                    }px`,
                  color: custom && custom.color[1].custom.color,
                }}
              >
                {dish.title}
              </Title>
            </div>
            {dish.isGlobal && dish.DrinkVolume.length != 0 ? (

              /* IF ITS A DRINKS SECTION */
              <DrinkSection dish={dish} index={index} custom={custom} />

            ) : (
              /* IF ITS A DISH SECTION */
              <div className="w-11/12 md:w-2/3">
                {/* PLATS */}
                {dish &&
                  dish.Dish &&
                  dish.Dish.sort((a, b) =>
                    a.positionId > b.positionId ? 1 : -1
                  ).map((item, index) => (
                    <div
                      key={index}
                      className={`my-3 mb-5 flex gap-5 w-full  ${item.name === null || item.name === "" ? "hidden" : ""
                        }`}
                    >
                      {custom && custom.display[1].visible && item.avatar && (
                        <>
                          <img
                            src={item.avatar}
                            style={{ width: "90px", height: "90px" }}
                            className="h-max cursor-pointer"
                            onClick={() => { setIsOpen(true), setselectedDish({title: item.name, image: item.avatar}) }}
                          />

                        </>
                      )}

                      <div
                        className={`w-full flex flex-col justify-start ${item.name === null || item.name === "" ? "hidden" : ""
                          }`}
                      >
                        <div
                          className={`flex flex-col justify-start md:flex-row md:justify-between md:items-center md:gap-5  `}
                        >
                          {/* TITLE OF DISH */}

                          <div
                            className={`md:min-w-max
                                ${custom &&
                              findFont(custom.police[2].custom.fontFamily)
                              }
                                ${custom && custom.police[2].custom.bold
                                ? "font-bold"
                                : ""
                              }
                                ${custom && custom.police[2].custom.italic
                                ? "italic"
                                : ""
                              }
                                
                              `}
                            style={{
                              fontSize: `${custom && custom.police[2].custom.fontSize
                                }px`,
                              letterSpacing: `${custom && custom.police[2].custom.letterSpacing
                                }px`,
                              color: custom && custom.color[2].custom.color,
                            }}
                          >
                            {item.name}
                          </div>
                          {/* PRICE OF DISH */}
                          {custom && custom.display[0].visible && (
                            <>
                              <div
                                className="border-t border-black border-dotted w-full h-0 hidden md:block "
                                style={{
                                  borderColor:
                                    custom && custom.color[3].custom.color,
                                }}
                              />
                              <PriceCustom
                                custom={custom}
                                className=" min-w-max "
                              >
                                {item.price === null || item.price === "" ? (
                                  <div className={`text-left md:text-center md:min-w-[50px]
                             ${custom &&
                                    findFont(custom.police[4].custom.fontFamily)
                                    }
                             ${custom && custom.police[4].custom.bold
                                      ? "font-bold"
                                      : ""
                                    }
                             ${custom && custom.police[4].custom.italic
                                      ? "italic"
                                      : ""
                                    }
                           `}
                                    style={{
                                      fontSize: `${custom && custom.police[4].custom.fontSize
                                        }px`,
                                      letterSpacing: `${custom && custom.police[4].custom.letterSpacing
                                        }px`,
                                      color: custom && custom.color[2].custom.color,
                                    }}>-</div>
                                ) : (
                                  <div className={`text-left md:text-center md:min-w-[50px]
                             ${custom &&
                                    findFont(custom.police[4].custom.fontFamily)
                                    }
                             ${custom && custom.police[4].custom.bold
                                      ? "font-bold"
                                      : ""
                                    }
                             ${custom && custom.police[4].custom.italic
                                      ? "italic"
                                      : ""
                                    }
                           `}
                                    style={{
                                      fontSize: `${custom && custom.police[4].custom.fontSize
                                        }px`,
                                      letterSpacing: `${custom && custom.police[4].custom.letterSpacing
                                        }px`,
                                      color: custom && custom.color[2].custom.color,
                                    }}>
                                    {" "}
                                    {reactStringReplace(
                                      `${item.price}`,
                                      "€",
                                      (match, i) => ""
                                    )}
                                    €
                                  </div>
                                )}
                              </PriceCustom>
                            </>
                          )}
                        </div>
                        <div className="flex gap-1">
                          {/* INGREDIENTS */}
                          {item.ingredients.map((ingredient, index) => (
                            <h3
                              key={index}
                              className={`
                            ${custom &&
                                findFont(custom.police[3].custom.fontFamily)
                                }
                            ${custom && custom.police[3].custom.bold
                                  ? "font-bold"
                                  : ""
                                }
                            ${custom && custom.police[3].custom.italic
                                  ? "italic"
                                  : ""
                                }
                          `}
                              style={{
                                fontSize: `${custom && custom.police[3].custom.fontSize
                                  }px`,
                                letterSpacing: `${custom &&
                                  custom.police[3].custom.letterSpacing
                                  }px`,
                                color: custom && custom.color[2].custom.color,
                              }}
                            >
                              {ingredient}
                              {index + 1 !== item.ingredients.length && ", "}
                            </h3>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <DefaultModal title={selectedDish.title} isOpen={isOpen} setIsOpen={action => setIsOpen(action)}>
              <img
                src={
                  selectedDish.image}
                style={{ width: "270px", height: "270px" }}
                className="h-max m-auto"

              />
            </DefaultModal>
            <div className="w-4/5 md:w-2/3">
              {dish &&
                dish.Comment.map((item, index) => (
                  <p
                    className={`mt-3
                            ${custom &&
                      findFont(custom.police[3].custom.fontFamily)
                      }
                            ${custom && custom.police[3].custom.bold
                        ? "font-bold"
                        : ""
                      }
                            ${custom && custom.police[3].custom.italic
                        ? "italic"
                        : ""
                      }
                          `}
                    style={{
                      fontSize: `${custom && custom.police[3].custom.fontSize
                        }px`,
                      letterSpacing: `${custom && custom.police[3].custom.letterSpacing
                        }px`,
                      color: custom && custom.color[2].custom.color,
                    }}
                    key={index}
                  >
                    {item.content.split("\n").map((str) => (
                      <p>{str}</p>
                    ))}
                  </p>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
}
