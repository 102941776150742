// COMPONENTS
import { Card, DefaultButton, Icons } from "components/atoms";
import { DeletePopUp } from "../Popup/DeletePopUp";
import { useState } from "react";
import { updateBrand } from "api/brand/brand";
import { getBrand } from "store/models/restaurantInfo";
import { deleteBrand } from "api/brand/brand";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { Link } from "react-router-dom";
import { Drag } from "components";

export function RestaurantCard({
  brandId,
  logo,
  name,
  city,
  position,
  status,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);
  const [drag, setDrag] = useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  async function deleteOneBrand(id) {
    await deleteBrand({ id });
    dispatch(getBrand());
  }

  const changeBrand = async (data) => {
    await updateBrand({ id: brandId, payload: data });
    dispatch(getBrand());
  };

  return (
    <div className="md:flex md:flex-wrap md:justify-between">
      <Card className="md:w-226 md:h-[320px] h-[317px] my-10 md:my-2">
        <div className="w-full flex justify-center">
          <div
            className={`-mt-3  w-2/4 text-center rounded text-white font-medium ${
              status === "DRAFT"
                ? "bg-yellow-draft"
                : status === "PUBLISHED" && "bg-green"
            }`}
          >
            {status === "DRAFT"
              ? "En cours"
              : status === "PUBLISHED" && "Publié"}
          </div>
        </div>
        <div className=" mt-3 md:mt-5  flex flex-col h-[292px] md:h-[269px] justify-between items-center">
          <div className="flex flex-col items-center">
            {logo ? (
              <img src={`${logo}`} className="w-20 h-20 rounded" alt="logo" />
            ) : (
              <div className="w-20 h-20 bg-yellow" />
            )}
            <div className=" mt-4 h-[28px]">
              <div className="flex justify-center">
                <p className="text-xl font-poppins font-regular text-center truncate w-[210px]">
                  {name}
                </p>
              </div>
            </div>
            
            <div>
              <div className="flex justify-center">
                <p className="text-sm font-poppins font-regular">{city}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center mb-5 md:mb-0">
            <div className="md:mb-0 mb-5" style={{ marginTop: "32px" }}>
              <Link className="relative hover:opacity-50 active:opacity-100" to={`/pages/dashboard/${brandId}`}>
                <Icons
                  name="crayon  "
                  height="20"
                  className="mr-3 md:mr-5 hover:opacity-50 active:opacity-100"

                />
                <div className="absolute top-[-1px] md:-top-0.5 ml-[20px]">
                  <Icons
                    name="crayon-1  "
                    height="20"
                    className="  "
                  />
                  <Icons
                    name="crayon-2  "
                    height="20"
                    className="   "
                  />
                </div>
              </Link>
              {
                drag ? (
                  <Drag
                    position={position}
                    changePosition={(pos) => {
                      changeBrand({ positionId: Number(pos) }), setDrag(false);
                    }}
                  />
                ) : (
                  ""
                )
                //  : (
                //   <Icons
                //     className="mx-2"
                //     name="move"
                //     height="20"
                //     onClick={() => setDrag(true)}
                //   />
                // )
              }
              <Icons
                name="trash"
                height="20"
                className="ml-2"
                onClick={() => {
                  setShowDeletePopUp(true);
                }}
              />
              {showDeletePopUp && (
                <DeletePopUp
                  onValidate={() => {
                    deleteOneBrand(brandId);
                    setShowDeletePopUp(false);
                  }}
                  onCancel={() => setShowDeletePopUp(false)}
                >
                  Etes vous sûr de vouloir supprimer le restaurant&nbsp;
                  <label className="font-bold">{name}</label>
                </DeletePopUp>
              )}
            </div>
            <Link to={`/pages/dashboard/menu/${brandId}`}>
              <DefaultButton className=" md:mt-5">
                Editer Menu
              </DefaultButton>

            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
}
