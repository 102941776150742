import Button from "./Button";
import joinClasses from "helpers/joinClasses";

export function DefaultButton({
  children,
  ButtonType,
  className = "",
  onClick,
  isActive,
  ...props
}) {
  if (ButtonType === "smaller") {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(
          className,
          "bg-yellow font-poppins py-2 px-7 text-xs md:text-sm   backdrop-filter hover:backdrop-brightness-200 "
        )}
        {...props}
      >
        {children}
      </Button>
    );
  } else if (isActive) {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(
          className,
          "bg-yellow font-poppins  py-[15px] px-10 font-medium text-xs md:text-sm"
        )}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      onClick={onClick}
      className={joinClasses(
        className,
        "bg-yellow font-poppins  py-[15px] px-10 font-medium text-xs md:text-sm"
      )}
      {...props}
    >
      {children}
    </Button>
  );
}
