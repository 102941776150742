import { Card, Icons, LogoPicker } from "components/atoms";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { TimePatron } from "../Patrons/TimePatron";
import "./switch.css";

import { fetchSelectedBrand, updateBrand } from "api/brand/brand";
import { Link } from "react-router-dom";

export function RestaurantEditInfoPratique({
  customData,
  isInfoOpen = false,
  setIsInfoOpen = () => {},
  selectedBrand,
  currentSection = false,
  setCurrentSection = () => {},
}) {
  // const { user } = useSelector(userSelector);
  // const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [schedule, setSchedule] = useState({
    Lundi: null,
    Mardi: null,
    Mercredi: null,
    Jeudi: null,
    Vendredi: null,
    Samedi: null,
    Dimanche: null,
  });
  const [links, setLinks] = useState([""]);

  useEffect(() => {
    if (selectedBrand) {
      setDisabled(selectedBrand.isSchedule);
      setSchedule({
        Lundi: selectedBrand.schedule && selectedBrand.schedule.Lundi,
        Mardi: selectedBrand.schedule && selectedBrand.schedule.Mardi,
        Mercredi: selectedBrand.schedule && selectedBrand.schedule.Mercredi,
        Jeudi: selectedBrand.schedule && selectedBrand.schedule.Jeudi,
        Vendredi: selectedBrand.schedule && selectedBrand.schedule.Vendredi,
        Samedi: selectedBrand.schedule && selectedBrand.schedule.Samedi,
        Dimanche: selectedBrand.schedule && selectedBrand.schedule.Dimanche,
      });
      setLinks(JSON.parse(selectedBrand.links)||[""])
    }
  }, [selectedBrand]);

  useEffect(() => {
    changeBrand({ schedule: schedule });
  }, [schedule]);

  useEffect(() => {
    changeBrand({links: JSON.stringify(links)})
  }, [links]);

  const getBrand = async () => {
    setBrand(await fetchSelectedBrand({ id: selectedBrand && selectedBrand.id }));
  };

  const changeBrand = async (data) => {
    await updateBrand({ id: selectedBrand && selectedBrand.id, payload: data });
  };

  return (
    <Card
      className={`fixed right-0 top-1/2 transform -translate-y-2/4 font-poppins  ${
        !isInfoOpen && "hidden"
      } shadow-special bg-white z-50 sm:w-[400px] pb-2 transition-all  w-full`}
      style={{
        backgroundColor: customData && customData.color[0].custom.color,
      }}
    >
      {/* <LogoPicker className={` w-full !my-0 relative h-[240px]`}/> */}
      <div className="flex items-center justify-between w-full top-0 left-0 " style={{            backgroundColor: customData && customData.color[0].custom.color,
}}>
        <div className="flex gap-4 ml-4">
          <Icons name="info" className=" right-0 top-0 pointer-events-none" style={{
            color: customData && customData.color[2].custom.color,
          }}height={25} />
          <h3 className="flex items-center  font-bold text-sm" style={{
            color: customData && customData.color[2].custom.color,
          }}>
              Infos pratiques
          </h3>
        </div>
        <button
          className="bg-yellow"
          style={{ width: "52px", height: "52px", backgroundColor: customData && customData.color[1].custom.color }}
          onClick={() => {
            setIsInfoOpen(false), setCurrentSection(0);
          }}
        >
          <Icons
            name="add"
            height={20}
            className="transform  rotate-45 block"
            style={{color: customData && customData.color[0].custom.color}}
          />
        </button>
      </div>
      <div className="overflow-y-auto overflow-x-hidden w-full "style={{backgroundColor: customData && customData.color[0].custom.color}}>
        <div className=" w-11/12 m-auto flex flex-col ">
          <div className="h-[126px] relative  w-full mt-3 flex flex-col pl-[14px] border justify-center m-auto rounded" style={{color: customData && customData.color[2].custom.color, borderColor: customData && customData.color[1].custom.color}}>
              <p className="text-2xl font-bold mb-2">
                {selectedBrand && selectedBrand.name}
              </p>
              <p>
                {selectedBrand && selectedBrand.address1}
                {selectedBrand && selectedBrand.address2 && (
                  <p>{selectedBrand.address2}</p>
                )}
              </p>
              <p>
                {selectedBrand && selectedBrand.zip + " " + selectedBrand.city}
              </p>
              <Link to={`/pages/dashboard/${selectedBrand && selectedBrand.id}`}>
              <div className="absolute top-2 right-2" >
                
                  <div className="z-50">
                  <Icons
                    name="crayon  "
                    height="20"
                    className=" hover:opacity-50 active:opacity-100"
                  />
                  </div>
                
                <div className="absolute top-0 ml-[20px]">
                  <Icons name="crayon-1  " height="20" className="  " />
                  <Icons name="crayon-2  " height="20" className="   " />
                </div>
              </div>
              </Link>
            </div>
          <div className="w-11/12  m-auto mt-2.5 flex items-center justify-between">
            <Icons name="phone1" height={30} className="pointer-events-none" style={{color: customData && customData.color[1].custom.color}}/>
            <input
              placeholder="numéro de téléphone"
              defaultValue={selectedBrand && selectedBrand.phone}
              type="phone"
              className="w-3/4 rounded border-black border bg-transparent text-xl font-bold border-2 focus:active mr-2"
              style={{padding: "0.5em 0 0.5em 15px", color: customData && customData.color[1].custom.color, borderColor: customData && customData.color[1].custom.color}}
              onBlur={(e) => changeBrand({ phone: e.target.value })}
            ></input>
          </div>
          <div className="mt-[22px] flex flex-col gap-1 ">
            <div className="flex items-center gap-2 mb-1">
              <h4 className="font-bold text-xs" style={{color: customData && customData.color[1].custom.color}}>HORAIRES :</h4>
              <label class="switch">
                <input
                  type="checkbox"
                  checked={disabled}
                  onChange={(e) => {
                    setDisabled(e.target.checked),
                      changeBrand({ isSchedule: e.target.checked });
                  }}
                />
                {disabled ? 
                  <span className="slider round" style={{backgroundColor: customData && customData.color[1].custom.color}} ></span>
                  : <span className="slider round border"
                  style={{backgroundColor: customData && customData.color[0].custom.color, borderColor: customData && customData.color[2].custom.color}} ></span>}
              </label>
            </div>
            {Object.keys(schedule).map((day, index) => (
              <TimePatron
                customData={customData}
                data={schedule[day]}
                disabled={!disabled}
                key={index}
                callBack={(hours) => {
                  setSchedule({ ...schedule, [day]: hours });
                }}
              >
                {day}
              </TimePatron>
            ))}

            <div></div>
          </div>
          <div>
            <div className="flex items-center  gap-2 my-4">
              <p className="font-bold text-xs" style={{color: customData && customData.color[1].custom.color}}>Commander : </p>
              <button>
                <Icons
                  name="add"
                  height={13}
                  className="bg-yellow p-2 rounded-full"
                  onClick={() => setLinks([...links, ""])}
                  style={{color: customData && customData.color[0].custom.color, backgroundColor: customData && customData.color[1].custom.color}}
                ></Icons>
              </button>
            </div>
            {links && links.map((value, index) => (
              <div className="flex mb-2 ">
                <input
                  className="bg-yellow border border-1 border-black pl-2 w-full text-sm bg-transparent truncate max-w-[300px] "
                  style={{borderColor: customData && customData.color[1].custom.color}}
                  placeholder="Lien"
                  defaultValue={value}
                  onBlur={(e)=>{
                    const newLinks = [...links];
                    newLinks[index] = e.target.value;
                    setLinks(newLinks)
                  }}
                />
                <Icons
                name="add"
                className="m-auto rotate-45 p-1 bg-red rounded-full"
                style={{backgroundColor: customData && customData.color[1].custom.color, color: customData && customData.color[0].custom.color}}
                height={12}
                onClick={()=>{
                    const newLinks = [...links];
                    newLinks.splice(index,1)
                    setLinks(newLinks)
                  }}>X</Icons>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
