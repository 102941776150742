import axios from "axios";
import getFormatedToken from "./helpers/getFormatedToken";
import Cookies from "universal-cookie";

export async function loginUser(data) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/authentication/login`,
    data
  );
  return response.data.data.token;
}

export async function registerUser(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/authentication/register`,
      data
    );
    return response.data;
  } catch (error) {
    
    return error;
  }
}

export async function createRestaurant(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/brand`,
      data,
      getFormatedToken()
    );
    return response.data;
  } catch (error) {
    
    return error;
  }
}

export async function authorized(productId) {
  const cookies = new Cookies();

  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/subscription`,
      headers: { Authorization: `Bearer ${cookies.get("::token")}` },
      data: { productId: productId },
    });

    window.location.replace(response.data);
    return;
  } catch (error) {
    
    return null;
  }
}

export async function billingPortal() {
  const response = await axios(getFormatedToken(), {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/subscription/billing-portal`,
  });
  return response.data;
}

export async function validateToken() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/authentication/auth`,
      getFormatedToken()
    );
    return response.data;
  } catch (error) {
    
    return null;
  }
}
