/* Components */
import { BigCard, Icons } from "components/atoms";
import Cookies from "universal-cookie";

export default function AccountPag({ setPage, page }) {
  const focus = "bg-yellow md:py-2 md:rounded-r-full rounded-full";
  const cookies = new Cookies();
  return (
    <>
      {/* DESKTOP FORMAT */}
      <BigCard className="w-full flex hidden md:block md:flex-col flex-row md:w-[250px] md:min-w-[250px]">
        <div className="border-b flex flex-col items-center w-full py-7">
          <Icons name="user" height="90" />
        </div>

        <div className="my-5 flex flex-row md:flex-col gap-4 mr-5">
          <button
            className={`text-left pl-10 ${page == "account" && focus}`}
            onClick={() => {
              setPage("account");
            }}
          >
            Mon compte
          </button>
          <button
            className={`text-left pl-10 ${page == "restaurants" && focus}`}
            onClick={() => {
              setPage("restaurants");
            }}
          >
            Mes établissement
          </button>
          {/* <button
            className={`text-left pl-10 ${page == "sub" && focus}`}
            onClick={() => {
              setPage("sub");
            }}
          >
            Abonnement
          </button> */}
        </div>

        <div className="border-t text-center">
          <button
            className="my-2 cursor-pointer"
            onClick={() => {
              cookies.remove("::token", { path: "/" });
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            }}
          >
            Se déconnecter
          </button>
        </div>
      </BigCard>

      {/* MOBILE FORMAT */}
      <div className="w-full flex items-center md:hidden px-10 pb-3 pt-3  flex-row  overflow-x-scroll">
        <div className=" flex flex-col items-center w-full mr-7 ">
          <Icons name="user" height="35" />
        </div>

        <div className=" h-full flex flex-row  gap-2  text-xs ">
          <button
            className={`text-left min-w-max px-7 py-2  ${
              page == "account" && focus
            }`}
            onClick={() => {
              setPage("account");
            }}
          >
            Mon profil
          </button>
          <button
            className={`text-left min-w-max px-7 py-2  ${
              page == "restaurants" && focus
            }`}
            onClick={() => {
              setPage("restaurants");
            }}
          >
            Mes établissement
          </button>
          {/* <button
            className={`text-left px-7  py-2 min-w-max ${
              page == "sub" && focus
            }`}
            onClick={() => {
              setPage("sub");
            }}
          >
            Abonnement
          </button> */}
          <button
            className=" cursor-pointer py-2 min-w-max"
            onClick={() => {
              cookies.remove("::token", { path: "/" });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }}
          >
            Se déconnecter
          </button>
        </div>
      </div>
    </>
  );
}
