import { MenuSectionView } from "components";
import { findFont } from "helpers/findDataCustom";
import { useState, useRef, useEffect } from "react";
import FormuleSectionView from "./formuleSection";
import "../../";


export function MenuView({ data, custom }) {
  const [secSelectedIndex, setSecSelectedIndex] = useState(null);
  
  const navBarScroll = useRef(null);
  const navBarParent = useRef(null);

  
  

  const getScrollNavBarWidth = () => {

    if (navBarScroll && navBarScroll.current && navBarParent && navBarParent.current) {

      if (navBarScroll.current.clientWidth > window.screen.width) {
        navBarParent.current.style.width = "100vw";
        navBarParent.current.style.overflow = "auto";
        navBarParent.current.style.justifyContent = "flex-start";
        navBarParent.current.style.display = "flex";



      } else {
        navBarParent.current.style.display = "flex";
        navBarParent.current.style.overflow = "none";
        navBarParent.current.style.justifyContent = "flex-start";
      }
    }
  }

  useEffect(() => {
    getScrollNavBarWidth();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getScrollNavBarWidth);
    
  }, )

  const getColorforNavbar = () => {
    return {
      active: {
        backgroundColor: custom && custom.color[1].custom.color || "#fff",
        color: custom && custom.color[0].custom.color || "#000",
      },
      inactive: {
        backgroundColor: "transparent",
        color: custom && custom.color[1].custom.color || "#fff",
      },
    };
  };

  return (
    <>
    
      {data && (
        <div className="w-full  ">
          {data.MenuSection && data.MenuSection.length > 0 && (
            <div
            ref={navBarParent}
            className="sticky top-0 z-10 w-full border-b-2 border-solid overflow-auto  flex justify-start  m-auto  border-black hideScrollBar"
            style={{
              borderColor:  custom &&  custom.color[1].custom.color,
              backgroundColor:  custom &&  custom.color[0].custom.color,
  
            }}
          >
            <div
  
              ref={navBarScroll}
              className={`   text-center flex m-auto sticky top-0 bg-white z-10 h-[40px] items-end
              ${ custom && findFont( custom.police[0].custom.fontFamily)}
                  ${ custom &&  custom.police[0].custom.bold ? "font-bold" : "font-normal"}
                  ${ custom &&  custom.police[0].custom.italic ? "italic" : ""}
              `}
              style={{
                backgroundColor:  custom &&  custom.color[0].custom.color,
                borderColor:  custom &&  custom.color[1].custom.color,
  
              }}
            > 
                {data.MenuSection &&
                  data.MenuSection.sort((a, b) =>
                    a.positionId > b.positionId ? 1 : -1
                  ).map((row, index) => (
                    <a
                      className={`h-10 flex min-w-max items-center px-3 text-sm md:text-md ${((!secSelectedIndex && index === 0) ||
                          secSelectedIndex === row.id) &&
                        "bg-black text-white "
                        }  cursor-pointer `}
                      style={
                        (!secSelectedIndex && index === 0) ||
                          secSelectedIndex === row.id
                          ? { ...getColorforNavbar().active }
                          : { ...getColorforNavbar().inactive }
                      }
                      onClick={() => {
                        setSecSelectedIndex(row.id);
                      }}
                      href={`#section-${row.id}`}
                      key={index}
                    >
                      {row.title}
                    </a>
                  ))}
              </div>
            </div>
          )}

          {/* <div className="border-b w-full" /> */}
          <MenuSectionView data={data.MenuSection} custom={custom} />
          <FormuleSectionView formules={data.Formule} custom={custom} />
        </div>
      )}
    </>
  );
}
