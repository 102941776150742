import { findFont } from "helpers/findDataCustom";
import joinClasses from "helpers/joinClasses";
const reactStringReplace = require('react-string-replace');

export default function FormuleCard({ formule, custom }) {
  return (
    <div
      className="w-11/12 md:w-2/3 mb-3 m-auto px-4 py-4 md:px-10 md:py-8"
      style={{
        backgroundColor: custom ? custom.color[4].custom.color : "black",
      }}
    >
      <div className="flex justify-between">
        <h1
          className={joinClasses(
            custom
              ? findFont(custom.police[1].custom.fontFamily)
              : "font-poppins",
            custom && custom.police[1].custom.bold ? "font-bold" : "",
            custom && custom.police[1].custom.italic ? "italic" : "",
            "text-white flex flex-col md:flex-row justify-between mb-3"
          )}
          style={{
            fontSize: `${custom && custom.police[1].custom.fontSize}px`,
            letterSpacing: `${custom && custom.police[1].custom.letterSpacing}px`,
            color: custom && custom.color[0].custom.color,

          }}
        >
          {formule.name}
        </h1>

        {custom && custom.display[0].visible && <p style={{
          fontSize: `${custom && custom.police[1].custom.fontSize}px`,
          letterSpacing: `${custom && custom.police[1].custom.letterSpacing}px`,
          color: custom && custom.color[0].custom.color}}
          className={joinClasses(
          custom
            ? findFont(custom.police[1].custom.fontFamily)
            : "font-poppins",
          custom && custom.police[1].custom.bold ? "font-bold" : "",
          custom && custom.police[1].custom.italic ? "italic" : "",
          "text-white flex flex-col md:flex-row justify-between mb-3"
        )}>
          {formule.price === null || formule.price === '' ? (<div>-</div>) : (<div> {reactStringReplace(`${formule.price}`, '€', (match, i) => (
          ""
        ))}
          €
        </div>)}</p>}
      </div>
      <div>
        {formule.FormuleElement
          .sort((a, b) => (a.positionId > b.positionId ? 1 : -1))
          .map((element, index) => (
            <>
              <div key={index}>
                <h3 className={`text-white text-lg ${custom && findFont(custom.police[2].custom.fontFamily)}
                ${custom && custom.police[2].custom.bold && "font-bold"}
                ${custom && custom.police[2].custom.italic && "italic"}
                ${custom && !custom.display[0].visible && "opacity-50"}`}
                  style={{
                    fontSize: custom && custom.police[2].custom.fontSize,
                    letterSpacing:
                      custom && custom.police[2].custom.letterSpacing,
                    color: custom && custom.color[0].custom.color,
                  }}>
                  {element.name}</h3>


                <ul className={`ml-4 text-black my-4 ${custom && findFont(custom.police[3].custom.fontFamily)}
                ${custom && custom.police[3].custom.bold && "font-bold"}
                ${custom && custom.police[3].custom.italic && "italic"}
                ${custom && !custom.display[0].visible && "opacity-50"} `}
                  style={{
                    fontSize: custom && custom.police[3].custom.fontSize,
                    letterSpacing:
                      custom && custom.police[3].custom.letterSpacing,
                    color: custom && custom.color[0].custom.color,
                  }} >
                  {element.subElements &&
                    Object.keys(element.subElements).map((key, index) => (
                      <li key={index} className="mt-4 ">
                        {element.subElements[key]}
                      </li>
                    ))}
                </ul>
              </div>
              {formule.FormuleElement.length !== index + 1 && (
                <div className="relative my-8">
                  <div className="h-0 border-t border-orange" style={{
                    borderColor: custom && custom.color[3].custom.color,
                  }} />
                  <span className="absolute font-poppins font-black text-5xl h-[47px] top-1/2 md:mt-[1px] left-1/2 transform -translate-x-1/2 -translate-y-1/2" style={{
                    color: custom && custom.color[3].custom.color,
                  }}>
                    +
                  </span>
                </div>

              )}
            </>
          ))}
      </div>
    </div>
  );
}
