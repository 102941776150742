import { YellowTemplate } from "components";
import { Card, DefaultButton, Paragraph, UnderlineInput } from "components/atoms";

export function FirstAccountInfoCard() {
    return (
        <div>
            <YellowTemplate>
                <div className="flex">
                    <Card className="m-auto    pt-5 pb-[27px]">
                        <div className="flex flex-col items-end">
                            <div className="flex flex-col items-center">
                                <Paragraph className="!font-medium mx-[106px]  ">Vos informations de compte</Paragraph>
                            <div className="flex flex-col w-full px-[69px] my-10">
                                <UnderlineInput placeholder={"Nom*"} className="!w-full !p-0 !pb-1 !text-sm"/>
                                <UnderlineInput placeholder={"Prénom*"} className="!w-full !p-0 !pb-1 !my-30 !text-sm"/>
                                <UnderlineInput placeholder={"Numéro de téléphone*"} className="!w-full !p-0 !pb-1 !text-sm"/>
                            </div>
                            </div>
                            <DefaultButton className="!mb-0 !py-[15px]  mr-[51px] ">
                                Valider
                            </DefaultButton>
                        </div>
                    </Card>
                </div>
            </YellowTemplate>
        </div>
    )
}