import { DashboardNavbar } from "components";
import { BasicFooter } from "components";
import joinClasses from "helpers/joinClasses";

export function YellowTemplate({ children, onClick, className, ...props }) {
  return (
    <div
      {...props}
      className={joinClasses(
        className,
        "font-poppins bg-yellow-lightest flex flex-col min-h-screen"
      )}
    >
      <header>
        <DashboardNavbar onClick={onClick} />
      </header>
      <main className="mb-auto ">{children}</main>
      <footer className="mt-7  w-full md:mt-10">
        <BasicFooter />
      </footer>
    </div>
  );
}
