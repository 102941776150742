import { Paragraph, SubTitle, ThinHorizontalLine } from "components/atoms";
import { RestaurantCard } from "components/molecules/Cards/RestaurantCard";

export default function Restaurant({ brand }) {
  return (
    <div className="flex flex-col items-center px-2 gap-2">
      <div className="w-4/6 text-center md:border-b text-left min-w-max">
        <Paragraph className="!md:text-base !text-sm !md:font-regular !font-medium !pb-1 md:pb-0   ">Informations sur le(s) restaurant(s)</Paragraph>
        <ThinHorizontalLine className="md:hidden"/>
      </div>
      <div className="w-10/12 md:w-full md:flex md:gap-5 md:overflow-auto md:px-5 md:py-5">
        {brand.map((item, index) => (
          <RestaurantCard
            key={index}
            brandId={item.id}
            name={item.name}
            city={item.city}
            logo={item.avatar}
            status={item.status}
          />
        ))}
      </div>
    </div>
  );
}
