import { BasicFooter } from "components"
import { DashboardNavbar } from "components";

export function Template(props) {
    return <div className="font-poppins flex flex-col h-screen ">
       <header> <DashboardNavbar/> </header>
        <main className="mb-auto">{props.children}</main>
        <footer><BasicFooter backgroundColor={"yellow"}/></footer>

    </div>
}
