import Button from "../Button";
import joinClasses from "helpers/joinClasses";

export function PriceButton({ children, className="" }) {
    return (
      <Button
        className={joinClasses(className,"font-medium font-oswald text-xl p-5  bg-yellow   text-center box-border")}
      >
        {children}
      </Button>
    );
  }