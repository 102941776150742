import { Card, DefaultButton, HorizontalLine, Title } from "components/atoms";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { createFormule } from "api/formule/formule";

export function PopupFormule({
  menuId,
  close = () => {},
  loadData = () => {},
}) {
  const { user } = useSelector(userSelector);
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);

  const sendFormule = async () => {
    await createFormule({ token: user.token, id: menuId, name, price });
    loadData();
    close();
  };
  useEffect(() => {
    document.getElementsByName(`popup-formule-${menuId}`)[0].focus();
  }, []);

  return (
    <div className="flex justify-center w-full h-full top-0 z-40 fixed">
      <div
        className="w-full h-full absolute bg-grey-disabled top-0 bg-opacity-50"
        onClick={() => close()}
      ></div>

      <div className="relative my-auto w-11/12 max-w-[1400px]  ">
        <Card className=" lg:w-3/4 m-auto w-full px-5 ">
          <div className="flex">
            <img
              className="cursor-pointer "
              src={"/assets/back-arrow.svg"}
              alt="GO BACK"
              onClick={() => close()}
            />
            <Title TextSize="smaller" className="p-4 m-auto  !text-center">
              Ajouter une formule
            </Title>
          </div>
          <HorizontalLine className="ml-16" />
          <div className="mt-8 flex sm:flex-row flex-col gap-4 ">
            <div className="sm:w-3/4 w-full p-5 rounded-lg bg-yellow-lightest mr-1">
              <input
                className="border border-solid border-grey-disabled font-poppins p-3 w-full"
                placeholder="| Nom de la formule"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name={`popup-formule-${menuId}`}
              ></input>
            </div>
            <p className="sm:hidden text-grey-disabled text-xs ">
              ex : menu du chef, menu du jour, menu enfant, etc.
            </p>
            <div className=" p-5 sm:w-auto w-2/3  rounded-lg bg-yellow-lightest">
              <input
                className="border border-solid border-grey-disabled font-poppins p-3 w-full"
                placeholder="Prix"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="flex sm:justify-between justify-center  ">
            <p className="hidden sm:block text-grey-disabled text-xs mt-3">
              ex : menu du chef, menu du jour, menu enfant, etc.
            </p>
            <DefaultButton className="my-5" onClick={() => sendFormule()}>
              Valider
            </DefaultButton>
          </div>
        </Card>
      </div>
    </div>
  );
}
