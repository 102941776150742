import Button from "./Button";
import joinClasses from "helpers/joinClasses";

export function StepsButton({
  children,
  ButtonType,
  isActive,
  className,
  setNumberNav = () => {},
  data,
}) {
  if (ButtonType === "validated") {
    return (
      <div className="flex cursor-default sm:flew-row">
        <div className=" flex  sm:w-auto sm:flex">
          <a
            onClick={() => setNumberNav(data.number)}
            className={`font-poppins text-sm font-medium text-center uppercase py-1.5 cursor-pointer pointer-events-auto ${
              className ? className : ""
            }`}
          >
            <div className="flex flex-col items-center sm:flex sm:items-center">
              <div className="rounded-full bg-yellow w-9 h-9 flex items-center align-middle justify-center p-3 ">
                {data && data.number ? data.number : ""}
              </div>
              <div className="flex text-smallest sm:text-sm sm:flex sm:align-middle ">
                <p className="whitespace-nowrap">
                  {data && data.name ? data.name : ""}
                </p>
              </div>
            </div>
          </a>
          <p className="hidden sm:self-center sm:flex sm:justify-center sm:mx-2 sm:text-grey-disabled cursor-default">
            {data && data.dots ? data.dots : ""}
          </p>
          <p className="sm:hidden items-end mb-1 flex justify-center mx-[7px] text-grey-disabled cursor-default">
            {data && data.smallDots ? data.smallDots : ""}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className=" flex cursor-default ">
      <div className=" flex sm:w-auto ">
        <a
          onClick={() => setNumberNav(data.number)}
          className={`font-poppins text-grey-disabled text-sm font-medium text-center uppercase py-1.5 cursor-pointer pointer-events-auto ${
            className ? className : ""
          }`}
        >
          <div className="flex  flex-col items-center sm:flex sm:items-center">
            <div className="rounded-full bg-white  border border-solid border-yellow w-9 h-9 flex items-center align-middle justify-center ">
              {data && data.number ? data.number : ""}
            </div>
            <div className="flex text-smallest sm:text-sm sm:flex sm:align-middle ">
              <p className="">{data && data.name ? data.name : ""}</p>
            </div>
          </div>
        </a>
        <p className="hidden sm:self-center sm:flex sm:justify-center sm:mx-2 sm:text-grey-disabled cursor-default ">
          {data && data.dots ? data.dots : ""}
        </p>
        <p className="sm:hidden items-end mb-1 flex justify-center mx-[7px] text-grey-disabled cursor-default">
          {data && data.smallDots ? data.smallDots : ""}
        </p>
      </div>
    </div>
  );
}
